/* eslint-disable vue/no-v-html */

<template>
  <div :class="[classes, dynamicCssClasses]" :data-preload="[$options.name]">
    <div v-if="!!label" class="headline">
      {{ label }}
    </div>

    <div class="option-list qa-radioGroup__options">
      <label
        v-for="(child, index) in children"
        :key="child.vars.label"
        :for="child.vars.value + (child.vars.key ? child.vars.key : '')"
        :class="`option qa-Radio__option-${index + 1}`"
        @click="$emit('input', child.vars.value)"
      >
        <input
          :id="child.vars.value + (child.vars.key ? child.vars.key : '')"
          :ref="child.vars.value"
          type="radio"
          class="native"
          :name="fullName"
          :value="child.vars.value"
          :checked="checked !== null ? checked : value === child.vars.value"
          :disabled="disabled"
          :readonly="readonly"
          :required="required"
          v-bind="attr"
          @input="$emit('input', child.vars.value)"
          @keydown.tab="onTab"
          @focus="handleFocus"
          @blur="handleBlur"
          @invalid="handleInvalidInput"
        />

        <span class="radio-circle" />

        <div class="label">
          <div class="label-text">
            {{ child.vars.label }}
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="!!child.vars.attr && !!child.vars.attr.text"
            class="label-info"
            v-html="child.vars.attr.text"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </label>
    </div>
    <template v-if="!!errors && !!showMessage" :showMessage="showMessage">
      <div
        v-for="error in errors"
        :key="error.message"
        :class="['error', 'qa-Input__error']"
      >
        {{ error.message }}
      </div>
    </template>
  </div>
</template>

<script>
import { formBaseMixin } from 'Libs/mixins/formBaseMixin.js';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'Radio',
  mixins: [globalMixin, formBaseMixin],
  props: {
    checked: {
      type: Boolean,
      default: null,
    },
    type: {
      type: String,
      default: 'radio',
    },
    text: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
    },
    showMessage: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.$watch('value', () => this.removeModifier('error'));
  },
};
</script>

<style lang="scss">
// TODO Move to global stylesheet?
@import 'variables/breakpoints';
@import 'mixins';

/* .radio */
.label-info a {
  @include themeColor(
    color,
    var(--color-tory-blue),
    var(--color-gold-drop),
    var(--color-picton-blue)
  );
}
</style>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.radio {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  @include clearfix;
}

.headline {
  padding-bottom: var(--space-1);

  color: var(--color-black--secondary);
  font-size: var(--font-size-M);
  line-height: 24px;
}

.option-list {
  flex-direction: column;
  display: flex;
}

.option {
  margin: 0;
  cursor: pointer;
  display: flex;

  &:last-child {
    margin-right: 0;
  }

  & + .option {
    margin-top: var(--space-1);
  }
}

.native {
  position: absolute;
  opacity: 0;
}

.radio-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: var(--space-XXS);
  width: 20px;
  height: 20px;
  cursor: pointer;

  border-width: 2px;
  border-style: solid;
  border-color: var(--color-rolling-stone);
  border-radius: 50%;
  box-shadow: 0 0 0 3px transparent;

  transition:
    border-color var(--time-XS) ease-in-out,
    box-shadow var(--time-XS) ease-in-out;

  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;

    background-color: transparent;

    cursor: pointer;
    transition: background-color var(--time-XS) ease-in-out;
  }
}

.label {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  vertical-align: baseline;
  padding-left: 8px;
  padding-top: 2px;
  color: var(--color-black--primary);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.label-text {
  display: block;
  font-size: 15px;
  line-height: 20px;
}

.label-info {
  margin-top: var(--space-XXS);
  display: block;
  font-size: var(--font-size-SM);
  font-weight: normal;
  line-height: 16px;
  padding-top: 2px;
}

.error {
  margin-top: var(--space-1);
  font-size: var(--font-size-S);
  line-height: 16px;
  color: var(--color-red-ribbon);
  display: none;
}

.native:checked {
  + .radio-circle {
    @include themeColor(
      border-color,
      var(--color-gold-drop),
      var(--color-tory-blue),
      var(--color-picton-blue)
    );

    &::before {
      @include themeColor(
        background-color,
        var(--color-gold-drop),
        var(--color-tory-blue),
        var(--color-picton-blue)
      );
    }
  }

  + .radio-circle + .label .label-text,
  + .radio-circle + .label .label-text + .label-info {
    @include themeColor(
      color,
      var(--color-gold-drop),
      var(--color-tory-blue),
      var(--color-chathamsblue)
    );
  }
}

.native:focus,
.radio--focused .native:checked {
  outline: none;
}

.radio--inline {
  .option-list {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .option {
    margin-right: var(--space-4);
    padding-right: var(--space-2);

    &:last-child {
      padding-right: 0;
    }
  }

  .option + .option {
    margin-top: 0;
  }
}

.radio--block {
  border: 1px solid var(--color-black--decorative);

  .option {
    padding: 8px 16px;
    margin-top: 0;
  }

  .option + .option {
    border-top: 1px solid var(--color-black--decorative);
  }
}

.radio--disabled,
.radio--readonly {
  label {
    pointer-events: none;
  }

  .label-text,
  .label-info {
    color: var(--color-boulder);
  }
}

.radio--error {
  .label-text,
  .label-info {
    color: var(--color-red-ribbon);
  }

  .error {
    display: block;
  }
}

.radio--tertiary {
  ::v-deep .native:checked {
    + .radio-circle {
      border-color: var(--color-secondary) !important;

      &::before {
        background-color: var(--color-secondary) !important;
      }
    }

    + .radio-circle + .label .label-text,
    + .radio-circle + .label .label-text + .label-info {
      color: var(--color-secondary) !important;
    }
  }
}

.radio--rebranding {
  ::v-deep .native:checked {
    + .radio-circle {
      border-color: var(--color-rebranding-secondary) !important;

      &::before {
        background-color: var(--color-rebranding-secondary) !important;
      }
    }

    + .radio-circle + .label .label-text,
    + .radio-circle + .label .label-text + .label-info {
      color: var(--color-rebranding-secondary) !important;
    }
  }
}
</style>
