export const MINICART_UPDATE = (state, data) => {
  if (data) {
    state.data = { ...data };
  }
};

export const SET_MINICART_TOTAL_PRODUCT_COUNT = (state, count) => {
  state.data.totalProductCount = count;
};

/**
 * Only used when the user is inside the Wishlist page and adds to wishlist from the mini cart.
 */
export const SET_PRODUCT_IN_WISHLIST = (state, product) => {
  const updatedProduct = state.data.products.find((obj) => obj.productId === product.productId);
  /**
   * As find returns a ref we edit the original object in the array of objects.
   * No need to reassign a new array with the updated object
   */
  updatedProduct.inWishlist = updatedProduct.inWishlist ? !updatedProduct.inWishlist : true;
};
