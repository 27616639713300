import qs from 'qs';
import AjaxService, { axios } from '../../../services/AjaxService';
import { has } from 'lodash';

const WISHLIST_SHARE_LINK_ENDPOINT_KEY = 'checkout.ajax.wishlist.share';
const WISHLIST_ADD_ALL_ENDPOINT_KEY = 'checkout.ajax.wishlist.cart.add_all';
const WISHLIST_EMPTY_ENDPOINT_KEY = 'checkout.ajax.wishlist.clear';
const WISHLIST_UPDATE_QUANTITY_ENDPOINT_KEY = 'checkout.ajax.wishlist.modify';
const WISHLIST_ADD_ENDPOINT_KEY = 'checkout.ajax.wishlist.add';
const WISHLIST_ADD_FROM_WISHLIST_ENDPOINT_KEY =
  'checkout.ajax.wishlist.wishlist.add';
const WISHLIST_ADD_TO_CART_ENDPOINT_KEY =
  'checkout.ajax.wishlist.cart.add_single';

const updateWishlistState = ({ result, commit }) => {
  if (has(result, 'data')) {
    if (has(result, 'data.wishlist')) {
      // [izerozlu] result will always be populated, so it's better to suppress the check
      // noinspection JSObjectNullOrUndefined
      commit('SET_WISHLIST', result.data.wishlist);
    }
  }
};

const updateWishlistAndCartStores = ({ cart, wishlist }, commit) => {
  if (cart) {
    commit('SET_CART', cart);
  }
  if (wishlist) {
    commit('SET_WISHLIST', wishlist);
  }
};

export const updateQuantity = async (
  { rootState, state, commit },
  { sku, carTypeNumber, amount, productId }
) => {
  const { fetchedUrls } = rootState.core;

  let result;
  try {
    const payload = {
      wishlistId: state.idWishlist,
      sku,
      carTypeNumber,
      amount,
      productId,
    };

    result = await AjaxService.post(
      fetchedUrls[WISHLIST_UPDATE_QUANTITY_ENDPOINT_KEY].replace(
        '{idWishlist}',
        state.idWishlist
      ),
      qs.stringify(payload)
    );
  } catch (e) {
    result = null;
  }

  updateWishlistState({ result, commit });

  return result && result.data;
};

export const addToCartFromWishlist = async (
  { rootState, state, commit },
  { sku, carTypeNumber, amount, productId }
) => {
  const { fetchedUrls } = rootState.core;
  const payload = {
    wishlistId: state.idWishlist,
    sku,
    carTypeNumber,
    amount,
    productId,
  };

  let result;
  try {
    result = await AjaxService.post(
      fetchedUrls[WISHLIST_ADD_TO_CART_ENDPOINT_KEY],
      qs.stringify(payload)
    );
  } catch (e) {
    result = null;
  }
  if (has(result, 'data.cart')) {
    // [izerozlu] result will always be populated, so it's better to suppress the check
    // noinspection JSObjectNullOrUndefined
    commit('cart/SET_TOTAL_PRODUCT_COUNT', result.data.cart.totalProductCount, {
      root: true,
    });
    commit('cart/SET_CART', result.data.cart, { root: true });// [andrei] Update the cart with the new product
  }

  return result;
};

export const addFromWishlist = async (
  { rootState, state, commit },
  { sku, carTypeNumber, amount, productId }
) => {
  const { fetchedUrls } = rootState.core;
  const payload = {
    wishlistId: state.idWishlist,
    sku,
    carTypeNumber,
    amount,
    productId,
  };

  let result;
  try {
    result = await AjaxService.post(
      fetchedUrls[WISHLIST_ADD_FROM_WISHLIST_ENDPOINT_KEY].replace(
        '{idWishlist}',
        state.idWishlist
      ),
      qs.stringify(payload)
    );
  } catch (e) {
    result = null;
  }

  updateWishlistState({ result, commit });

  return result && result.data;
};

export const addToWishlist = async ({ commit, state, rootState }, data) => {
  const url = rootState.core.fetchedUrls[WISHLIST_ADD_ENDPOINT_KEY];

  const payload = {
    sku: data.product.sku,
    carTypeNumber: data.product.carTypeNumber,
    quantity: data.quantity,
    productId: data.product.productId,
    list: data.list,
  };

  const result = await axios.post(url, qs.stringify(payload));

  updateWishlistState({ result, commit });

  if (result.data) {
    state.data.isWished = true;
  }
};

export const getShareLink = async ({ rootState, state }) => {
  const { fetchedUrls } = rootState.core;

  let result;
  try {
    result = await AjaxService.post(
      fetchedUrls[WISHLIST_SHARE_LINK_ENDPOINT_KEY].replace(
        '{idWishlist}',
        state.idWishlist
      ),
      { wishlistId: state.idWishlist }
    );
  } catch (e) {
    result = null;
  }

  return { shareLink: result ? result.data.url : null };
};

export const addAllToCart = async ({ rootState, commit }, { wishlistId }) => {
  const { fetchedUrls } = rootState.core;
  let result;
  try {
    result = await AjaxService.post(
      fetchedUrls[WISHLIST_ADD_ALL_ENDPOINT_KEY].replace(
        '{idWishlist}',
        wishlistId
      ),
      { wishlistId }
    );
    // Update Minicart
    commit('miniCart/MINICART_UPDATE', result.data.cart, { root: true });
  } catch (e) {
    result = null;
  }

  if (has(result, 'data')) {
    // [izerozlu] result will always be populated, so it's better to suppress the check
    // noinspection JSObjectNullOrUndefined
    updateWishlistAndCartStores(result.data, commit);
  }
};

export const emptyWishlist = async ({ rootState, commit }, { wishlistId }) => {
  const { fetchedUrls } = rootState.core;
  let result;
  try {
    result = await AjaxService.post(
      fetchedUrls[WISHLIST_EMPTY_ENDPOINT_KEY].replace(
        '{idWishlist}',
        wishlistId
      ),
      { wishlistId }
    );
  } catch (e) {
    result = null;
  }

  if (has(result, 'data')) {
    // [izerozlu] result will always be populated, so it's better to suppress the check
    // noinspection JSObjectNullOrUndefined
    updateWishlistAndCartStores(result.data, commit);
  }
};
