import { render, staticRenderFns } from "./PlpProduct21.vue?vue&type=template&id=2092b7f4&scoped=true"
import script from "./PlpProduct21.vue?vue&type=script&lang=js"
export * from "./PlpProduct21.vue?vue&type=script&lang=js"
import style0 from "./PlpProduct21.vue?vue&type=style&index=0&id=2092b7f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2092b7f4",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fplp-product-21%2FPlpProduct21.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports