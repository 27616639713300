import { render, staticRenderFns } from "./HeaderWishlistButton.vue?vue&type=template&id=04988928&scoped=true"
import script from "./HeaderWishlistButton.vue?vue&type=script&lang=js"
export * from "./HeaderWishlistButton.vue?vue&type=script&lang=js"
import style0 from "./HeaderWishlistButton.vue?vue&type=style&index=0&id=04988928&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04988928",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F03-organisms%2Fheader-wishlist-button%2FHeaderWishlistButton.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports