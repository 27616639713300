
<template>
  <Fragment>
    <component
      :is="componentName"
      v-if="inline"
      :width="width"
      :height="height"
      :original="original"
      :class="classes"
      data-components="Icon"
    />
    <img
      v-else-if="lazy"
      class="lazy"
      :src="iconPath"
      :width="width"
      :height="height"
      loading="lazy"
    >
    <img
      v-else-if="!lazy"
      :src="iconPath"
      :width="width"
      :height="height"
    >
  </Fragment>
</template>

<script>
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import globalMixin from 'Libs/mixins/globalMixin';
import Fragment from 'Components/01-atoms/fragment/Fragment';

import '../../icons/GHS01.svg';
import '../../icons/GHS02.svg';
import '../../icons/GHS03.svg';
import '../../icons/GHS04.svg';
import '../../icons/GHS05.svg';
import '../../icons/GHS06.svg';
import '../../icons/GHS07.svg';
import '../../icons/GHS08.svg';
import '../../icons/GHS09.svg';
import '../../icons/accessories.svg';
import '../../icons/account.svg';
import '../../icons/add.svg';
import '../../icons/added_to_cart_check.svg';
import '../../icons/afterpay.svg';
import '../../icons/air_conditioning.svg';
import '../../icons/apple_store.svg';
import '../../icons/arrow-box.svg';
import '../../icons/arrow-open-modal.svg';
import '../../icons/arrow.svg';
import '../../icons/arrow_drop_down.svg';
import '../../icons/axle_drive.svg';
import '../../icons/axle_mounting.svg';
import '../../icons/b2b_cash_on_delivery.svg';
import '../../icons/batteries.svg';
import '../../icons/belt_drive.svg';
import '../../icons/bicycle.svg';
import '../../icons/body_parts.svg';
import '../../icons/brake_system.svg';
import '../../icons/burger_menu.svg';
import '../../icons/business_to_business_invoice.svg';
import '../../icons/camera.svg';
import '../../icons/car.svg';
import '../../icons/car_parts.svg';
import '../../icons/carrier_system.svg';
import '../../icons/cart.svg';
import '../../icons/carte_bancaire.svg';
import '../../icons/cash_on_delivery.svg';
import '../../icons/chat_arrow_down.svg';
import '../../icons/chat_service.svg';
import '../../icons/check--bold.svg';
import '../../icons/check_circle.svg';
                           import CheckCircleSvg from 'Components/00-generated/CheckCircleSvg';
import '../../icons/checked.svg';
import '../../icons/checkmark_green.svg';
import '../../icons/chevron_down.svg';
import '../../icons/chevron_left.svg';
import '../../icons/chevron_right.svg';
import '../../icons/clock.svg';
import '../../icons/close.svg';
import '../../icons/clutch_parts.svg';
import '../../icons/collected_invoice.svg';
import '../../icons/comfort_systems.svg';
import '../../icons/cooling_system.svg';
import '../../icons/cross_circle.svg';
                           import CrossCircleSvg from 'Components/00-generated/CrossCircleSvg';
import '../../icons/customer-support.svg';
import '../../icons/customer_service.svg';
import '../../icons/date_range.svg';
import '../../icons/delete.svg';
import '../../icons/delivery.svg';
import '../../icons/delivery_standard.svg';
import '../../icons/dhl.svg';
import '../../icons/document_img.svg';
import '../../icons/document_pdf.svg';
import '../../icons/download.svg';
import '../../icons/dpd.svg';
import '../../icons/edit.svg';
import '../../icons/electrics.svg';
import '../../icons/email.svg';
import '../../icons/engine.svg';
import '../../icons/eu_stars.svg';
import '../../icons/exception-warning.svg';
import '../../icons/exhaust_system.svg';
import '../../icons/explanation-blue.svg';
import '../../icons/explanation.svg';
import '../../icons/facebook.svg';
import '../../icons/facebook_deshop.svg';
import '../../icons/filter_mobile.svg';
import '../../icons/filters.svg';
import '../../icons/flag_at.svg';
import '../../icons/flag_be.svg';
import '../../icons/flag_ch.svg';
import '../../icons/flag_de.svg';
import '../../icons/flag_dk.svg';
import '../../icons/flag_es.svg';
import '../../icons/flag_fr.svg';
import '../../icons/flag_gb.svg';
import '../../icons/flag_it.svg';
import '../../icons/flag_lu.svg';
import '../../icons/flag_nl.svg';
import '../../icons/fuel_efficiency_class.svg';
import '../../icons/fuel_preparation_system.svg';
import '../../icons/fuel_supply_system.svg';
import '../../icons/go.svg';
import '../../icons/google_play_store.svg';
import '../../icons/green_check.svg';
import '../../icons/guaranteed.svg';
import '../../icons/headlight_cleaning.svg';
import '../../icons/heating.svg';
import '../../icons/help-center_contact.svg';
import '../../icons/help-center_faq.svg';
import '../../icons/help-center_return_and_guarantee.svg';
import '../../icons/help.svg';
import '../../icons/ideal.svg';
import '../../icons/ignition_plug.svg';
import '../../icons/info-filled.svg';
import '../../icons/info-outline.svg';
import '../../icons/info.svg';
import '../../icons/information_systems.svg';
import '../../icons/instagram.svg';
import '../../icons/instagram_deshop.svg';
import '../../icons/interior.svg';
import '../../icons/lamps.svg';
import '../../icons/leftnavi_dot.svg';
import '../../icons/linkedin.svg';
import '../../icons/linkedin_deshop.svg';
import '../../icons/loading.svg';
import '../../icons/loading_orange.svg';
import '../../icons/location.svg';
import '../../icons/locking_system.svg';
import '../../icons/logo_autoteile24.svg';
                           import LogoAutoteile24Svg from 'Components/00-generated/LogoAutoteile24Svg';
import '../../icons/logo_autoteile24_small.svg';
                           import LogoAutoteile24SmallSvg from 'Components/00-generated/LogoAutoteile24SmallSvg';
import '../../icons/logo_carpardoo.svg';
                           import LogoCarpardooSvg from 'Components/00-generated/LogoCarpardooSvg';
import '../../icons/logo_kfz24.svg';
                           import LogoKfz24Svg from 'Components/00-generated/LogoKfz24Svg';
import '../../icons/logo_kfz24_small.svg';
                           import LogoKfz24SmallSvg from 'Components/00-generated/LogoKfz24SmallSvg';
import '../../icons/logo_kfzteile-com.svg';
                           import LogoKfzteileComSvg from 'Components/00-generated/LogoKfzteileComSvg';
import '../../icons/logo_mono.svg';
                           import LogoMonoSvg from 'Components/00-generated/LogoMonoSvg';
import '../../icons/mail.svg';
import '../../icons/maintainance_products.svg';
import '../../icons/mastercard.svg';
import '../../icons/menu.svg';
import '../../icons/new_document_pdf.svg';
import '../../icons/no-image.svg';
import '../../icons/noise_emission_class.svg';
import '../../icons/noted_outlined.svg';
import '../../icons/oils_lubricants.svg';
import '../../icons/paper_plane.svg';
import '../../icons/payment-options.svg';
import '../../icons/paypal.svg';
import '../../icons/payu.svg';
import '../../icons/phone.svg';
import '../../icons/plus.svg';
import '../../icons/postnord.svg';
import '../../icons/prepayment.svg';
import '../../icons/product_label_plus.svg';
import '../../icons/red_warning.svg';
import '../../icons/reload.svg';
import '../../icons/reload_small.svg';
import '../../icons/remove.svg';
import '../../icons/return.svg';
import '../../icons/returns-and-guarantee__fifth-step.svg';
import '../../icons/returns-and-guarantee__first-step.svg';
import '../../icons/returns-and-guarantee__fourth-step.svg';
import '../../icons/returns-and-guarantee__second-step.svg';
import '../../icons/returns-and-guarantee__third-step.svg';
import '../../icons/rieck.svg';
import '../../icons/safety_system.svg';
import '../../icons/search.svg';
import '../../icons/search_small.svg';
import '../../icons/seasonal_article.svg';
import '../../icons/service.svg';
import '../../icons/shipment.svg';
import '../../icons/shipping.svg';
import '../../icons/shipping_truck.svg';
import '../../icons/shock_absorber.svg';
import '../../icons/sofort.svg';
import '../../icons/spare_parts.svg';
import '../../icons/spark_glow_ignition.svg';
import '../../icons/steering.svg';
import '../../icons/stored_creditcard.svg';
import '../../icons/streetwear.svg';
import '../../icons/support.svg';
import '../../icons/technical-support.svg';
import '../../icons/thumb_up.svg';
import '../../icons/tiktok.svg';
import '../../icons/tiktok_deshop.svg';
import '../../icons/tools.svg';
import '../../icons/trailer_hitch.svg';
import '../../icons/transmission.svg';
import '../../icons/triangle.svg';
import '../../icons/tuning.svg';
import '../../icons/twitter.svg';
import '../../icons/tyres_wheels.svg';
import '../../icons/undefined.svg';
import '../../icons/usp-24.svg';
import '../../icons/usp-percentage.svg';
import '../../icons/usp-return.svg';
import '../../icons/visa.svg';
import '../../icons/visibility.svg';
import '../../icons/visibility_off.svg';
import '../../icons/warning.svg';
import '../../icons/wet_grip_class.svg';
import '../../icons/whatsapp.svg';
import '../../icons/wheel_drive.svg';
import '../../icons/windscreen_window_cleaning_system.svg';
import '../../icons/wishlist.svg';
import '../../icons/wishlist_checked.svg';
import '../../icons/wishlist_filled_heart.svg';
import '../../icons/wishlist_heart.svg';
import '../../icons/workshop-request.svg';
import '../../icons/xing.svg';
import '../../icons/xing_deshop.svg';
import '../../icons/yellow_warning.svg';
import '../../icons/youtube.svg';
import '../../icons/youtube_deshop.svg';

/* eslint-disable */
export default {
  name: 'Icon',
  mixins: [globalMixin],
  components: {
    Fragment,
    Ghs01Svg: () => import('Components/00-generated/Ghs01Svg.vue' /* webpackChunkName: "Ghs01Svg" */),
    Ghs02Svg: () => import('Components/00-generated/Ghs02Svg.vue' /* webpackChunkName: "Ghs02Svg" */),
    Ghs03Svg: () => import('Components/00-generated/Ghs03Svg.vue' /* webpackChunkName: "Ghs03Svg" */),
    Ghs04Svg: () => import('Components/00-generated/Ghs04Svg.vue' /* webpackChunkName: "Ghs04Svg" */),
    Ghs05Svg: () => import('Components/00-generated/Ghs05Svg.vue' /* webpackChunkName: "Ghs05Svg" */),
    Ghs06Svg: () => import('Components/00-generated/Ghs06Svg.vue' /* webpackChunkName: "Ghs06Svg" */),
    Ghs07Svg: () => import('Components/00-generated/Ghs07Svg.vue' /* webpackChunkName: "Ghs07Svg" */),
    Ghs08Svg: () => import('Components/00-generated/Ghs08Svg.vue' /* webpackChunkName: "Ghs08Svg" */),
    Ghs09Svg: () => import('Components/00-generated/Ghs09Svg.vue' /* webpackChunkName: "Ghs09Svg" */),
    AccessoriesSvg: () => import('Components/00-generated/AccessoriesSvg.vue' /* webpackChunkName: "AccessoriesSvg" */),
    AccountSvg: () => import('Components/00-generated/AccountSvg.vue' /* webpackChunkName: "AccountSvg" */),
    AddSvg: () => import('Components/00-generated/AddSvg.vue' /* webpackChunkName: "AddSvg" */),
    AddedToCartCheckSvg: () => import('Components/00-generated/AddedToCartCheckSvg.vue' /* webpackChunkName: "AddedToCartCheckSvg" */),
    AfterpaySvg: () => import('Components/00-generated/AfterpaySvg.vue' /* webpackChunkName: "AfterpaySvg" */),
    AirConditioningSvg: () => import('Components/00-generated/AirConditioningSvg.vue' /* webpackChunkName: "AirConditioningSvg" */),
    AppleStoreSvg: () => import('Components/00-generated/AppleStoreSvg.vue' /* webpackChunkName: "AppleStoreSvg" */),
    ArrowBoxSvg: () => import('Components/00-generated/ArrowBoxSvg.vue' /* webpackChunkName: "ArrowBoxSvg" */),
    ArrowOpenModalSvg: () => import('Components/00-generated/ArrowOpenModalSvg.vue' /* webpackChunkName: "ArrowOpenModalSvg" */),
    ArrowSvg: () => import('Components/00-generated/ArrowSvg.vue' /* webpackChunkName: "ArrowSvg" */),
    ArrowDropDownSvg: () => import('Components/00-generated/ArrowDropDownSvg.vue' /* webpackChunkName: "ArrowDropDownSvg" */),
    AxleDriveSvg: () => import('Components/00-generated/AxleDriveSvg.vue' /* webpackChunkName: "AxleDriveSvg" */),
    AxleMountingSvg: () => import('Components/00-generated/AxleMountingSvg.vue' /* webpackChunkName: "AxleMountingSvg" */),
    B2BCashOnDeliverySvg: () => import('Components/00-generated/B2BCashOnDeliverySvg.vue' /* webpackChunkName: "B2BCashOnDeliverySvg" */),
    BatteriesSvg: () => import('Components/00-generated/BatteriesSvg.vue' /* webpackChunkName: "BatteriesSvg" */),
    BeltDriveSvg: () => import('Components/00-generated/BeltDriveSvg.vue' /* webpackChunkName: "BeltDriveSvg" */),
    BicycleSvg: () => import('Components/00-generated/BicycleSvg.vue' /* webpackChunkName: "BicycleSvg" */),
    BodyPartsSvg: () => import('Components/00-generated/BodyPartsSvg.vue' /* webpackChunkName: "BodyPartsSvg" */),
    BrakeSystemSvg: () => import('Components/00-generated/BrakeSystemSvg.vue' /* webpackChunkName: "BrakeSystemSvg" */),
    BurgerMenuSvg: () => import('Components/00-generated/BurgerMenuSvg.vue' /* webpackChunkName: "BurgerMenuSvg" */),
    BusinessToBusinessInvoiceSvg: () => import('Components/00-generated/BusinessToBusinessInvoiceSvg.vue' /* webpackChunkName: "BusinessToBusinessInvoiceSvg" */),
    CameraSvg: () => import('Components/00-generated/CameraSvg.vue' /* webpackChunkName: "CameraSvg" */),
    CarSvg: () => import('Components/00-generated/CarSvg.vue' /* webpackChunkName: "CarSvg" */),
    CarPartsSvg: () => import('Components/00-generated/CarPartsSvg.vue' /* webpackChunkName: "CarPartsSvg" */),
    CarrierSystemSvg: () => import('Components/00-generated/CarrierSystemSvg.vue' /* webpackChunkName: "CarrierSystemSvg" */),
    CartSvg: () => import('Components/00-generated/CartSvg.vue' /* webpackChunkName: "CartSvg" */),
    CarteBancaireSvg: () => import('Components/00-generated/CarteBancaireSvg.vue' /* webpackChunkName: "CarteBancaireSvg" */),
    CashOnDeliverySvg: () => import('Components/00-generated/CashOnDeliverySvg.vue' /* webpackChunkName: "CashOnDeliverySvg" */),
    ChatArrowDownSvg: () => import('Components/00-generated/ChatArrowDownSvg.vue' /* webpackChunkName: "ChatArrowDownSvg" */),
    ChatServiceSvg: () => import('Components/00-generated/ChatServiceSvg.vue' /* webpackChunkName: "ChatServiceSvg" */),
    CheckBoldSvg: () => import('Components/00-generated/CheckBoldSvg.vue' /* webpackChunkName: "CheckBoldSvg" */),
    CheckCircleSvg,
    CheckedSvg: () => import('Components/00-generated/CheckedSvg.vue' /* webpackChunkName: "CheckedSvg" */),
    CheckmarkGreenSvg: () => import('Components/00-generated/CheckmarkGreenSvg.vue' /* webpackChunkName: "CheckmarkGreenSvg" */),
    ChevronDownSvg: () => import('Components/00-generated/ChevronDownSvg.vue' /* webpackChunkName: "ChevronDownSvg" */),
    ChevronLeftSvg: () => import('Components/00-generated/ChevronLeftSvg.vue' /* webpackChunkName: "ChevronLeftSvg" */),
    ChevronRightSvg: () => import('Components/00-generated/ChevronRightSvg.vue' /* webpackChunkName: "ChevronRightSvg" */),
    ClockSvg: () => import('Components/00-generated/ClockSvg.vue' /* webpackChunkName: "ClockSvg" */),
    CloseSvg: () => import('Components/00-generated/CloseSvg.vue' /* webpackChunkName: "CloseSvg" */),
    ClutchPartsSvg: () => import('Components/00-generated/ClutchPartsSvg.vue' /* webpackChunkName: "ClutchPartsSvg" */),
    CollectedInvoiceSvg: () => import('Components/00-generated/CollectedInvoiceSvg.vue' /* webpackChunkName: "CollectedInvoiceSvg" */),
    ComfortSystemsSvg: () => import('Components/00-generated/ComfortSystemsSvg.vue' /* webpackChunkName: "ComfortSystemsSvg" */),
    CoolingSystemSvg: () => import('Components/00-generated/CoolingSystemSvg.vue' /* webpackChunkName: "CoolingSystemSvg" */),
    CrossCircleSvg,
    CustomerSupportSvg: () => import('Components/00-generated/CustomerSupportSvg.vue' /* webpackChunkName: "CustomerSupportSvg" */),
    CustomerServiceSvg: () => import('Components/00-generated/CustomerServiceSvg.vue' /* webpackChunkName: "CustomerServiceSvg" */),
    DateRangeSvg: () => import('Components/00-generated/DateRangeSvg.vue' /* webpackChunkName: "DateRangeSvg" */),
    DeleteSvg: () => import('Components/00-generated/DeleteSvg.vue' /* webpackChunkName: "DeleteSvg" */),
    DeliverySvg: () => import('Components/00-generated/DeliverySvg.vue' /* webpackChunkName: "DeliverySvg" */),
    DeliveryStandardSvg: () => import('Components/00-generated/DeliveryStandardSvg.vue' /* webpackChunkName: "DeliveryStandardSvg" */),
    DhlSvg: () => import('Components/00-generated/DhlSvg.vue' /* webpackChunkName: "DhlSvg" */),
    DocumentImgSvg: () => import('Components/00-generated/DocumentImgSvg.vue' /* webpackChunkName: "DocumentImgSvg" */),
    DocumentPdfSvg: () => import('Components/00-generated/DocumentPdfSvg.vue' /* webpackChunkName: "DocumentPdfSvg" */),
    DownloadSvg: () => import('Components/00-generated/DownloadSvg.vue' /* webpackChunkName: "DownloadSvg" */),
    DpdSvg: () => import('Components/00-generated/DpdSvg.vue' /* webpackChunkName: "DpdSvg" */),
    EditSvg: () => import('Components/00-generated/EditSvg.vue' /* webpackChunkName: "EditSvg" */),
    ElectricsSvg: () => import('Components/00-generated/ElectricsSvg.vue' /* webpackChunkName: "ElectricsSvg" */),
    EmailSvg: () => import('Components/00-generated/EmailSvg.vue' /* webpackChunkName: "EmailSvg" */),
    EngineSvg: () => import('Components/00-generated/EngineSvg.vue' /* webpackChunkName: "EngineSvg" */),
    EuStarsSvg: () => import('Components/00-generated/EuStarsSvg.vue' /* webpackChunkName: "EuStarsSvg" */),
    ExceptionWarningSvg: () => import('Components/00-generated/ExceptionWarningSvg.vue' /* webpackChunkName: "ExceptionWarningSvg" */),
    ExhaustSystemSvg: () => import('Components/00-generated/ExhaustSystemSvg.vue' /* webpackChunkName: "ExhaustSystemSvg" */),
    ExplanationBlueSvg: () => import('Components/00-generated/ExplanationBlueSvg.vue' /* webpackChunkName: "ExplanationBlueSvg" */),
    ExplanationSvg: () => import('Components/00-generated/ExplanationSvg.vue' /* webpackChunkName: "ExplanationSvg" */),
    FacebookSvg: () => import('Components/00-generated/FacebookSvg.vue' /* webpackChunkName: "FacebookSvg" */),
    FacebookDeshopSvg: () => import('Components/00-generated/FacebookDeshopSvg.vue' /* webpackChunkName: "FacebookDeshopSvg" */),
    FilterMobileSvg: () => import('Components/00-generated/FilterMobileSvg.vue' /* webpackChunkName: "FilterMobileSvg" */),
    FiltersSvg: () => import('Components/00-generated/FiltersSvg.vue' /* webpackChunkName: "FiltersSvg" */),
    FlagAtSvg: () => import('Components/00-generated/FlagAtSvg.vue' /* webpackChunkName: "FlagAtSvg" */),
    FlagBeSvg: () => import('Components/00-generated/FlagBeSvg.vue' /* webpackChunkName: "FlagBeSvg" */),
    FlagChSvg: () => import('Components/00-generated/FlagChSvg.vue' /* webpackChunkName: "FlagChSvg" */),
    FlagDeSvg: () => import('Components/00-generated/FlagDeSvg.vue' /* webpackChunkName: "FlagDeSvg" */),
    FlagDkSvg: () => import('Components/00-generated/FlagDkSvg.vue' /* webpackChunkName: "FlagDkSvg" */),
    FlagEsSvg: () => import('Components/00-generated/FlagEsSvg.vue' /* webpackChunkName: "FlagEsSvg" */),
    FlagFrSvg: () => import('Components/00-generated/FlagFrSvg.vue' /* webpackChunkName: "FlagFrSvg" */),
    FlagGbSvg: () => import('Components/00-generated/FlagGbSvg.vue' /* webpackChunkName: "FlagGbSvg" */),
    FlagItSvg: () => import('Components/00-generated/FlagItSvg.vue' /* webpackChunkName: "FlagItSvg" */),
    FlagLuSvg: () => import('Components/00-generated/FlagLuSvg.vue' /* webpackChunkName: "FlagLuSvg" */),
    FlagNlSvg: () => import('Components/00-generated/FlagNlSvg.vue' /* webpackChunkName: "FlagNlSvg" */),
    FuelEfficiencyClassSvg: () => import('Components/00-generated/FuelEfficiencyClassSvg.vue' /* webpackChunkName: "FuelEfficiencyClassSvg" */),
    FuelPreparationSystemSvg: () => import('Components/00-generated/FuelPreparationSystemSvg.vue' /* webpackChunkName: "FuelPreparationSystemSvg" */),
    FuelSupplySystemSvg: () => import('Components/00-generated/FuelSupplySystemSvg.vue' /* webpackChunkName: "FuelSupplySystemSvg" */),
    GoSvg: () => import('Components/00-generated/GoSvg.vue' /* webpackChunkName: "GoSvg" */),
    GooglePlayStoreSvg: () => import('Components/00-generated/GooglePlayStoreSvg.vue' /* webpackChunkName: "GooglePlayStoreSvg" */),
    GreenCheckSvg: () => import('Components/00-generated/GreenCheckSvg.vue' /* webpackChunkName: "GreenCheckSvg" */),
    GuaranteedSvg: () => import('Components/00-generated/GuaranteedSvg.vue' /* webpackChunkName: "GuaranteedSvg" */),
    HeadlightCleaningSvg: () => import('Components/00-generated/HeadlightCleaningSvg.vue' /* webpackChunkName: "HeadlightCleaningSvg" */),
    HeatingSvg: () => import('Components/00-generated/HeatingSvg.vue' /* webpackChunkName: "HeatingSvg" */),
    HelpCenterContactSvg: () => import('Components/00-generated/HelpCenterContactSvg.vue' /* webpackChunkName: "HelpCenterContactSvg" */),
    HelpCenterFaqSvg: () => import('Components/00-generated/HelpCenterFaqSvg.vue' /* webpackChunkName: "HelpCenterFaqSvg" */),
    HelpCenterReturnAndGuaranteeSvg: () => import('Components/00-generated/HelpCenterReturnAndGuaranteeSvg.vue' /* webpackChunkName: "HelpCenterReturnAndGuaranteeSvg" */),
    HelpSvg: () => import('Components/00-generated/HelpSvg.vue' /* webpackChunkName: "HelpSvg" */),
    IdealSvg: () => import('Components/00-generated/IdealSvg.vue' /* webpackChunkName: "IdealSvg" */),
    IgnitionPlugSvg: () => import('Components/00-generated/IgnitionPlugSvg.vue' /* webpackChunkName: "IgnitionPlugSvg" */),
    InfoFilledSvg: () => import('Components/00-generated/InfoFilledSvg.vue' /* webpackChunkName: "InfoFilledSvg" */),
    InfoOutlineSvg: () => import('Components/00-generated/InfoOutlineSvg.vue' /* webpackChunkName: "InfoOutlineSvg" */),
    InfoSvg: () => import('Components/00-generated/InfoSvg.vue' /* webpackChunkName: "InfoSvg" */),
    InformationSystemsSvg: () => import('Components/00-generated/InformationSystemsSvg.vue' /* webpackChunkName: "InformationSystemsSvg" */),
    InstagramSvg: () => import('Components/00-generated/InstagramSvg.vue' /* webpackChunkName: "InstagramSvg" */),
    InstagramDeshopSvg: () => import('Components/00-generated/InstagramDeshopSvg.vue' /* webpackChunkName: "InstagramDeshopSvg" */),
    InteriorSvg: () => import('Components/00-generated/InteriorSvg.vue' /* webpackChunkName: "InteriorSvg" */),
    LampsSvg: () => import('Components/00-generated/LampsSvg.vue' /* webpackChunkName: "LampsSvg" */),
    LeftnaviDotSvg: () => import('Components/00-generated/LeftnaviDotSvg.vue' /* webpackChunkName: "LeftnaviDotSvg" */),
    LinkedinSvg: () => import('Components/00-generated/LinkedinSvg.vue' /* webpackChunkName: "LinkedinSvg" */),
    LinkedinDeshopSvg: () => import('Components/00-generated/LinkedinDeshopSvg.vue' /* webpackChunkName: "LinkedinDeshopSvg" */),
    LoadingSvg: () => import('Components/00-generated/LoadingSvg.vue' /* webpackChunkName: "LoadingSvg" */),
    LoadingOrangeSvg: () => import('Components/00-generated/LoadingOrangeSvg.vue' /* webpackChunkName: "LoadingOrangeSvg" */),
    LocationSvg: () => import('Components/00-generated/LocationSvg.vue' /* webpackChunkName: "LocationSvg" */),
    LockingSystemSvg: () => import('Components/00-generated/LockingSystemSvg.vue' /* webpackChunkName: "LockingSystemSvg" */),
    LogoAutoteile24Svg,
    LogoAutoteile24SmallSvg,
    LogoCarpardooSvg,
    LogoKfz24Svg,
    LogoKfz24SmallSvg,
    LogoKfzteileComSvg,
    LogoMonoSvg,
    MailSvg: () => import('Components/00-generated/MailSvg.vue' /* webpackChunkName: "MailSvg" */),
    MaintainanceProductsSvg: () => import('Components/00-generated/MaintainanceProductsSvg.vue' /* webpackChunkName: "MaintainanceProductsSvg" */),
    MastercardSvg: () => import('Components/00-generated/MastercardSvg.vue' /* webpackChunkName: "MastercardSvg" */),
    MenuSvg: () => import('Components/00-generated/MenuSvg.vue' /* webpackChunkName: "MenuSvg" */),
    NewDocumentPdfSvg: () => import('Components/00-generated/NewDocumentPdfSvg.vue' /* webpackChunkName: "NewDocumentPdfSvg" */),
    NoImageSvg: () => import('Components/00-generated/NoImageSvg.vue' /* webpackChunkName: "NoImageSvg" */),
    NoiseEmissionClassSvg: () => import('Components/00-generated/NoiseEmissionClassSvg.vue' /* webpackChunkName: "NoiseEmissionClassSvg" */),
    NotedOutlinedSvg: () => import('Components/00-generated/NotedOutlinedSvg.vue' /* webpackChunkName: "NotedOutlinedSvg" */),
    OilsLubricantsSvg: () => import('Components/00-generated/OilsLubricantsSvg.vue' /* webpackChunkName: "OilsLubricantsSvg" */),
    PaperPlaneSvg: () => import('Components/00-generated/PaperPlaneSvg.vue' /* webpackChunkName: "PaperPlaneSvg" */),
    PaymentOptionsSvg: () => import('Components/00-generated/PaymentOptionsSvg.vue' /* webpackChunkName: "PaymentOptionsSvg" */),
    PaypalSvg: () => import('Components/00-generated/PaypalSvg.vue' /* webpackChunkName: "PaypalSvg" */),
    PayuSvg: () => import('Components/00-generated/PayuSvg.vue' /* webpackChunkName: "PayuSvg" */),
    PhoneSvg: () => import('Components/00-generated/PhoneSvg.vue' /* webpackChunkName: "PhoneSvg" */),
    PlusSvg: () => import('Components/00-generated/PlusSvg.vue' /* webpackChunkName: "PlusSvg" */),
    PostnordSvg: () => import('Components/00-generated/PostnordSvg.vue' /* webpackChunkName: "PostnordSvg" */),
    PrepaymentSvg: () => import('Components/00-generated/PrepaymentSvg.vue' /* webpackChunkName: "PrepaymentSvg" */),
    ProductLabelPlusSvg: () => import('Components/00-generated/ProductLabelPlusSvg.vue' /* webpackChunkName: "ProductLabelPlusSvg" */),
    RedWarningSvg: () => import('Components/00-generated/RedWarningSvg.vue' /* webpackChunkName: "RedWarningSvg" */),
    ReloadSvg: () => import('Components/00-generated/ReloadSvg.vue' /* webpackChunkName: "ReloadSvg" */),
    ReloadSmallSvg: () => import('Components/00-generated/ReloadSmallSvg.vue' /* webpackChunkName: "ReloadSmallSvg" */),
    RemoveSvg: () => import('Components/00-generated/RemoveSvg.vue' /* webpackChunkName: "RemoveSvg" */),
    ReturnSvg: () => import('Components/00-generated/ReturnSvg.vue' /* webpackChunkName: "ReturnSvg" */),
    ReturnsAndGuaranteeFifthStepSvg: () => import('Components/00-generated/ReturnsAndGuaranteeFifthStepSvg.vue' /* webpackChunkName: "ReturnsAndGuaranteeFifthStepSvg" */),
    ReturnsAndGuaranteeFirstStepSvg: () => import('Components/00-generated/ReturnsAndGuaranteeFirstStepSvg.vue' /* webpackChunkName: "ReturnsAndGuaranteeFirstStepSvg" */),
    ReturnsAndGuaranteeFourthStepSvg: () => import('Components/00-generated/ReturnsAndGuaranteeFourthStepSvg.vue' /* webpackChunkName: "ReturnsAndGuaranteeFourthStepSvg" */),
    ReturnsAndGuaranteeSecondStepSvg: () => import('Components/00-generated/ReturnsAndGuaranteeSecondStepSvg.vue' /* webpackChunkName: "ReturnsAndGuaranteeSecondStepSvg" */),
    ReturnsAndGuaranteeThirdStepSvg: () => import('Components/00-generated/ReturnsAndGuaranteeThirdStepSvg.vue' /* webpackChunkName: "ReturnsAndGuaranteeThirdStepSvg" */),
    RieckSvg: () => import('Components/00-generated/RieckSvg.vue' /* webpackChunkName: "RieckSvg" */),
    SafetySystemSvg: () => import('Components/00-generated/SafetySystemSvg.vue' /* webpackChunkName: "SafetySystemSvg" */),
    SearchSvg: () => import('Components/00-generated/SearchSvg.vue' /* webpackChunkName: "SearchSvg" */),
    SearchSmallSvg: () => import('Components/00-generated/SearchSmallSvg.vue' /* webpackChunkName: "SearchSmallSvg" */),
    SeasonalArticleSvg: () => import('Components/00-generated/SeasonalArticleSvg.vue' /* webpackChunkName: "SeasonalArticleSvg" */),
    ServiceSvg: () => import('Components/00-generated/ServiceSvg.vue' /* webpackChunkName: "ServiceSvg" */),
    ShipmentSvg: () => import('Components/00-generated/ShipmentSvg.vue' /* webpackChunkName: "ShipmentSvg" */),
    ShippingSvg: () => import('Components/00-generated/ShippingSvg.vue' /* webpackChunkName: "ShippingSvg" */),
    ShippingTruckSvg: () => import('Components/00-generated/ShippingTruckSvg.vue' /* webpackChunkName: "ShippingTruckSvg" */),
    ShockAbsorberSvg: () => import('Components/00-generated/ShockAbsorberSvg.vue' /* webpackChunkName: "ShockAbsorberSvg" */),
    SofortSvg: () => import('Components/00-generated/SofortSvg.vue' /* webpackChunkName: "SofortSvg" */),
    SparePartsSvg: () => import('Components/00-generated/SparePartsSvg.vue' /* webpackChunkName: "SparePartsSvg" */),
    SparkGlowIgnitionSvg: () => import('Components/00-generated/SparkGlowIgnitionSvg.vue' /* webpackChunkName: "SparkGlowIgnitionSvg" */),
    SteeringSvg: () => import('Components/00-generated/SteeringSvg.vue' /* webpackChunkName: "SteeringSvg" */),
    StoredCreditcardSvg: () => import('Components/00-generated/StoredCreditcardSvg.vue' /* webpackChunkName: "StoredCreditcardSvg" */),
    StreetwearSvg: () => import('Components/00-generated/StreetwearSvg.vue' /* webpackChunkName: "StreetwearSvg" */),
    SupportSvg: () => import('Components/00-generated/SupportSvg.vue' /* webpackChunkName: "SupportSvg" */),
    TechnicalSupportSvg: () => import('Components/00-generated/TechnicalSupportSvg.vue' /* webpackChunkName: "TechnicalSupportSvg" */),
    ThumbUpSvg: () => import('Components/00-generated/ThumbUpSvg.vue' /* webpackChunkName: "ThumbUpSvg" */),
    TiktokSvg: () => import('Components/00-generated/TiktokSvg.vue' /* webpackChunkName: "TiktokSvg" */),
    TiktokDeshopSvg: () => import('Components/00-generated/TiktokDeshopSvg.vue' /* webpackChunkName: "TiktokDeshopSvg" */),
    ToolsSvg: () => import('Components/00-generated/ToolsSvg.vue' /* webpackChunkName: "ToolsSvg" */),
    TrailerHitchSvg: () => import('Components/00-generated/TrailerHitchSvg.vue' /* webpackChunkName: "TrailerHitchSvg" */),
    TransmissionSvg: () => import('Components/00-generated/TransmissionSvg.vue' /* webpackChunkName: "TransmissionSvg" */),
    TriangleSvg: () => import('Components/00-generated/TriangleSvg.vue' /* webpackChunkName: "TriangleSvg" */),
    TuningSvg: () => import('Components/00-generated/TuningSvg.vue' /* webpackChunkName: "TuningSvg" */),
    TwitterSvg: () => import('Components/00-generated/TwitterSvg.vue' /* webpackChunkName: "TwitterSvg" */),
    TyresWheelsSvg: () => import('Components/00-generated/TyresWheelsSvg.vue' /* webpackChunkName: "TyresWheelsSvg" */),
    UndefinedSvg: () => import('Components/00-generated/UndefinedSvg.vue' /* webpackChunkName: "UndefinedSvg" */),
    Usp24Svg: () => import('Components/00-generated/Usp24Svg.vue' /* webpackChunkName: "Usp24Svg" */),
    UspPercentageSvg: () => import('Components/00-generated/UspPercentageSvg.vue' /* webpackChunkName: "UspPercentageSvg" */),
    UspReturnSvg: () => import('Components/00-generated/UspReturnSvg.vue' /* webpackChunkName: "UspReturnSvg" */),
    VisaSvg: () => import('Components/00-generated/VisaSvg.vue' /* webpackChunkName: "VisaSvg" */),
    VisibilitySvg: () => import('Components/00-generated/VisibilitySvg.vue' /* webpackChunkName: "VisibilitySvg" */),
    VisibilityOffSvg: () => import('Components/00-generated/VisibilityOffSvg.vue' /* webpackChunkName: "VisibilityOffSvg" */),
    WarningSvg: () => import('Components/00-generated/WarningSvg.vue' /* webpackChunkName: "WarningSvg" */),
    WetGripClassSvg: () => import('Components/00-generated/WetGripClassSvg.vue' /* webpackChunkName: "WetGripClassSvg" */),
    WhatsappSvg: () => import('Components/00-generated/WhatsappSvg.vue' /* webpackChunkName: "WhatsappSvg" */),
    WheelDriveSvg: () => import('Components/00-generated/WheelDriveSvg.vue' /* webpackChunkName: "WheelDriveSvg" */),
    WindscreenWindowCleaningSystemSvg: () => import('Components/00-generated/WindscreenWindowCleaningSystemSvg.vue' /* webpackChunkName: "WindscreenWindowCleaningSystemSvg" */),
    WishlistSvg: () => import('Components/00-generated/WishlistSvg.vue' /* webpackChunkName: "WishlistSvg" */),
    WishlistCheckedSvg: () => import('Components/00-generated/WishlistCheckedSvg.vue' /* webpackChunkName: "WishlistCheckedSvg" */),
    WishlistFilledHeartSvg: () => import('Components/00-generated/WishlistFilledHeartSvg.vue' /* webpackChunkName: "WishlistFilledHeartSvg" */),
    WishlistHeartSvg: () => import('Components/00-generated/WishlistHeartSvg.vue' /* webpackChunkName: "WishlistHeartSvg" */),
    WorkshopRequestSvg: () => import('Components/00-generated/WorkshopRequestSvg.vue' /* webpackChunkName: "WorkshopRequestSvg" */),
    XingSvg: () => import('Components/00-generated/XingSvg.vue' /* webpackChunkName: "XingSvg" */),
    XingDeshopSvg: () => import('Components/00-generated/XingDeshopSvg.vue' /* webpackChunkName: "XingDeshopSvg" */),
    YellowWarningSvg: () => import('Components/00-generated/YellowWarningSvg.vue' /* webpackChunkName: "YellowWarningSvg" */),
    YoutubeSvg: () => import('Components/00-generated/YoutubeSvg.vue' /* webpackChunkName: "YoutubeSvg" */),
    YoutubeDeshopSvg: () => import('Components/00-generated/YoutubeDeshopSvg.vue' /* webpackChunkName: "YoutubeDeshopSvg" */)
  },
  props: {
    name: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: true
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentName() {
      return upperFirst(camelCase(this.name)) + 'Svg';
    },
    iconPath() {
      return this.resolveFile('icons/' + this.name + '.svg');
    },
  }
};
</script>
