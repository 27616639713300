<i18n src="./translations"></i18n>

<template>
  <div>
    <div
      ref="filterRow"
      :class="[classes, 'qa-catalog-category--filter']"
      :data-preload="[$options.name]"
    >
      <div :key="'brand_select'" class="section grid">
        <div class="brand-row">
          <BrandFilterContainer
            v-if="brandsAvailable"
            :key="'brand'"
            :name="allFilters['brand'].name"
            :title="allFilters['brand'].title"
            :options="allFilters['brand'].options"
            :value="activeFilters['brand']"
            class="section__filter-row"
            @input="(value) => handleSetFilter('brand', value)"
          />
        </div>
      </div>
      <div
        :class="[
          'section grid dropdown-filters',
          {
            'display-top':
              !hasPrimaryFilters &&
              !hasActiveFiltersBesidesBrandAndSorting &&
              showCount === 0,
          },
        ]"
      >
        <template v-for="section in computedVisibleSections">
          <template v-for="filter in getSectionFilters(section)">
            <Dropdown
              v-if="filter !== 'sorting' && filter !== 'brand'"
              :key="filter"
              :form="{
                vars: {
                  label: allFilters[filter].title,
                  innerLabel: allFilters[filter].title,
                  full_name: allFilters[filter].name,
                  type: 'choice',
                  required: true,
                  choices: filterChoices(filter),
                  value: filterSelected(filter),
                },
              }"
              class="section__filter-row item filter-dropdown"
              @input="(value) => handleSetFilter(filter, [value])"
            />
          </template>
        </template>
        <div
          v-if="
            shouldShowMoreLessButtons(true) ||
            shouldShowMoreLessButtons(false) ||
            shouldShowHideAllButton()
          "
          class="showMore-button"
          :class="[{ 'no-margin': noMargin && showCount > 0 }]"
        >
          <Button
            v-show="shouldShowMoreLessButtons(true)"
            class="handle-filters show-more"
            :icon-width="13"
            :icon-height="13"
            :has-focus-border="false"
            :title="
              isMobile
                ? $t('catalog-category-filters.show-4-more')
                : $t('catalog-category-filters.show-more')
            "
            :on-click="showMore"
          />
          <Button
            v-show="shouldShowMoreLessButtons(false)"
            class="handle-filters show-less"
            :icon-width="13"
            :icon-height="13"
            :has-focus-border="false"
            :title="$t('catalog-category-filters.show-less')"
            :on-click="showLess"
          />
          <Button
            v-show="shouldShowHideAllButton()"
            class="handle-filters hide-all"
            :icon-width="13"
            :icon-height="13"
            :has-focus-border="false"
            :title="$t('catalog-category-filters.show-top-filters')"
            :on-click="hideAll"
          />
        </div>
      </div>
      <div class="catalog-category-filters__bottom-container">
        <div class="catalog-category-filters__availability-wrapper">
          <CheckboxBase
            v-if="shouldShowAvailabilityFilter"
            class="catalog-category-filters__availability"
            :label="$t('catalog-category-filters.availability-instant')"
            :modifiers="['theme-spacing']"
            :value="
              availabilityFilterClicked
                ? !instantAvailableActiveFilter
                : instantAvailableActiveFilter
            "
            :hide-optional="true"
            @input="(value) => handleSetFilter('availability', value)"
          />
          <span
            v-if="isPromiseBannerVisible && !isBusinessCustomer"
            class="catalog-category-filters__shipment-promise"
          >
            {{ $t('catalog-category-filters.shipment-promise') }}
          </span>
        </div>
        <Button
          v-show="shouldShowRemoveAllFilterButton()"
          icon="delete"
          :class="[
            'remove-filters item',
            { 'no-margin': noMargin && showCount > 0 },
          ]"
          :icon-width="13"
          :icon-height="13"
          :has-focus-border="false"
          :title="$t('catalog-category-filters.remove-all-filters')"
          :on-click="removeFilters"
        />
      </div>

      <div class="filters-bottom-section">
        <div v-if="!isEmpty(brandActiveFilters)" class="activeSection">
          <span class="section__header titleText">
            {{ $t('catalog-category-filters.only-parts-of') }}:
          </span>
          <ActiveBrandFilter
            :active-filters="brandActiveFilters"
            :filters="allFilters"
            @click="handleRemoveFilter"
          />
        </div>
        <div class="section__filter-row sorting item">
          <SortingCategoriesDropdown
            :sorting="allFilters.sorting"
            :active-sorting="activeSorting"
            @input="(value) => handleSetFilter('sorting', value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortingCategoriesDropdown from 'Components/02-molecules/sorting-categories-dropdown/SortingCategoriesDropdown';
import globalMixin from 'Libs/mixins/globalMixin';
import ActiveBrandFilter from 'Components/02-molecules/active-brand-filter/ActiveBrandFilter.vue';
import BrandFilterContainer from 'Components/03-organisms/brand-filter-container/BrandFilterContainer.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import CheckboxBase from 'Components/01-atoms/form/checkbox/CheckboxBase';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';
import { mapState, mapGetters } from 'vuex';
import Dropdown from 'Components/01-atoms/form/dropdown/Dropdown.vue';

const [rimSize, tyreWidth] = ['rim_size_in_75', 'tyre_width_1428'];

export default {
  name: 'CatalogCategoryFilters',
  components: {
    SortingCategoriesDropdown,
    Button,
    ActiveBrandFilter,
    BrandFilterContainer,
    CheckboxBase,
    Dropdown,
  },
  mixins: [globalMixin],
  props: {
    filterSections: {
      type: Object,
      default: () => ({}),
    },
    allFilters: {
      type: Object,
      default: () => ({}),
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
    activeSorting: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showCount: 0,
      availabilityFilterClicked: false,
    };
  },
  computed: {
    ...mapState('core', {
      isMobile: ({ viewport }) => viewport.range !== 'desktop',
      isPromiseBannerVisible: ({ promiseBanner }) => promiseBanner?.visible,
    }),
    ...mapGetters('core', {
      isBusinessCustomer: 'isBusinessCustomer',
    }),
    ...mapState('plpFilters', {
      shouldShowAvailabilityFilter: function({ availability }) {
        const instantPosition = availability?.options?.findIndex(
          (f) => f.alias === 'instant'
        );
        if (instantPosition === undefined || instantPosition === -1) {
          return false;
        }
        return true;
      },
    }),
    brandsAvailable() {
      return this.allFilters && this.allFilters.brand;
    },
    computedVisibleSections() {
      const filterSections = sortBy(Object.keys(this.filterSections));
      return filterSections;
    },
    hasActiveFiltersBesidesBrandAndSorting() {
      for (let filterName in this.activeFilters) {
        if (filterName !== 'brand' && filterName !== 'sorting') {
          return true;
        }
      }
      return false;
    },
    brandActiveFilters() {
      if ('brand' in this.activeFilters) {
        return { brand: this.activeFilters.brand };
      }
      return {};
    },
    hasPrimaryFilters() {
      return (
        Object.values(this.allFilters).filter(
          (f) => f.category === 'PRIMARY' && f.alias !== 'brand'
        ).length > 0
      );
    },
    noMargin() {
      return (
        Object.values(this.allFilters).filter(
          (f) => f.alias !== 'brand' && f.alias !== 'sorting'
        ).length %
          4 ===
        0
      );
    },
    instantAvailableActiveFilter() {
      if (!('availability' in this.activeFilters)) {
        return false;
      }
      const filter = this.activeFilters.availability;
      if (!Array.isArray(filter)) {
        return false;
      }
      if (filter.length === 1 && filter[0] === 'instant') {
        return true;
      }
      return false;
    },
  },
  methods: {
    secondaryActiveCount(filters) {
      let part = partition(filters, (filter) => {
        return filter in this.activeFilters;
      });

      if (part === 1) {
        return 0;
      }

      return part[0].length;
    },
    shouldShowHideAllButton() {
      if (!this.isMobile) {
        return false;
      }
      if (this.showCount < this.countSecondary()) {
        return false;
      }
      return true;
    },
    shouldShowRemoveAllFilterButton() {
      return !isEmpty(this.activeFilters);
    },
    countSecondary() {
      let count = 0;
      for (let k in this.allFilters) {
        if (this.allFilters[k].category === 'SECONDARY') {
          count++;
        }
      }
      return count;
    },
    shouldShowMoreLessButtons(more) {
      let count = this.countSecondary();

      if (more) {
        return this.showCount >= count ? false : true;
      }
      return this.showCount === 0 ? false : true;
    },
    hideAll() {
      this.showCount = 0;
    },
    getSectionFilters(section) {
      const filters = this.filterSections[section];

      if (section === 'SECONDARY') {
        let part = partition(filters, (filter) => {
          return filter in this.activeFilters;
        });

        if (part.length === 1) {
          return filters.slice(0, this.showCount);
        }
        return [...part[0], ...part[1]].slice(
          0,
          this.secondaryActiveCount(filters) + this.showCount
        );
      }

      // Swap rim_size_in_75 and tyre_width_1428
      if (
        section === 'PRIMARY' &&
        filters.includes(rimSize) &&
        filters.includes(tyreWidth)
      ) {
        const tyreWidthIndex = filters.indexOf(tyreWidth);

        if (tyreWidthIndex !== 0) {
          [filters[0], filters[tyreWidthIndex]] = [
            filters[tyreWidthIndex],
            filters[0],
          ];
        }
      }

      return filters;
    },
    showMore() {
      if (this.isMobile) {
        this.showCount += 4;
      } else {
        this.showCount = size(this.allFilters);
      }
    },
    showLess() {
      if (this.isMobile) {
        this.showCount = Math.max(0, this.showCount - 4);
      } else {
        this.showCount = 0;
      }
    },
    removeFilters() {
      this.$emit('applyFilters', {}, this.activeSorting);
    },
    handleSetFilter(selectedFilterName, value) {
      if (selectedFilterName === 'sorting') {
        this.$emit('applyFilters', this.activeFilters, value);
        return;
      }

      if (selectedFilterName === 'availability') {
        this.availabilityFilterClicked = true;
        if (value) {
          this.$emit(
            'applyFilters',
            {
              ...this.activeFilters,
              availability: ['instant'],
            },
            this.activeSorting
          );
        } else {
          this.handleRemoveFilter({
            filterAlias: 'availability',
            optionAlias: 'instant',
          });
        }
        return;
      }

      this.$emit(
        'applyFilters',
        {
          ...this.activeFilters,
          [selectedFilterName]: value,
        },
        this.activeSorting
      );
    },
    handleRemoveFilter({ filterAlias, optionAlias }) {
      if (!this.activeFilters[filterAlias]) {
        return;
      }

      const newValue = this.activeFilters[filterAlias].filter(
        (v) => v !== optionAlias
      );

      this.$emit(
        'applyFilters',
        {
          ...this.activeFilters,
          [filterAlias]: newValue,
        },
        this.activeSorting
      );
    },
    isEmpty: isEmpty,
    filterChoices(filter) {
      if (!filter || !this.allFilters[filter]) return {};
      const options = this.allFilters[filter]?.options;

      return Object.values(options).map((option) => ({
        title: option?.title,
        value: option?.alias,
        label: `${option?.alias} ${option?.count ? `(${option?.count})` : ''} `,
        selected: options.isActive,
      }));
    },
    filterSelected(filter) {
      if (!filter || !this.allFilters[filter]) return null;
      const options = this.allFilters[filter]?.options;

      return Object.values(options).find((opt) => opt.isActive)?.alias || null;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

.catalogCategoryFilters {
  background-color: var(--color-wild-sandish);
  padding-top: var(--space-1--half);

  @media #{$_mediaMDown} {
    padding-top: 10px;
  }
}

.catalog-category-filters__bottom-container {
  display: flex;
  position: relative;
}

.catalog-category-filters__availability-wrapper {
  position: relative;
  margin-left: var(--space-2);
  padding-bottom: var(--space-1--half);
  width: 100%;

  @media #{$_mediaMDown} {
    margin-left: var(--space-2--half);
  }

  .catalog-category-filters__availability.checkbox {
    margin-bottom: var(--space-M);
  }

  ::v-deep {
    .checkbox--theme-spacing.checkbox .checkmark {
      margin-right: var(--space-S);
    }

    .checkbox--theme-spacing.checkbox .label__value {
      font-size: var(--font-size-SM);
      color: var(--color-availability-green);

      @media #{$_mediaMDown} {
        font-size: var(--font-size-S);
      }
    }

    input.checkboxEl {
      height: 30px;
      width: 130px;
      margin-left: -2px;
      top: -2px;
      position: absolute;
      z-index: 9999;
    }
  }

  .catalog-category-filters__shipment-promise {
    position: absolute;
    left: var(--space-3--half);
    top: 18px;
    font-size: var(--font-size-S);
    color: var(--color-black);
  }
}

.brand-row {
  width: 100%;
}

.dropdown-filters {
  .no-margin.showMore-button {
    margin-top: -13px;

    @media #{$_mediaMDown} {
      margin-top: -20px;
    }
  }
}

.grid {
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  .itemsContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 8px;
    background: var(--color-wild-sandish);
  }

  .showMore-button {
    display: inline-block;
    max-width: 145px;
    text-align: left;
    margin-bottom: 10px;
    margin-left: var(--space-XXS);
    align-self: end;

    ::v-deep {
      .inner {
        align-items: left;
        justify-content: left;

        @media #{$_mediaMDown} {
          align-items: right;
          justify-content: right;
        }
      }
    }

    @media #{$_mediaMDown} {
      justify-content: space-between;
      width: 100%;
      max-width: none;
      margin-top: 0;
    }
  }

  .hide-all {
    @media #{$_mediaMDown} {
      display: none;
    }

    @media #{$_mediaLDown} {
      display: none;
    }
  }

  .show-less {
    @media #{$_mediaMDown} {
      margin-left: auto;
    }
  }

  .show-less {
    @media #{$_mediaMDown} {
      margin-left: auto;

      ::v-deep {
        .inner span.title {
          align-self: flex-end;
          display: flex;
        }
      }
    }
  }

  .handle-filters {
    font-size: var(--font-size-SM);
    color: var(--color-nero);
    text-transform: initial;
    text-decoration: underline;
    font-weight: normal;

    @media #{$_mediaMDown} {
      width: 100%;
      max-width: min-content;
      font-size: var(--font-size-S);
      padding: var(--space-1--half) var(--space-7--half) 0 0;
    }
  }

  .item {
    flex: 1 1 100%;
    max-width: 210px;
    margin-bottom: 15px;
    margin-top: 18px;
    margin-left: var(--space-1--half);
  }

  button {
    width: 100%;
    max-height: 40px;
    margin: 0;
  }

  &:last-child {
    margin-bottom: var(--space-0--half);
  }
}

.button.remove-filters {
  font-weight: normal;
  font-size: var(--font-size-SM);
  color: var(--color-nero);
  text-transform: initial;
  max-width: 170px;
  margin-left: auto;
  margin-bottom: 5px;
  top: -12px;

  @media #{$_mediaMDown} {
    max-width: 100%;
    padding-top: 5px;
    font-size: var(--font-size-S);
    margin-bottom: var(--space-1--half);
  }

  &.no-margin {
    margin-top: -19px;
  }

  ::v-deep {
    .inner {
      padding-left: 9px;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .icon + .title {
      padding-top: var(--space-XXS);
      margin-left: 6px;
      margin-top: var(--space-XXS);

      @media #{$_mediaMDown} {
        margin-top: 0;
      }
    }
  }
}

.sorting {
  width: fit-content;
  margin-left: auto;
  margin-top: 7px;
  position: relative;

  @media #{$_mediaMDown} {
    height: 38px;
    position: absolute;
    right: var(--space-1--half);
    background: white;
  }

  @media (max-width: $_widthSTo) {
    margin-top: 0;
    height: 35px;
    top: 1px;
  }
}

.filters-bottom-section {
  display: flex;
  background: white;
  margin-top: -8px;
  position: relative;
  min-height: 55px;

  @media #{$_mediaSDown} {
    min-height: 26px;
  }
}

.section__header {
  color: var(--color-nero);
  vertical-align: middle;
  font-size: var(--font-size-M);
  line-height: 17px;
  margin-top: 13px;
  margin-right: var(--space-1);
  max-width: 200px;
  width: 100px;

  @media #{$_mediaMDown} {
    font-size: var(--font-size-SM);
  }
}

.activeSection {
  margin-left: var(--space-1--half);
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  & > div {
    margin-top: var(--space-1);
    flex-wrap: wrap;
    max-width: 555px;

    @media #{$_mediaMDown} {
      margin-top: 13px;
      flex-wrap: nowrap;
    }
  }

  @media #{$_mediaMUp} {
    margin-bottom: 10px;
  }
}

@media #{$_mediaMDown} {
  .activeSection {
    display: block;
    background: white;
    padding-top: 3px;
    padding-left: var(--space-1--half);
    margin-left: 0;
    margin-top: 0;
  }
}

button.handle-filters ::v-deep .icon path,
button.remove-filters ::v-deep .icon path {
  fill: var(--color-primary);
}

.filter-dropdown {
  ::v-deep {
    .buttonText {
      font-size: var(--font-size-SM);
      padding: var(--space-1--half) var(--space-2);
    }

    .button {
      height: 44px;
    }
  }
}
</style>
