<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <span v-if="headline" class="pdp-information-21__headline">
      {{ headline }}
    </span>

    <div v-if="items.length !== 0" class="pdp-information-21__wrapper">
      <div
        v-if="modifiersArray.includes('slim')"
        class="pdp-information-21__list"
      >
        <span v-for="(item, index) in items.slice(0, limit)" :key="item.label">
          {{ getItemLabelAndInfo(items.slice(0, limit), index) }}
        </span>
      </div>
      <table v-else class="pdp-information-21__table">
        <tbody>
          <tr
            v-for="item in items.slice(0, limit)"
            :key="item.label"
            :class="[
              'pdp-information-21__row',
              `pdp-information-21__row--${item.modifier}`,
            ]"
          >
            <th class="pdp-information-21__cell pdp-information-21__label">
              {{ item.label }}
            </th>
            <td class="pdp-information-21__cell">
              {{ item.info }}
            </td>
          </tr>
        </tbody>

        <tbody v-if="items.length > limit">
          <tr class="pdp-information-21__row">
            <td colspan="2">
              <div class="pdp-information-21__trigger" @click="showAll()">
                <strong class="pdp-information-21__trigger-text">
                  {{ $t('pdp-information-21.additional_attributes') }}
                </strong>
                <ChevronRightSvg class="pdp-information__show-attributes" />
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-if="!hideOnToggle" class="pdp-information-21__row">
            <td colspan="2">
              <div class="pdp-information-21__trigger" @click="hideDetails()">
                <strong class="pdp-information-21__trigger-text">
                  {{ $t('pdp-information-21.less_attributes') }}
                </strong>
                <ChevronRightSvg class="pdp-information__hide-attributes" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <HazardousGood21
      v-if="hazardousGoodInfo && isHazardousGood && hasHazardousGoodEnabled"
      :information="product.hazardousGoodInformation"
    />

    <div v-if="hasCarTypes || hasOeNumbers">
      <div
        v-if="hasOeNumbers"
        class="pdp-information-21__oe-numbers qa-ProductCard__showOeNumbers"
        @click="showDialogHandler('oeNumbersDialog')"
      >
        {{ $t('pdp-information-21.parts_dialog_trigger') }}
        <ArrowOpenModalSvg class="pdp-information-21__arrow" />
      </div>

      <div
        v-if="hasCarTypes"
        class="pdp-information-21__car-types qa-ProductCard__showCarTypes"
        @click="showDialogHandler('carTypesDialog')"
      >
        {{ $t('pdp-information-21.suitable_cars') }}
        <ArrowOpenModalSvg class="pdp-information-21__arrow" />
      </div>
    </div>

    <Dialog
      v-if="hasOeNumbers"
      ref="oeNumbersDialog"
      class="pdp-information-21__oe-numbers-container"
      :click-outside="true"
      :title="$t('pdp-information-21.oe_numbers')"
      :use-frame-padding="false"
    >
      <Table
        v-if="!oeNumbers.length"
        :is-ajax="true"
        :url="tablesURLs(frontFacingPath('search.ajax.product.oe_numbers'))"
        :load-more="true"
        :load-more-label="$t('pdp-information-21.more_oe_numbers')"
        :intial-rows-limit="4"
        table-key="oeNumbers"
        :has-border="false"
        @tableIsEmpty="tableIsEmptyHandler"
      >
        <template #oeNumber="{ entity }">
          <MaskedLink
            :text="entity.oeNumber"
            :redirect-to="oeSearchUrl(entity.oeNumber)"
            class="pdp-information-21__link"
          />
        </template>
      </Table>

      <Table
        v-else
        :entities="product.oeNumbers"
        :columns="oeNumbersColumns"
        table-key="oeNumber"
        :has-border="false"
        @tableIsEmpty="tableIsEmptyHandler"
      >
        <template #oeNumber="{ entity }">
          <MaskedLink
            :text="entity.oeNumber"
            :redirect-to="oeSearchUrl(entity.oeNumber)"
            class="pdp-information-21__link"
          />
        </template>
      </Table>
    </Dialog>

    <Dialog
      v-if="hasCarTypes"
      ref="carTypesDialog"
      class="pdp-information-21__car-types-container"
      :click-outside="true"
      :title="$t('pdp-information-21.suitable_cars')"
      :use-frame-padding="false"
    >
      <Table
        :is-ajax="true"
        :url="tablesURLs(frontFacingPath('search.ajax.product.car.data'))"
        :load-more="true"
        :load-more-label="$t('pdp-information-21.more_car_types')"
        :intial-rows-limit="4"
        table-key="carTypes"
        :has-border="false"
        @tableIsEmpty="tableIsEmptyHandler"
      />
    </Dialog>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState, mapMutations } from 'vuex';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import ArrowOpenModalSvg from 'Components/00-generated/ArrowOpenModalSvg';
import Dialog from 'Components/02-molecules/dialog/Dialog';
import Table from 'Components/01-atoms/table/Table';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import HazardousGood21 from 'Components/02-molecules/hazardous-good-21/HazardousGood21.vue';
import get from 'lodash/get';

export default {
  name: 'PdpInformation21',
  components: {
    Dialog,
    Table,
    MaskedLink,
    ChevronRightSvg,
    ArrowOpenModalSvg,
    HazardousGood21,
  },
  mixins: [globalMixin],
  props: {
    highlight: {
      type: Array,
      default: () => [],
    },
    regular: {
      type: Array,
      default: () => [],
    },
    defaultLimit: {
      type: Number,
      default: 7,
    },
    headline: {
      type: String,
      default: null,
    },
    productId: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      required: true,
    },
    hasCarTypes: {
      type: Boolean,
      default: false,
    },
    hasOeNumbers: {
      type: Boolean,
      default: false,
    },
    hasHazardousGoodEnabled: {
      type: Boolean,
      default: false,
    },
    oeNumbers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const itemCount = this.highlight.length + this.regular.length;

    return {
      limit: itemCount >= this.defaultLimit ? this.defaultLimit : itemCount,
      hideOnToggle: true,
      oeNumbersColumns: [
        {
          key: 'manufacturer',
          label: this.$t('pdp-information-21.oe-numbers-table.manufacturer'),
        },
        {
          key: 'oeNumber',
          label: this.$t('pdp-information-21.oe-numbers-table.oeNumber'),
        },
      ],
    };
  },
  computed: {
    ...mapState('core', {
      hazardousGoodInfo: (state) =>
        get(state, 'salesChannel.config.hazardousGoodInfo', false),
    }),
    items() {
      return [
        ...this.highlight.map((item) => ({ ...item, modifier: 'highlight' })),
        ...this.regular.map((item) => ({ ...item, modifier: 'regular' })),
      ];
    },
    isHazardousGood() {
      return this.product?.isHazardousGood || false;
    },
  },
  mounted() {
    this.hideOnToggle = this.limit === this.items.length;
  },
  methods: {
    ...mapMutations('core', ['setViewport']),
    showAll() {
      this.limit = this.items.length;
    },
    getItemLabelAndInfo(items, index) {
      let item = items[index];
      let output = `${item.label}: ${item.info}${
        index !== items.length - 1 ? ',' : ''
      }`;
      return output;
    },
    oeSearchUrl(oeNumber) {
      return `${this.frontFacingPath(
        'search.search_bar.search'
      )}?search=${oeNumber.replace(/\s/g, '+')}#oens`;
    },
    hideDetails() {
      const itemCount = this.highlight.length + this.regular.length;
      this.limit =
        itemCount >= this.defaultLimit ? this.defaultLimit : itemCount;
    },
    tablesURLs(url) {
      return url.replace('{productId}', this.productId);
    },
    tableIsEmptyHandler(table) {
      if (table === 'oeNumbers') {
        this.showOENumbers = false;
      } else {
        this.showCarTypes = false;
      }
    },
    showDialogHandler(refName) {
      this.$refs[refName].show();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.pdpInformation21 {
  width: 100%;

  .pdp-information-21__oe-numbers,
  .pdp-information-21__car-types {
    display: block;
    color: var(--color-nero);
    font-size: var(--font-size-SM);
    line-height: 1.4em;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 5px;
  }

  .pdp-information-21__oe-numbers {
    margin-top: -8px;

    @media #{$_mediaLDown} {
      margin-bottom: 7px;
    }
  }

  .pdp-information-21__car-types {
    margin-bottom: 24px;
  }

  @media #{$_mediaLDown} {
    .isDesktop {
      display: none;
    }
  }

  @media #{$_mediaXLUp} {
    .isMobile {
      display: none;
    }
  }
}

.pdp-information-21__arrow {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin-left: 8px;
  top: var(--space-0--half);
  position: relative;
  color: var(--color-secondary);
  fill: var(--color-secondary);
}

.pdp-information-21__headline {
  padding: 0 var(--space-1--half) var(--space-1--half) var(--space-XXS);
  width: 100%;
  font-weight: bold;
  font-size: var(--font-size-M);
  line-height: 17px;
  color: var(--color-nero);
  display: inline-block;
  border-bottom: 1px solid var(--color-silver-lining);
}

.pdp-information-21__headline + .pdp-information-21__wrapper {
  border-bottom: 1px solid var(--color-silver-lining);
  margin-bottom: var(--space-2);
  margin-top: 7px;
  padding: 0 var(--space-2) 7px var(--space-2);
}

.pdp-information-21__table {
  width: 100%;
  text-align: left;
}

.dialog .pdp-information-21__table {
  margin: 0;
}

.pdp-information-21__cell {
  padding: 6px 0;
  font-size: var(--font-size-M);
  line-height: 1.2em;
  width: 50%;
  text-align: left;
  padding-right: 7px;
  color: var(--color-nero);
  word-break: break-all;

  + .pdp-information-21__cell {
    padding-right: 0;
    padding-left: 35px;
    word-break: break-word;
  }
}

.pdp-information-21__row--highlight {
  background: var(--color-wild-sand);

  .pdp-information-21__label {
    font-weight: bold;
  }

  & + & {
    .pdp-information-21__cell {
      padding-top: 0;
    }
  }
}

.pdp-information-21__trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 6px 0 7px;
  padding: 0;
}

.pdp-information__show-attributes,
.pdp-information__hide-attributes {
  color: var(--color-secondary);
  transform: rotate(90deg);
  width: 22px;
  height: 18px;
  margin-top: var(--space-XXS);
}

.pdp-information__hide-attributes {
  transform: rotate(270deg);
}

.pdp-information-21__trigger-text {
  color: var(--color-nero);
  font-size: var(--font-size-SM);
  text-decoration: underline;
}

.rotateArrow {
  transform: rotate(-180deg);
}

@media (min-width: $_widthSTo) {
  .pdp-information-21__headline {
    line-break: 20px;
  }

  .pdp-information-21__cell {
    font-size: 16px;
    line-height: 18px;
  }

  .pdp-information-21__row--highlight {
    & + & {
      .pdp-information-21__cell {
        padding-top: 0;
      }
    }
  }
}

@media #{$_mediaMDown} {
  .pdp-information-21__car-types-container,
  .pdp-information-21__oe-numbers-container {
    display: none;
  }
}
</style>
