import AjaxService from 'Services/AjaxService';
import qs from 'qs';
import { getProductCategoryLevelTwo } from '../data-layer/actions';
import { toCamelCase } from 'Libs/helpers/key-case-converter';

const WISHLIST_ADD_ENDPOINT_KEY = 'checkout.ajax.cart.wishlist.add';

export const updateQuantity = async ({ commit, state, rootState }, { product, amount }) => {
  const updatedProduct = state.data.products.find((obj) => obj.productId === product.productId);
  const url = rootState.core.fetchedUrls['checkout.ajax.cart.amount.modify'];
  const payload = {
    productId: product.productId,
    amount: amount || 0,
    carTypeNumber: updatedProduct.carTypeNumber,
    sku: updatedProduct.sku,
    cartId: state.data.cartId,
    miniCart: true,
  };

  if (product.listOfOrigin) {
    payload.list = product.listOfOrigin;
  }

  const request = await AjaxService.post(url, qs.stringify(payload), {
    hideSuccess: true,
  });

  if (request?.data && request.data.cart) {
    commit('MINICART_UPDATE', toCamelCase(request.data.cart));
  }
};

export const addToCart = async ({ commit, dispatch, rootState }, data) => {
  const url = rootState.core.fetchedUrls['checkout.ajax.cart.add'];

  const payload = {
    sku: data.product.sku,
    carTypeNumber: data.product.carTypeNumber || '',
    quantity: data.quantity,
    productId: data.product.productId,
    list: data.list ? data.list : getProductCategoryLevelTwo(data.product.sku),
    miniCart: true
  };

  const response = await AjaxService.post(url, qs.stringify(payload), {
    hideSuccess: false,
  });

  if (response?.data && response.data.cart) {
    commit('MINICART_UPDATE', response.data.cart);
    commit('header/SET_LATEST_ADDED_PRODUCT_ID', data.product.productId, { root: true });

    setTimeout(() => {
      dispatch(
        'header/showLatestAddedNotification',
        '',
        { root: true }
      );
    }, 500);
  }
};

export const addToWishlist = async (
  { commit, state, rootState },
  { product, miniCart = true }
) => {
  const { fetchedUrls } = rootState.core;
  const payload = {
    productId: product.productId,
    amount: !product.inWishlist ? product.quantityAmount : 0,
    carTypeNumber: product.carTypeNumber,
    sku: product.sku,
    cartId: state.data.cartId,
    miniCart: miniCart,
  };

  const request = await AjaxService.post(
    fetchedUrls[WISHLIST_ADD_ENDPOINT_KEY],
    qs.stringify(payload)
  );

  if (request?.data && request.data.cart) {
    if (miniCart) {
      commit('MINICART_UPDATE', request.data.cart);
    } else {
      /**
       * [andrei] Because the request is made with the 'false' value for 'miniCart' property,
       * BE returns the products structured as sections.
       * We do this because we want reactivity in the wishlist page when the user
       * adds a product to wishlist from minicart
       */
      commit('SET_PRODUCT_IN_WISHLIST', product);
      // Filter out products not in wishlist
      const updatedSections = request.data.cart.sections.map(section => ({
        ...section,
        products: section.products.filter(p => p.inWishlist),
      }));

      commit('wishlist/SET_SECTIONS', updatedSections, { root: true });
    }

    commit('wishlist/SET_PRODUCT_COUNT', request.data.cart.wishListItemCount, {
      root: true,
    });
  }
};
