import { capitalize } from 'lodash';

function redefineDefaults(state, addressData) {
  if (addressData.defaultBillingAddress) {
    state.data.address.addresses.billing.forEach((address) => {
      if (address.addressId !== addressData.addressId) {
        address.defaultBillingAddress = false;
      }
    });
  }

  if (addressData.defaultShippingAddress) {
    state.data.address.addresses.shipping.forEach((address) => {
      if (address.addressId !== addressData.addressId) {
        address.defaultShippingAddress = false;
      }
    });
  }
}

export default {
  CREATE_CUSTOMER_ADDRESS(state, { addressData }) {
    if (!state.data.address.addresses.shipping) {
      state.data.address.addresses.shipping = [];
    }

    if (!state.data.address.addresses.billing) {
      state.data.address.addresses.billing = [];
    }

    redefineDefaults(state, addressData);

    for (const addressType in state.data.address.addresses) {
      // [andrei] Update the state this way in order to trigger the getters
      state.data.address.addresses[addressType] = [...state.data.address.addresses[addressType], addressData];
    }
  },

  UPDATE_CUSTOMER_ADDRESS(state, { addressData }) {
    // We need to update the addresses in both shipping and billing lists.
    for (const addressType in state.data.address.addresses) {
      const index = state.data.address.addresses[addressType].findIndex(address => address.addressId === addressData.addressId);
      if (index >= 0) {
        // [andrei] Use splice in order to trigger reactivity
        state.data.address.addresses[addressType].splice(index, 1, {
          ...state.data.address.addresses[addressType][index],
          ...addressData,
        });
      } else {
        console.error('Address does not exist !');
      }
    }
  },

  DELETE_CUSTOMER_ADDRESS(state, { addressId }) {
    if (state.data?.address?.addresses) {
      for (const addressType in state.data.address.addresses) {
        state.data.address.addresses[addressType] = state.data.address.addresses[addressType].filter((address) => address.addressId !== addressId);

        /**
         * [andrei] Update the selected address after the deletion is made
         */
        if (state.data[`selected${capitalize(addressType)}Address`].addressId === addressId) {
          state.data[`selected${capitalize(addressType)}Address`] =
            state.data.address.addresses[addressType].length > 0 ? state.data.address.addresses[addressType][0] : {}
        }
      }
    }
  },

  UPDATE_SHIPPING_ZIP_CODE_ALLOWED(state, { shippingType }) {
    if (
      state.data &&
      state.data.shipping &&
      state.data.shipping.availableShippingTypes
    ) {
      state.data.shipping.availableShippingTypes.forEach((type, index) => {
        if (type.shippingType === shippingType.shippingType) {
          state.data.shipping.availableShippingTypes[index].zipCodeAllowed = shippingType.zipCodeAllowed;
        }
      });
    } else {
      console.error('Shipping data or availableShippingTypes not found!'); // eslint-disable-line no-console
    }
  },

  UPDATE_CHECKOUT_STEP(state, { step }) {
    state.data.step = step;
  },

  SET_PAYMENT(state, { payment }) {
    state.data.payment = { ...payment };
  },

  SET_ADDRESS(state, address) {
    state.data.address = { ...address };
  },

  UPDATE_SELECTED_BILLING_ADDRESS(state, address) {
    state.data.selectedBillingAddress = { ...address };
  },

  UPDATE_SELECTED_SHIPPING_ADDRESS(state, address) {
    state.data.selectedShippingAddress = { ...address };
  },

  UPDATE_CHECKOUT_CART(state, cart) {
    state.data.cart = { ...cart };
  },

  UPDATE_CHECKOUT_SHIPPING(state, shipping) {
    state.data.shipping = { ...shipping };
  },

  UPDATE_DROPSHIPMENT_ITEMS(state, dropshippingItems) {
    state.data.dropshippingItems = dropshippingItems;
  },

  SET_SUBMIT_COMPUTOP(state, value) {
    state.data.submitComputop = value;
  },

  SET_SELECTED_PAYMENT(state, payment) {
    state.data.selectedPayment = payment;
  },

  SET_IS_VALID_PAYMENT_SELECTION(state, isValid) {
    state.data.isValidPaymentSelection = isValid;
  }
};
