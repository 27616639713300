import { has } from 'lodash';

const getShipping = (state, shippingType) => {
  const availableTypes = state.data.shipping?.availableShippingTypes || [];

  const shipping = availableTypes.find(
    (type) => type.shippingType === shippingType
  );

  if (!shipping) {
    console.log('No matching shipping type found ', shippingType);
  }

  return shipping;
};

export default {
  getAddresses(state) {
    if (has(state, ['data', 'address'])) {
      return state.data.address;
    }
    return { addresses: { billing: [], shipping: [] } };
  },
  getBillingAddresses(state) {
    if (has(state, ['data', 'address', 'addresses', 'billing'])) {
      return state.data.address.addresses.billing;
    }
    return [];
  },
  getShippingAddresses(state) {
    if (has(state, ['data', 'address', 'addresses', 'shipping'])) {
      return state.data.address.addresses.shipping;
    }
    return [];
  },
  getSelectedShippingAddress(state) {
    if (has(state, ['data', 'selectedShippingAddress'])) {
      return state.data.selectedShippingAddress;
    }
    return {};
  },
  getSelectedBillingAddress(state) {
    if (has(state, ['data', 'selectedBillingAddress'])) {
      return state.data.selectedBillingAddress;
    }
    return {};
  },
  getCurrentStep(state) {
    return state.data.step;
  },
  getAvailablePayments(state) {
    return state.data?.payment?.availablePayments || [];
  },
  getSelectedPayment(state) {
    return state.data.selectedPayment;
  },
  // [Mina] Should be removed
  getOriginalSelectedPayment(state) {
    return state.data.payment?.selectedPayment || null;
  },
  getIsValidPaymentSelection(state) {
    return state.data.isValidPaymentSelection;
  },
  getRegularShippingCost(state) {
    const shipping = getShipping(state, 'shipment_regular');
    return shipping ? shipping.shippingCost : 0;
  },
  getExpressShippingCost(state) {
    const shipping = getShipping(state, 'shipment_express');
    return shipping ? shipping.shippingCost : 0;
  },
  getRegularShippingDetails(state) {
    return getShipping(state, 'shipment_regular');
  },
  getExpressShippingDetails(state) {
    return getShipping(state, 'shipment_express');
  },
  getSelectedShippingOption(state) {
    return state.data.shipping?.shippingType;
  },

  isDropShippingItems(state) {
    return state.data?.dropshippingItems || false;
  },

  isZipCodeNotAllowed(state) {
    if (has(state, ['data', 'shipping', 'availableShippingTypes'])) {
      return state.data.shipping.availableShippingTypes.some(
        (item) => item.zipCodeAllowed === false
      );
    }
    return false;
  },

  getRefinedIdealBanksList(state) {
    const idealPaymentMethod = state.data?.payment?.availablePayments.find(obj => obj.name === 'ideal') ?? false;
    if (idealPaymentMethod) {
      let refinedBankList = [];

      Object.keys(idealPaymentMethod.additionalData.idealAvailableBanks).forEach((key) => {
        refinedBankList.push({
          label: idealPaymentMethod.additionalData.idealAvailableBanks[key],
          value: key
        });
      });

      return refinedBankList;
    }
    return [];
  }
};
