import { has } from 'lodash';

export const setWishlistState = ({
  data: headerWishlistData,
  component: headerWishlistComponent,
} = {}) => {
  const defaultData = { count: 0, isWished: false };
  let wishlistData = {};

  if (!headerWishlistData) {
    headerWishlistData = {};
  }

  if (has(headerWishlistData, 'wishlist')) {
    wishlistData = headerWishlistData.wishlist;
  }

  return {
    data: {
      ...headerWishlistData,
      wishlist:
        headerWishlistData && headerWishlistData.wishlist
          ? headerWishlistData.wishlist
          : defaultData,
      isWished:
        headerWishlistData && headerWishlistData.isWished
          ? headerWishlistData.isWished
          : defaultData.isWished,
    },
    component: headerWishlistComponent,
    wishlistId: '',
    productCount: 0,
    ...wishlistData,
  };
};
