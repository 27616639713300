<i18n src="./translations"></i18n>
<template>
  <div
    v-if="
      information.signalWord !== '' ||
      information.icon.length !== 0 ||
      hasHazardStatements
    "
    class="hazard-container"
    :class="classes"
  >
    <div class="statements-label">
      <seo-hide :text="$t('hazardous-good-21.label')" />
    </div>

    <div class="hazard-warning">
      <div class="single-word">
        <seo-hide :text="information.signalWord" />
      </div>

      <div class="icons-container">
        <div
          v-for="(name, index) in information.icon"
          :key="index"
          class="icons"
        >
          <Icon class="icon" :name="upperCamelCase(name)" />
          <p class="icon-title">
            <seo-hide :text="$t(`hazardous-good-21.${name}`)" />
          </p>
        </div>
      </div>
    </div>

    <template v-if="hasHazardStatements">
      <seo-hide
        class="headline"
        :text="`${$t('hazardous-good-21.hazard_warning')}:`"
      />

      <div class="hazard-statements">
        <seo-hide
          v-for="(statement, index) in information.statements.hazard"
          :key="index"
          class="statement"
          :text="`${statement.label} `"
        />
      </div>
    </template>
  </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import Icon from 'Components/00-generated/Icon';
import SeoHide from 'Components/01-atoms/seo-hide/SeoHide';

export default {
  name: 'HazardousGood21',
  components: { Icon, SeoHide },
  props: {
    information: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasHazardStatements() {
      return (
        this.information?.statements &&
        this.information.statements.hazard &&
        this.information.statements.hazard.length > 0
      );
    },
  },
  methods: {
    upperCamelCase: (string) => upperFirst(camelCase(string)),
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

@mixin default-typography {
  font-size: var(--font-size-M) !important;
  line-height: var(--font-size-MLL) !important;
  color: var(--color-nero);
}

.hazard-container {
  margin-bottom: var(--space-1--half);

  .hazard-warning {
    padding: 0;
    display: flex;
    width: 100%;
  }

  .title {
    display: flex;
    flex-direction: column;
    margin-right: var(--space-3);

    .headline {
      padding-bottom: 8px;
      font-weight: bold;
      font-size: var(--font-size-M);
      line-height: 17px;

      @include themeColor(
        color,
        var(--color-tory-blue),
        var(--color-endeavour),
        var(--color-chathamsblue)
      );
    }
  }

  .hazard-criteria {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: -8px -14px 0;
  }

  .icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 0 3px;
    text-align: center;
    width: 71px;

    .icon {
      height: 40px;
      width: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      margin: 0 14px;
      text-align: center;

      ::v-deep path:nth-of-type(1) {
        fill: var(--color-red);
      }
    }

    .icon-title {
      margin-top: 1px;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      width: 72px;
      color: var(--color-mine-shaft);
      word-break: break-word;
      hyphens: auto;
    }
  }

  .hazard-statements {
    width: 100%;
    word-break: break-word;
    font-size: 16px;
    line-height: 22px;
  }

  .statements-label {
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 16px;
  }

  .headline {
    color: var(--color-nero);
    font-weight: bold;
    display: block;

    @include default-typography;
  }

  .single-word {
    @include default-typography;

    font-weight: bold;
    width: 50%;
    margin-top: 10px;
  }

  .icons-container {
    display: flex;
    margin-left: auto;
  }
}
</style>
