import { render, staticRenderFns } from "./DialogFrame.vue?vue&type=template&id=40c01647&scoped=true"
import script from "./DialogFrame.vue?vue&type=script&lang=js"
export * from "./DialogFrame.vue?vue&type=script&lang=js"
import style0 from "./DialogFrame.vue?vue&type=style&index=0&id=40c01647&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c01647",
  null
  
)

export default component.exports