<i18n src="./translations"></i18n>

<template>
  <PdpTabBase
    class="pdp-vehicle-types-tab"
    :headline="$t('pdp-vehicle-types-tab.headline')"
    :class="classes"
    :data-preload="[$options.name]"
    :is-expanded="activeTab"
  >
    <PdpTable
      :lines="lines"
      :columns="columns"
      :load-limit="loadLimit"
      :headline="$t('pdp-vehicle-types-tab.table_headline')"
      :show-all-label="$t('pdp-vehicle-types-tab.show_all_label')"
      :row-modifier="{
        modifier: 'success',
        indices: matchIndices,
      }"
      :is-compact="viewport === 'mobile'"
    >
      <template v-slot:row-modifier-extra>
        <div class="pdp-vehicle-types-tab__row-modifier">
          <Icon
            class="pdp-vehicle-types-tab__r ow-modifier-icon"
            name="green_check"
            width="16"
            height="16"
          />
          <span class="pdp-vehicle-types-tab__row-modifier-label">
            {{ $t('pdp-vehicle-types-tab.match_label') }}
          </span>
        </div>
      </template>
    </PdpTable>
  </PdpTabBase>
</template>

<script>
import PdpTabBase from 'Components/01-atoms/pdp-tab-base/PdpTabBase';
import globalMixin from 'Libs/mixins/globalMixin';
import { axios } from 'Services/AjaxService';
import has from 'lodash/has';
import { mapState } from 'vuex';
import PdpTable from 'Components/01-atoms/pdp-table/PdpTable';
import Icon from 'Components/00-generated/Icon';

const LOAD_LIMIT = 4;

export default {
  name: 'PdpVehicleTypesTab',
  components: { PdpTabBase, PdpTable, Icon },
  mixins: [globalMixin],
  props: {
    productId: {
      type: String,
      required: true,
    },
    hasCarTypes: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    lines: [],
    columns: [],
    loadLimit: LOAD_LIMIT,
    matchIndices: [],
  }),
  computed: {
    ...mapState('core', {
      ktypnr: ({ placeholders }) => {
        const hasVehicle = has(placeholders, 'carSelection.data.vehicle');
        const vehicle = hasVehicle && placeholders.carSelection.data.vehicle;
        return vehicle ? vehicle.ktypnr : null;
      },
      viewport: ({ viewport }) => viewport.range,
    }),
  },
  mounted() {
    if (this.hasCarTypes) {
      this.loadLines(LOAD_LIMIT);
    }
  },
  methods: {
    async loadLines(limit) {
      const carTypeEndpoint = this.frontFacingPath(
        'search.ajax.product.car.data'
      ).replace('{productId}', this.productId);
      const requestConfiguration = { params: { limit } };
      const {
        data: { carTypes: carTypeList },
      } = await axios.get(
        carTypeEndpoint,
        limit ? requestConfiguration : undefined
      );

      if (carTypeList) {
        const { entities, count, columns } = carTypeList;
        let sortedEntities = entities;

        if (this.ktypnr) {
          const [matches, others] = sortedEntities.reduce(
            (accumulator, entity) => {
              accumulator[entity.carId === this.ktypnr ? 0 : 1].push(entity);

              return accumulator;
            },
            [[], []]
          );

          this.matchIndices = [
            ...Object.keys(matches).map((key) => parseInt(key, 10)),
          ];
          sortedEntities = [...matches, ...others];
        }

        this.lines = sortedEntities || [];
        this.columns = this.lines.length ? columns : [];
        this.loadLimit = count >= this.lines.length ? limit : 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

::v-deep {
  .pdp-vehicle-types-tab__row-modifier {
    .pdp-vehicle-types-tab__row-modifier-label {
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
    }
  }

  .pdp-table__table-cell:not(.pdp-table__table-cell--slot-cell) {
    padding-top: var(--space-1--half) !important;
    padding-bottom: var(--space-1--half) !important;
  }
}
</style>
