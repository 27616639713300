<i18n src="./translations"></i18n>

<template>
  <div
    :class="classes"
    :data-preload="[$options.name]"
    @keyup.down="navigateOptions('down')"
    @keyup.up="navigateOptions('up')"
    @keyup.esc="clearCompletionTerms"
  >
    <div
      v-if="isClickOutsideActive"
      class="overlay"
      @click="closeCompletionsElement()"
    ></div>

    <form
      ref="form"
      method="GET"
      :action="searchUrl"
      autocomplete="off"
      class="form"
      @submit.prevent="resolveSearch"
    >
      <div class="wrapper">
        <InputBase
          ref="textInput"
          :modifiers="searchModifiers"
          type="text"
          class="searchInput qa-searchInput"
          full-name="search"
          :send="true"
          :icon1="mobile && isNewHeader"
          :value="inputDisplay"
          :placeholder="$t('search-bar.search_term')"
          :attr="{ clear_button: true }"
          @invalid="handleValidity"
          @focus="searchBarOnFocusHandler"
          @input="searchBarOnChangeHandler"
        >
          <template v-if="mobile && isNewHeader" v-slot:icon1>
            <button @click="handleButtonClick">
              <ChevronLeftSvg class="iconLeft" height="30" width="30" />
            </button>
          </template>
        </InputBase>
        <Button
          :class="[
            'searchButton qa-searchButton',
            { 'searchButton--hidden': mobile && isNewHeader },
          ]"
          :modifiers="['tertiary', 'lowercase', 'slim', 'inputLeft']"
          :title="onlyShowSeachIcon ? '' : $t('search-bar.search')"
          type="button"
          :on-click="handleButtonClick"
          :icon="onlyShowSeachIcon ? icon : ''"
        />
      </div>
    </form>

    <CompletionTerms
      :class="{
        'secondary-theme': isCategorySearchEnabled,
        'is-suggested-term': isCategorySearchEnabled && searchTerm.length !== 0,
      }"
      :items="completionTerms"
      :selection-index="selectionIndex"
      :on-click-handler="optionOnClick"
      :fresh="fresh"
      :search-title="searchTitle"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions, mapState, mapMutations } from 'vuex';
import StringHelper from 'Libs/helpers/string';
import CompletionTerms from 'Components/01-atoms/completion-terms/CompletionTerms.vue';
import InputBase from 'Components/01-atoms/form/input/InputBase.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import ChevronLeftSvg from 'Components/00-generated/ChevronLeftSvg';
import UrlHelper from 'Libs/helpers/url';
import globalMixin from 'Libs/mixins/globalMixin';
import DeviceHelper from 'Libs/helpers/device';

export default {
  name: 'SearchBar',
  components: {
    CompletionTerms,
    InputBase,
    Button,
    ChevronLeftSvg,
  },
  mixins: [globalMixin],
  props: {
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNewHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectionIndex: null,
      inputDisplay: '',
      pastedValue: false,
      firstLaunch: true,
      pasted: false,
      buttonClick: false,
      icon: {
        name: 'search_small',
        width: '21',
        height: '21',
      },
      onlyShowSeachIcon: false,
    };
  },
  computed: {
    ...mapState('core', {
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
      viewport: ({ viewport }) => viewport.range || 'desktop',
      //TODO - replace this with another flag if needed
      isCategorySearchEnabled: ({ salesChannel: { config } }) =>
        config.isCategorySearchEnabled || false,
    }),
    ...mapState('search', {
      searchTerm: (state) => state.searchTerm,
      completionTerms: (state) => state.completionTerms,
      mobileOpen: (state) => state.mobileOpen,
    }),
    searchTitle() {
      const hasTitle = [
        'www-autoteile24-de',
        'www-kfzteile-com',
        'www-k24-at',
      ].includes(this.salesChannelId);
      let title = '';

      if (hasTitle && this.searchTerm === '') {
        title = this.$t('search-bar.autocomplete_title');
      }

      return title;
    },
    dataCompletionUrl() {
      return this.frontFacingPath('search.search_bar.completion');
    },
    searchUrl() {
      return this.frontFacingPath('search.search_bar.search') + this.searchType;
    },
    searchType() {
      if (this.pasted) {
        return '#as';
      } else if (this.buttonClick) {
        return '#msb';
      }
      return '#ms';
    },
    selectedItem: {
      get() {
        return this.selectionIndex !== null
          ? this.completionTerms[this.selectionIndex]
          : null;
      },
      set(index) {
        this.selectionIndex = index;
      },
    },
    fresh: {
      get() {
        return this.firstLaunch;
      },
      set(value) {
        this.firstLaunch = value;
      },
    },
    isIosSafari() {
      const deviceHelper = new DeviceHelper();
      return deviceHelper.isIosSafari();
    },
    isClickOutsideActive() {
      if (this.mobile) {
        return this.mobileOpen && !this.isIosSafari;
      }

      return this.mobileOpen === false && !!this.completionTerms.length;
    },
    searchModifiers() {
      return this.mobile && this.isNewHeader
        ? ['buttonRight', 'iconLeft']
        : ['buttonRight'];
    },
  },
  watch: {
    searchTerm: debounce(function() {
      if (this.searchTerm !== '' || this.inputDisplay !== '') {
        this.updateSearchCompletionTerms({
          url: this.dataCompletionUrl,
        });
      } else {
        this.clearCompletionTerms();
      }
      this.selectionIndex = null;
    }, 100),
  },
  mounted() {
    const parameters = UrlHelper.getUrlParameters();

    if (parameters && parameters.search) {
      const searchParameter = parameters.search.replace(/\+/g, ' ');
      const decodedSearchParameter = decodeURIComponent(searchParameter);

      this.updateSearchTerm(decodedSearchParameter);
    }

    if (
      (this.isCategorySearchEnabled && this.viewport === 'desktop') ||
      this.isNewHeader
    ) {
      this.onlyShowSeachIcon = true;
    }
  },
  methods: {
    ...mapActions('search', [
      'updateSearchCompletionTerms',
      'updateStaticSearchCompletionTerms',
    ]),
    ...mapMutations('search', {
      updateSearchTerm: 'UPDATE_SEARCH_TERM',
      setCompletionTerms: 'SET_COMPLETION_TERMS',
      updateSubmitStatus: 'UPDATE_SUBMIT_STATUS',
    }),
    async searchBarOnChangeHandler(value) {
      this.firstLaunch = false;
      this.clearCompletionTerms();
      this.inputDisplay = value;
      this.updateSearchTerm(value);

      // TODO: find a proper solution
      await this.$nextTick();
      setTimeout(() => {
        if (value === '') {
          this.updateStaticSearchCompletionTerms();
        }
      }, 300);
    },
    searchBarOnFocusHandler(event) {
      const target = event && event.target;
      if (target) {
        const value = target.value;
        this.firstLaunch = false;
        if (value === '') {
          this.updateStaticSearchCompletionTerms();
        }
      }
    },
    clearCompletionTerms() {
      !!this.completionTerms.length && this.setCompletionTerms([]);
      this.selectionIndex = null;
    },
    clearSearchTerm() {
      this.updateSearchTerm('');
      this.clearCompletionTerms();
    },
    selectFromCompletionTerms() {
      const label = StringHelper.removeTags(this.selectedItem.label, 'em');
      this.pasted = true;
      this.inputDisplay = label;
      this.updateSearchTerm(label);
    },
    submitSearch() {
      this.updateSubmitStatus(true);
      this.clearCompletionTerms();
      this.$refs.form.search.value = this.searchTerm.trim();
      this.$nextTick(() => {
        this.$refs.form.submit();
      });
    },
    optionOnClick(index) {
      this.selectedItem = index;
      this.selectFromCompletionTerms();
      this.submitSearch();
    },
    handleValidity() {
      if (!this.$refs.textInput.classes.includes('input--error')) {
        this.$refs.textInput.handleInvalidInput();
      }
    },
    handleButtonClick() {
      this.buttonClick = true;

      this.resolveSearch();
    },
    navigateOptions(direction) {
      if (this.completionTerms.length) {
        switch (direction) {
          case 'up':
            if (this.selectionIndex === null || this.selectionIndex === 0) {
              this.selectionIndex = this.completionTerms.length - 1;
            } else if (this.selectionIndex > 0) {
              this.selectionIndex--;
            }
            break;
          case 'down':
            if (
              this.selectionIndex === null ||
              this.selectionIndex === this.completionTerms.length - 1
            ) {
              this.selectionIndex = 0;
            } else if (this.selectionIndex < this.completionTerms.length - 1) {
              this.selectionIndex++;
            }
            break;
        }
        const label = StringHelper.removeTags(this.selectedItem.label, 'em');
        this.inputDisplay = label;
        this.pasted = false;
      }
    },
    resolveSearch() {
      if (!!this.searchTerm || !!this.inputDisplay) {
        if (this.selectionIndex !== null && !this.pasted) {
          this.selectFromCompletionTerms();
        }
        this.submitSearch();
        return;
      }

      this.handleValidity();
    },
    toggleFocus(isFocused) {
      this.$refs.textInput[isFocused ? 'focus' : 'blur'].call();
    },
    closeCompletionsElement() {
      setTimeout(() => {
        this.clearCompletionTerms();
      }, 400);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.searchBar {
  width: 100%;
  position: relative;
  overflow: visible;
}

.form {
  width: 100%;
}

.wrapper {
  display: flex;
}

.searchInput {
  flex-grow: 1;
  background: var(--color-white);
  border-radius: var(--border-radius-default);

  .iconLeft {
    fill: var(--color-primary);
  }

  ::v-deep {
    .input-icon.input-icon1 {
      width: 30px;
      height: 30px;
      left: 0;

      button {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.searchInput ::v-deep input[type='text'] {
  height: 40px;
  padding: 12px var(--space-2);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--color-white) !important;
  border: solid 1px var(--color-alt);
  border-right: 0;
  transition: border-color 0.3s ease-in;
  line-height: normal;

  &:focus {
    border-color: var(--color-secondary);

    & + .searchButton {
      border-color: var(--color-secondary);
    }
  }
}

.searchButton {
  border-radius: 0 3px 3px 0;
  color: var(--color-white);
  transition: border-color 0.3s ease-in;
  cursor: pointer;
  font-weight: bold;

  background-color: var(--color-secondary);
  border-left-color: var(--color-secondary);

  &--hidden {
    display: none;
  }
}

.completionTerms {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  top: 100%;
}

@media #{$_mediaMDown} {
  .completionTerms {
    margin: 0 4px;
    top: calc(100% - 5px);
  }
}

.secondary-theme {
  .completionTerms {
    box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.15);
  }

  .searchInput ::v-deep input[type='text'] {
    padding: 0 0 0 20px;
  }
}

:root[data-theme='tirendo'] {
  .searchButton {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
}
</style>
