import { has } from 'lodash';

export default {
  products(state) {
    let products = [];
    const section = (state.sections && state.sections.length) || 0;

    if (
      has(state, 'sections') &&
      section
    ) {
      products = state.sections.reduce((products, section) => {
        return [...products, ...section.products];
      }, []);
    }

    return products;
  },

  getTotalProductCount(state) {
    return state.totalProductCount || 0;
  }
};
