var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNativeApp),expression:"!isNativeApp"}],class:[
    _vm.classes,
    {
      'page-footer--rebranding': _vm.isRebrandingChannel,
    },
  ],attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"page-footer-wrapper"},[(!_vm.isReducedFooter)?_c('div',[(_vm.navigation.cars || _vm.navigation.products)?_c('div',{staticClass:"row links footer-top"},[(_vm.navigation.cars)?_c('LinksList',{staticClass:"column",attrs:{"columns":"inline","separator":"|","title":_vm.navigation.cars.headline,"items":_vm.navigation.cars.links}}):_vm._e(),_vm._v(" "),(_vm.navigation.products)?_c('LinksList',{staticClass:"column",attrs:{"columns":"inline","separator":"|","title":_vm.navigation.products.headline,"items":_vm.navigation.products.links}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row links",class:{ 'footer-middle': _vm.navigation.cars || _vm.navigation.products }},[_vm._l((_vm.navigation.footer.links),function(linkList,index){return _c('LinksList',{key:index,staticClass:"column one links__about",attrs:{"title":linkList.headline,"items":linkList.links}})}),_vm._v(" "),(!!_vm.paymentMethods.length || !!_vm.deliveryMethods.length)?_c('div',{staticClass:"column two services"},[(!!_vm.paymentMethods.length)?_c('FooterServices',{attrs:{"type":"payment","title":_vm.$t('page-footer.accepted_payments'),"providers":_vm.paymentMethods}}):_vm._e(),_vm._v(" "),_c('FooterServices',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDeliveryMethods),expression:"showDeliveryMethods"}],attrs:{"type":"delivery","title":_vm.$t('page-footer.shipping_providers'),"providers":_vm.deliveryMethods}})],1):_vm._e()],2),_vm._v(" "),(
          _vm.getShowAppLinks ||
          _vm.getShowSocials ||
          (_vm.getShowChannels && _vm.navigation.channels)
        )?_c('div',{staticClass:"row footer-bottom"},[_c('DeshopSocial',{staticClass:"deshop-social",attrs:{"show-app-links":_vm.getShowAppLinks,"show-socials":_vm.getShowSocials}}),_vm._v(" "),(_vm.getShowChannels && _vm.navigation.channels)?_c('div',{staticClass:"channelsWrapper"},[_c('LinksList',{attrs:{"type":"channels","items":_vm.navigation.channels.links,"collapsable":false}})],1):_vm._e()],1):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.isReducedFooter)?_c('div',{staticClass:"infoWrapper row"},[_c('div',{staticClass:"phoneContainer"},[_c('div',{staticClass:"phone"},[_vm._v("\n          "+_vm._s(_vm.$t('page-footer.have_questions'))+"\n          "),_c('a',{attrs:{"href":`tel:${_vm.contactUsInfo.phone}`}},[_vm._v(_vm._s(_vm.contactUsInfo.formattedPhone))])]),_vm._v(" "),_c('div',{staticClass:"times"},[_vm._v("\n          "+_vm._s(_vm.contactUsInfo.openingTimes)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"links"},_vm._l((_vm.navigation.about.links),function(link,index){return _c('a',{key:index,staticClass:"link",attrs:{"href":link.url}},[_vm._v(_vm._s(link.title))])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"copyrightWrapper"},[_c('div',{staticClass:"copyright"},[_vm._v("\n        © "+_vm._s(new Date().getFullYear())+" "+_vm._s(_vm.$t('page-footer.copyright'))+"\n      ")]),_vm._v(" "),_c('img',{staticClass:"logo",attrs:{"alt":"Tec Alliance","src":_vm.resolveFile('images/page-footer/tec_alliance.jpg'),"loading":"lazy","width":"159","height":"53"}})]),_vm._v(" "),(!_vm.isReducedFooter)?_c('p',{staticClass:"disclaimer"},[_vm._v("\n      "+_vm._s(_vm.$t('page-footer.disclaimer'))+"\n    ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }