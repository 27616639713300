<template>
  <div class="autoSuggest-dialog">
    <div class="autoSuggest-container">
      <div
        v-for="suggestion in autoSuggest.addresses"
        :key="suggestion.uuid"
        class="autoSuggest-tile"
        @click="$emit('click', suggestion)"
      >
        {{ suggestion.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoSuggestDropdown',
  props: {
    autoSuggest: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'variables/colors';

.autoSuggest-dialog {
  position: absolute;
  z-index: 11;
  margin-top: var(--space-1);
  width: 95%;
}

.autoSuggest-dialog::before {
  content: '';
  width: var(--space-2);
  height: var(--space-2);
  position: absolute;
  left: var(--space-4);
  top: -6px;
  z-index: 1;
  -webkit-transform: translateX(-50%) scaleX(0.6) rotate(45deg);
  transform: translateX(-50%) scaleX(0.6) rotate(45deg);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  background: var(--color-white);
}

.autoSuggest-container {
  background: var(--color-white);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  padding: var(--space-1) 0;
}

.autoSuggest-container::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: var(--color-white);
}

.autoSuggest-tile {
  position: relative;
  z-index: 2;
  padding: var(--space-1) var(--space-2);
  background: var(--color-white);
  cursor: pointer;

  &:hover {
    background: var(--color-gallery);
  }
}
</style>
