var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"login-form",class:[
    {
      'rebranding-form': _vm.isRebrandingChannel,
    },
  ],attrs:{"data-preload":[_vm.$options.name]},on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin.apply(null, arguments)}}},[(_vm.wrongCredentials)?_c('span',{staticClass:"login-form-error qa-LoginForm_wrong-credentials"},[_vm._v("\n    "+_vm._s(_vm.$t('login-form.check_password_email'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.requestFailed)?_c('span',{staticClass:"login-form-error qa-LoginForm__failed_request"},[_vm._v("\n    "+_vm._s(_vm.$t('login-form.error_message'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.title)?_c('h1',{staticClass:"title"},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"login-form-content qa-LoginForm__fields"},[_c('Input',{staticClass:"email qa-LoginForm__input_email",attrs:{"name":"email","type":"email","form":{
        vars: {
          id: 'qa-id_LoginForm__input_email',
          full_name: 'email',
          required: true,
          value: _vm.email,
          label: _vm.$t('login-form.inputs.email'),
        },
      }},on:{"input":(value) => (_vm.email = value)}}),_vm._v(" "),_c('Input',{staticClass:"password qa-LoginForm__input_password",attrs:{"name":"password","type":"password","form":{
        vars: {
          id: 'qa-id_LoginForm__input_password',
          full_name: 'password',
          required: true,
          value: _vm.password,
          label: _vm.$t('login-form.inputs.password'),
        },
      }},on:{"input":(value) => (_vm.password = value)}})],1),_vm._v(" "),_c('a',{staticClass:"forgot-password qa-LoginForm__forgot-password_button",attrs:{"href":"/customer/forgot-password"}},[_vm._v("\n    "+_vm._s(_vm.$t('login-form.forgot_password'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"buttons"},[_c('Button',{staticClass:"login-button qa-LoginForm__submit_button",attrs:{"title":_vm.$t('login-form.login-button.text'),"modifiers":['primary', _vm.isRebrandingChannel ? 'rebranding' : ''],"type":"submit","name":"login[submit]","disabled":_vm.isLoading || _vm.disabled,"has-loading":_vm.isLoading}}),_vm._v(" "),(_vm.shouldRegister)?[_c('span',{staticClass:"divider"},[_vm._v("\n        "+_vm._s(_vm.$t('login-form.or'))+"\n      ")]),_vm._v(" "),_c('Button',{staticClass:"register-button qa-LoginForm__register_button",attrs:{"title":_vm.$t('login-form.login.registration'),"modifier":"secondary","href":_vm.frontFacingPath('customer.shop.registration'),"disabled":_vm.isLoading || _vm.disabled}})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }