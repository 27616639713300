import { setCheckoutState } from './state';
import mutations from './mutations';
import * as actions from './actions';
import getters from 'Store/modules/checkout/getters';

export default (placeholder) => ({
  state: setCheckoutState(placeholder),
  mutations,
  actions,
  getters,
  namespaced: true,
});
