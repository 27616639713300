<template>
  <component
    :is="componentType"
    :class="[
      classes,
      'qa-Button',
      {
        'button--disabled': disabled,
        'button--hasLoading button--loading': hasLoading,
        'button--focus-border': hasFocusBorder,
        'button--gray': isGray,
        'button--icon': !!icon,
      },
    ]"
    :name="name"
    :type="componentType === 'a' ? false : type"
    :disabled="disabled"
    :href="href || false"
    :target="componentType === 'a' ? target || !!target : false"
    :title="title || !!title"
    :value="value || !!value"
    v-bind="attrs"
    :data-preload="[$options.name]"
    @click="onClick"
    @mouseup="onMouseup"
  >
    <div class="inner">
      <slot name="icon" />

      <LoadingSvg
        v-if="!!hasLoading"
        :original="true"
        :inline="true"
        :class="['icon', 'icon--loading']"
      />

      <slot>
        <span v-if="!!title" class="title">
          {{ title }}
        </span>
      </slot>
    </div>
  </component>
</template>

<script>
import noop from 'lodash/noop';
import LoadingSvg from 'Components/00-generated/LoadingSvg.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import { buttonMixin } from 'Libs/mixins/buttonMixin';

export default {
  name: 'Button',
  components: { LoadingSvg },
  mixins: [globalMixin, buttonMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasLoading: {
      type: Boolean,
      default: false,
    },
    hasFocusBorder: {
      type: Boolean,
      default: true,
    },
    onClick: {
      type: Function,
      default: noop,
    },
    target: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: [String, Boolean],
      default: false,
    },
    type: {
      type: [String, Boolean],
      default: false,
    },
    href: {
      type: [String, Boolean],
      default: false,
    },
    icon: {
      type: [String, Object],
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentType() {
      return this.href ? 'a' : 'button';
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'utils/fonts';
@import 'mixins';
@import 'animations';

.button {
  display: inline-block;
  width: auto;
  padding: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: var(--border-radius-default);
  min-height: 40px;
  white-space: nowrap;

  font-size: var(--font-size-M);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-tory-blue);
  cursor: pointer;

  transition: color var(--time-S) ease-in-out;

  // NOTE Different display prop for links
  @at-root {
    a#{&} {
      display: inline-flex;
    }
  }

  &:focus {
    z-index: 1;
    outline: 0;
  }

  &--focus-border {
    @include activeState;
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    padding: 0 var(--space-2);
  }

  .title {
    line-height: 1;
    align-self: center;

    &:empty {
      display: none;
    }
  }

  ::v-deep .icon {
    align-self: center;
    flex-shrink: 0;

    & + .title {
      margin-left: var(--space-1);
    }

    path {
      fill: var(--color-secondary);
    }
  }

  /* NOTE Keep disabled style safe */
  &[disabled] {
    color: var(--color-black--disabled);
    cursor: not-allowed;

    & .title {
      margin-right: var(--space-1);
      margin-left: 0;
    }

    ::v-deep .icon path {
      fill: var(--color-black--disabled);
    }
  }

  &--primary,
  &--secondary,
  &--tertiary,
  &--japaneseLaurel,
  &--ghostOrange,
  &--ghostWhite {
    border-width: 1px;
    border-style: solid;
    transition: all var(--time-XS) ease-in-out;

    .inner {
      padding-right: var(--space-4);
      padding-left: var(--space-4);
    }

    .plusSvg {
      position: relative;
      top: -1px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &.button--active,
      &:active {
        color: var(--color-white);
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);

        &:not(.button--loading) {
          ::v-deep .icon path {
            fill: var(--color-white);
          }
        }
      }
    }

    &[disabled] {
      pointer-events: none;

      @include increaseSpecificity(color, var(--color-white));
      @include increaseSpecificity(background-color, var(--color-silver));
      @include increaseSpecificity(border-color, var(--color-silver));

      ::v-deep .icon path {
        fill: var(--color-white);
      }
    }
  }

  &--primary {
    color: var(--color-white);
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    ::v-deep .icon path {
      fill: var(--color-white);
    }

    // REVISIT Only primary?
    &.button--error {
      background-color: red;
    }
  }

  &--primaryReversed {
    color: var(--color-white);

    background-color: var(--color-secondary);
    border-color: var(--color-secondary);

    ::v-deep .icon path {
      fill: var(--color-white);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--color-reversed);
        border-color: var(--color-reversed);
      }
    }
  }

  &--secondary {
    color: var(--color-rolling-stone);
    background-color: var(--color-white);
    border-color: var(--color-alto);

    ::v-deep .icon path {
      fill: var(--color-rolling-stone);
    }
  }

  &--tertiary {
    color: var(--color-white);

    background-color: var(--color-secondary);
    border-color: var(--color-secondary);

    ::v-deep .icon path {
      fill: var(--color-white);
    }
  }

  &--japaneseLaurel {
    color: var(--color-white);
    background-color: var(--color-japanese-laurel);
    border-color: var(--color-japanese-laurel);
    height: 100%;

    & .inner {
      padding-right: var(--space-4);
      padding-left: var(--space-4);

      ::v-deep .icon path {
        fill: var(--color-white);
      }
    }

    /* NOTE Stay green in disabled case */
    &[disabled] {
      @include increaseSpecificity(color, var(--color-white));
      @include increaseSpecificity(
        background-color,
        var(--color-japanese-laurel)
      );
      @include increaseSpecificity(border-color, var(--color-japanese-laurel));
    }
  }

  &--ghostWhite {
    @include increaseSpecificity(color, var(--color-white));
    @include increaseSpecificity(border-color, var(--color-white));

    ::v-deep .icon path {
      fill: var(--color-white);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: rgba(var(--color-white), 0.2) !important;
        border-color: var(--color-white) !important;
      }
    }
  }

  &--ghostOrange {
    @include increaseSpecificity(color, var(--color-tango));
    @include increaseSpecificity(border-color, var(--color-alto));
    @include increaseSpecificity(background-color, var(--color-white));

    ::v-deep .icon path {
      fill: var(--color-tango);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: darken(#ffffff, 2) !important;
        border-color: var(--color-alto) !important;

        ::v-deep .icon path {
          @include increaseSpecificity(fill, var(--color-tango));
        }
      }
    }

    &.button--error {
      background-color: red;
    }
  }

  &--icon {
    .inner {
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }
  }

  &--hasLoading {
    .inner {
      position: relative;
    }

    .icon--loading {
      animation: linearRotationKeyframes var(--time-L) ease-in-out infinite;
      opacity: 0;
      position: absolute;
      left: calc(50% - 16px);
      top: calc(50% - 15px);
      transition: opacity var(--time-S) ease-in-out;
      width: 30px;
      height: 30px;

      ::v-deep {
        g {
          stroke: var(--color-white);
        }

        path {
          fill: none !important;
        }
      }

      stroke: var(--color-primary);
    }

    ::v-deep .icon + .title {
      transition: opacity var(--time-S) ease-in-out;
    }

    &.button--loading {
      pointer-events: none;

      border-color: var(--color-secondary);
      background-color: var(--color-secondary);

      ::v-deep .icon.icon--loading {
        opacity: 1;
      }

      ::v-deep .icon,
      ::v-deep .icon + .title {
        opacity: 0;
      }
    }
  }

  // --- Simple modifiers with stories ---

  &--full {
    display: block;
    width: 100%;

    .inner {
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }
  }

  &--slim {
    .inner {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &--short {
    min-height: 30px;

    .inner {
      padding: 0;
      font-size: 11px;
      line-height: normal;

      svg {
        width: 19px !important;
        height: 19px !important;
      }

      .title {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  &--reverse {
    .inner {
      flex-direction: row-reverse;

      .title {
        margin-right: var(--space-1);
        margin-left: 0;
      }
    }
  }

  &--small {
    max-height: 30px;
    min-height: 30px;

    .inner {
      padding-right: var(--space-1);
      padding-left: var(--space-1);
      font-size: var(--font-size-S);
    }
  }

  &--smaller {
    max-height: 28px;
    min-height: 28px;

    .inner {
      padding-right: var(--space-1);
      padding-left: var(--space-1);
      font-size: 11px;
    }
  }

  &--lowercase {
    text-transform: none;
  }

  &--grayIcon {
    ::v-deep.icon,
    ::v-deep.icon path {
      fill: var(--color-rolling-stone) !important;
    }
  }

  &--gray {
    color: var(--color-rolling-stone);

    ::v-deep .icon,
    ::v-deep .icon path {
      fill: var(--color-rolling-stone) !important;
    }
  }

  &--inputLeft {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // --- No stories ---

  &--block {
    width: 42px;
    display: flex;
    justify-content: center;
    border-radius: 0;
    border: none;

    color: var(--color-rolling-stone);
    font-size: var(--font-size-S);
    letter-spacing: 0;
    line-height: 24px;

    .inner {
      padding-left: 10px;
      padding-right: 10px;
    }

    ::v-deep .icon {
      margin: 0;

      & + .title {
        margin-left: var(--space-1);
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:not(:hover) {
        &.button--activeOrange {
          color: var(--color-white);

          ::v-deep .icon {
            fill: var(--color-white);
          }
        }
      }
    }
  }

  &--icon {
    .inner {
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }

    ::v-deep .icon {
      & + .title {
        margin-left: var(--space-1);
      }
    }
  }

  &--themePrimaryColor {
    color: var(--color-primary);

    ::v-deep .icon,
    ::v-deep .icon path {
      fill: var(--color-primary);
    }
  }

  &--pulse-in {
    ::v-deep svg {
      transition: all 0.15s ease-in;

      animation-fill-mode: both;
      animation-name: pulseIn;
      animation-delay: 0.1s;
      animation-timing-function: ease-in;
      animation-duration: 0.7s; // .3s
    }
  }

  &--pr-0 {
    .inner {
      padding-right: 0;
    }
  }

  &--pl-0 {
    .inner {
      padding-left: 0;
    }
  }

  &--px-0 {
    .inner {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &--py-0 {
    .inner {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--flatGray {
    color: var(--color-rolling-stone);
  }

  &--nowrap {
    .title {
      white-space: nowrap;
    }
  }

  &--stretched {
    height: 100%;
  }

  &--expand {
    display: block;
    flex-grow: 1;

    .inner {
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }
  }

  &--iconInverted {
    .inner {
      flex-direction: row-reverse;
    }
  }

  &--iconRight {
    .inner {
      flex-direction: row-reverse;

      & .title {
        margin-right: var(--space-1);
        margin-left: 0;
      }
    }
  }

  &--link {
    border: none;
    text-decoration: underline;
    text-transform: unset;
    color: var(--color-nero);
    font-weight: 500;
    min-height: auto;

    .inner {
      height: fit-content;
    }
  }

  // Rebranding
  &--rebranding {
    @include rebranding-para-bold;

    &.button--primary {
      background: var(--color-rebranding-primary);
      color: var(--color-rebranding-white);
      border-color: var(--color-rebranding-primary);

      &:hover {
        background: var(--color-rebranding-primary--hover);
      }

      &:active {
        background: var(--color-rebranding-primary--pressed);
      }

      &.button--disabled {
        @include increaseSpecificity(
          'background',
          var(--color-rebranding-primary--disabled)
        );
      }
    }
  }
}

@keyframes pulseIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1);
  }

  20% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

@media #{$_mediaSDown} {
  .button {
    &--sdown {
      .inner {
        padding-left: 4px;
      }
    }
  }
}

@media #{$_mediaMUp} {
  .button {
    &--label-mobile {
      .title {
        display: none;
      }
    }

    &--mobile {
      display: none;
    }
  }
}

@media #{$_mediaLUp} {
  .button {
    &--label-desktop {
      ::v-deep .icon + .title {
        display: block;
        margin-left: 0;
      }
    }

    &--icon-mobile {
      ::v-deep .icon {
        display: none;
      }
    }
  }
}

@media #{$_mediaMDown} {
  .button {
    &--label-desktop {
      .title {
        display: none;
      }
    }

    &--icon-mobile {
      ::v-deep .icon {
        display: block;
      }
    }
  }
}

@media (max-width: 600px) {
  .button {
    &--ghostWhite {
      &.button--icon {
        .inner {
          padding: 9px;

          .title {
            display: none;
          }
        }
      }
    }
  }
}

@media #{$_mediaSDown} {
  .button {
    &--primary,
    &--secondary {
      .inner {
        padding-right: var(--space-2);
        padding-left: var(--space-2);
      }
    }
  }
}
</style>
