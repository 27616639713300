<template>
  <div
    :class="[
      classes,
      { 'is-black': isBlack, 'is-rebranding': isRebrandingChannel },
    ]"
    :data-preload="[$options.name]"
  >
    <article>
      <div class="header">
        <div class="title-container">
          <span class="dialog-title">{{ title }}</span>
        </div>
        <button
          class="closeButton"
          data-testid="dialog-frame-closeButton"
          @click="onCloseHandler"
        >
          <CloseSvg width="24" height="24" />
        </button>
      </div>

      <div class="body">
        <slot />
      </div>

      <div class="buttons-container">
        <slot name="buttons" />
      </div>
    </article>
  </div>
</template>

<script>
import CloseSvg from 'Components/00-generated/CloseSvg.vue';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'DialogFrame',
  components: {
    CloseSvg,
  },
  mixins: [globalMixin],
  props: {
    onCloseHandler: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    isBlack: {
      type: Boolean,
      default: false,
    },
    isRebrandingChannel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';
@import 'utils/fonts';

.dialogFrame {
  @include dropShadow;

  min-width: 300px;

  &.is-black {
    article {
      .header {
        background-color: var(--color-nero);
        color: var(--color-white);
        border-left-color: var(--color-nero);
        font-family: 'Glacial Indifference';
        font-weight: 700;
        font-size: var(--font-size-ML);
        padding: var(--space-3) var(--space-3) var(--space-1);

        &::before {
          content: none;
        }

        .closeButton {
          border: 0;
          background: transparent;
          color: var(--color-white);
        }
      }

      .body {
        background-color: var(--color-nero);

        .slotFrame p {
          color: var(--color-white);
          font-size: var(--font-size-M);
          margin-bottom: 24px;
        }

        .slotFrame {
          padding: 0 var(--space-3);
          margin-bottom: 0;

          .dialog_content {
            overflow-y: unset;
            padding: 0;
            max-height: 100%;
          }
        }
      }

      .buttons-container {
        background-color: var(--color-nero);
        padding: 16px 24px 24px;

        .buttonsSection {
          border-top: var(--color-nero);
          padding: 0;
        }
      }
    }
  }

  &.is-rebranding article {
    .header {
      @include rebranding-h4;

      &::before {
        background: var(--color-rebranding-primary);
      }
    }

    .body {
      @include rebranding-para-regular;
    }
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: var(--space-2);
  background-color: var(--color-white);
  height: auto;
  flex-shrink: 0;

  border-left-color: var(--color-primary);

  .title-container {
    display: flex;
    flex-flow: column nowrap;
  }

  .dialog-title {
    font-weight: 700;
  }

  &::before {
    background: var(--color-primary);

    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 100%;
  }
}

.closeButton {
  @include square(30px);

  line-height: 24px;
  padding: 0;
  border-radius: 50%;
  color: var(--color-rolling-stone);
  background-color: var(--color-wild-sand);
  border: solid 1px var(--color-alto);
  cursor: pointer;

  svg {
    margin-top: 2px;
  }
}

.body {
  border-top: solid 1px var(--color-alto);
  background-color: var(--color-white);
  flex-grow: 999;
  overflow: auto;
  height: 100%;
  max-height: calc(80vh - 100px);
}

.buttons-container {
  background-color: var(--color-white);
}

article {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.dialog.dialog--theme-secondary {
  color: var(--color-black);

  .frame {
    max-width: 725px;
  }

  header.header {
    border: 0;
    color: var(--color-black);
    font-size: 26px;
    text-align: left;
    align-items: initial;
    padding: 16px 25px 4px;

    &::before {
      width: 0;
    }

    .dialog-title {
      text-align: left;
      font-size: var(--font-size-L);
    }
  }

  .body {
    border: 0;
    margin-top: -1px;
  }

  .slotFrame {
    padding: 0 38px 0 25px;
    color: var(--color-black);

    p {
      font-size: var(--font-size-SM);
      margin-bottom: 27px;
      color: var(--color-black);
    }
  }

  .dialog_content {
    overflow-y: scroll;
    padding: 0 20px;
    max-height: 300px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--color-white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-alto-sec);
    }
  }

  .buttonsSection.buttonsSection--single {
    border: 0;
    margin: 5px auto 10px;
    max-width: 40%;
  }

  @media (max-width: $_widthLFrom) {
    .dialogFrame {
      width: 100%;
      min-width: 100%;
    }

    .slotFrame {
      height: 100%;

      & > p {
        height: 45px;
        margin-bottom: 0;
      }
    }

    .dialog_content {
      height: calc(100% - 65px);
      max-height: none;
    }
  }

  @media #{$_mediaMDown} {
    .dialogFrame {
      position: fixed;
      bottom: 0;
      transform: translate(0, 0);
      left: 0;
      overflow: initial;

      &.is-black {
        top: 0;
      }
    }

    .dialog_content {
      padding: 0 14px 0 8px;

      &.is-black {
        overflow-y: unset;
      }
    }

    .buttonsSection.buttonsSection--single {
      position: fixed;
      max-width: 100%;
      width: 100%;
      bottom: -10px;
    }

    header.header {
      padding: 21px 20px 2px 25px;
    }

    .slotFrame {
      padding: 0 30px 0 25px;
    }
  }
}
</style>
