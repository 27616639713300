export const addUrlMappings = (state, { urlMappings }) => {
  state.fetchedUrls = {
    ...state.fetchedUrls,
    ...urlMappings,
  };
};

export const setViewport = (state, { name, range }) => {
  state.viewport = {
    name,
    range,
  };
};


export const setIsLoggedIn = (state, value) => {
  state.requestData.isLoggedIn = value;
};
