<i18n src="./translations"></i18n>

<template>
  <div
    ref="rootElement"
    :class="[
      classes,
      isMobile
        ? 'addedToCartNotification--mobile'
        : 'addedToCartNotification--desktop',
    ]"
    :data-preload="[$options.name]"
    :style="stickyProps"
  >
    <div class="triangle">
      <Icon name="triangle" width="25" height="25" />
    </div>

    <span class="header header--mobile">
      <span class="header-text">
        {{ $t('added-to-cart-notification.cart.cart') }}
      </span>
      <div
        class="ga-addedToCartNotification-cartBtn header-icon"
        :title="$t('added-to-cart-notification.cart.view')"
        @click="handleCartClick()"
      >
        <span v-if="cartItemsCount > 0" class="bubble">
          {{ cartItemsCount }}
        </span>

        <Icon name="cart" height="21" width="21" />
      </div>
    </span>

    <span class="header header--desktop">
      <span class="header-text">
        {{ $t('added-to-cart-notification.cart.added') }}
      </span>

      <Icon
        class="header-icon"
        name="added_to_cart_check"
        height="22"
        width="22"
      />
    </span>

    <div v-show="!isMobile" class="body">
      <ProductTile v-if="product" :product="product" :fixed-quantity="true" />
    </div>

    <div class="footer">
      <Button
        class="ga-addedToCartNotification-cartBtn gtm--addedToCartNotification-cartBtn"
        :modifiers="['primary', 'full']"
        :title="$t('added-to-cart-notification.cart.view')"
        :href="frontFacingPath('checkout.cart.details')"
      />
    </div>
  </div>
</template>

<script>
import Icon from 'Components/00-generated/Icon';
import ProductTile from 'Components/02-molecules/product-tile/ProductTile';
import Button from 'Components/01-atoms/button/Button';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState } from 'vuex';

export default {
  name: 'AddedToCartNotification',
  components: {
    ProductTile,
    Icon,
    Button,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    stickyDesktopProps: null,
    offsetTop: 0,
  }),
  computed: {
    ...mapState('core', {
      isMobile: ({ viewport }) => viewport.range !== 'desktop',
    }),
    ...mapState('miniCart', {
      cartItemsCount: (state) => state.data.count || 0,
    }),
    stickyProps() {
      return this.isMobile ? null : this.stickyDesktopProps;
    },
  },
  mounted() {
    const { offsetTop, offsetHeight } = this.$refs.rootElement.parentElement;
    this.offsetTop = offsetTop + offsetHeight + 15; // 15 - height of the little arrow
    this.handleScroll();

    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.$refs.rootElement) {
        return;
      }

      // Remove sticky props
      if (window.pageYOffset < this.offsetTop) {
        this.stickyDesktopProps = null;
        this.removeModifier('stickyOnDesktop');
        return;
      }

      // Do nothing when the sticky props are already computed&applied
      if (this.stickyDesktopProps) {
        return;
      }

      const {
        left,
        width,
      } = this.$refs.rootElement.parentElement.getBoundingClientRect();
      this.addModifier('stickyOnDesktop');
      this.stickyDesktopProps = {
        top: '15px', // 15 - height of the little arrow
        right: document.body.offsetWidth - left - width + 'px',
        position: 'fixed',
      };
    },
    handleCartClick() {
      location.assign(this.frontFacingPath('checkout.cart.details'));
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.addedToCartNotification {
  @include dropShadow;

  background-color: var(--color-white);

  z-index: 6;

  &--stickyOnDesktop {
    .triangle {
      display: block;
    }

    &::before {
      display: none;
    }
  }

  &--desktop {
    position: absolute;
    top: 48px;
    right: 0;
    width: 320px;

    .header--desktop {
      display: flex;
    }

    .header-text {
      font-size: var(--font-size-M);
      font-weight: bold;
      padding: 30px 0;
      display: block;
    }

    .header-icon {
      display: block;
    }
  }

  &--mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: unset;

    .header--mobile {
      display: flex;
    }

    .header {
      padding-top: 15px;
    }

    .header-text {
      display: block;
      font-size: var(--font-size-SM);
    }

    .header-icon {
      display: block;
      position: absolute;
      right: 35px;

      svg {
        fill: var(--color-primary);
      }
    }
  }
}

.triangle {
  display: none;
  position: absolute;
  transform: scale(2, 1);
  top: -15px;
  right: 25px;

  svg {
    fill: var(--color-white);
    filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.3));
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  background-color: var(--color-white);
  color: var(--color-black);

  svg {
    margin-left: var(--space-4);
    fill: var(--color-black);
  }
}

.header--desktop,
.header--mobile {
  display: none;
}

.body {
  background-color: var(--color-white);
  border-top: 1px solid var(--color-alto);
}

.footer {
  padding: 15px;
  background: var(--color-white);
}

.bubble {
  position: absolute;
  top: -9px;
  right: -12px;
  transform: scale(0.7);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  border-radius: 50%;

  animation: fadeIn 0.25s ease-in 0s 1;
  color: var(--color-white);
  font-size: 11px;
  font-weight: bold;

  background: var(--color-secondary);
}
</style>
