<i18n src="./translations"></i18n>

<template>
  <div class="caris-selection">
    <span class="caris-selection__headline">
      {{ `${$t('caris-selection.headline')}:` }}
    </span>
    <p class="caris-selection__selection-name selection-name">
      {{ $t('caris-selection.my') }}
      <span class="selection-name__model">
        {{ `${selectionNameModel}, ` }}
      </span>
      {{ selectionNameRest }}
      <Button
        v-if="hasLongDescription"
        class="caris-selection__description_link"
        type="link"
        modifier="link"
        :title="$t('caris-selection.long_description')"
        :on-click="handleFullDescriptionDialog"
      />
    </p>
    <Button
      class="caris-selection__select-new-car-button gtm--caris-selectNewCarButton"
      :title="
        viewport === 'mobile'
          ? $t('caris-selection.change_selection')
          : $t('caris-selection.select_new_car')
      "
      :class="{
        'button--primary': theme === 'tirendo',
        'button--primaryReversed': theme !== 'tirendo',
      }"
      type="button"
      :on-click="handleNewCarClick"
    />
    <SimpleDialog
      class="caris-selection__full-description-dialog"
      :active="isDescriptionDialogActive"
      :title="$t('caris-selection.headline')"
      :click-outside="true"
      @close="handleFullDescriptionDialog"
    >
      <p class="caris-selection__description">
        {{ $t('caris-selection.my') }}
        <span class="selection-name__model">
          {{ selectionNameModel }}
        </span>
      </p>

      <table class="caris-selection__table">
        <tbody class="caris-selection__table-body">
          <tr
            v-for="(description, index) in descriptionList"
            :key="index"
            class="caris-selection__description_table_row"
          >
            <td
              v-for="(info, index2) in Object.entries(description)[0]"
              :key="index2"
              class="caris-selection__description_table_cell"
            >
              {{ info }}
            </td>
          </tr>
        </tbody>
      </table>
    </SimpleDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from 'Components/01-atoms/button/Button';
import SimpleDialog from 'Components/01-atoms/simple-dialog/SimpleDialog';

export default {
  name: 'CarisSelection',
  components: { Button, SimpleDialog },
  props: {
    selectionName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDescriptionDialogActive: false,
      descriptionList: [],
      descriptionLabelsList: [
        this.$t('caris-selection.type'),
        this.$t('caris-selection.motor'),
        this.$t('caris-selection.year'),
        this.$t('caris-selection.kba'),
      ],
    };
  },
  computed: {
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
      theme: ({ salesChannel }) => salesChannel.theme,
    }),
    selectionNameModel() {
      return this.selectionName.split(' (')[0];
    },
    selectionNameRest() {
      const splitSelectionName = this.selectionName.replace(
        `${this.selectionNameModel.split(',')[0]}`,
        ''
      );
      if (this.hasLongDescription) {
        this.updateDescriptionList();
        const maxDescriptionLength = 59;

        return `${splitSelectionName.substring(0, maxDescriptionLength)}...`;
      }

      return splitSelectionName;
    },
    hasLongDescription() {
      const maxDescriptionLength = 98;

      return (
        this.selectionName.length > maxDescriptionLength &&
        this.viewport !== 'mobile'
      );
    },
  },
  methods: {
    handleNewCarClick() {
      this.$emit('select-new-vehicle');
    },
    handleFullDescriptionDialog() {
      this.isDescriptionDialogActive = !this.isDescriptionDialogActive;
    },
    updateDescriptionList() {
      const splitCarisDescription = this.selectionName.split(/,(?![^(]*\)) /g);

      const motorDescription = splitCarisDescription.slice(1, 3).join(' ');

      splitCarisDescription.splice(1, 2, motorDescription);

      this.descriptionList = splitCarisDescription.map((description, index) => {
        if (index === splitCarisDescription.length - 1) {
          return {
            [this.descriptionLabelsList[index]]: this.handleKbaString(
              description.split(':')[1].trim()
            ),
          };
        }
        return {
          [this.descriptionLabelsList[index]]: description,
        };
      });
    },
    handleKbaString(kbaString) {
      const kbaArray = kbaString.split('');
      const index = 4;

      kbaArray.splice(index, 0, ' ');

      return kbaArray.join('');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

.caris-selection {
  display: flex;
  flex-direction: column;
  height: 100%;

  --regular-font-size: var(--font-size-M);
  --regular-line-height: var(--font-size-ML);
  --headline-font-size: 18px;
  --headline-line-height: 22px;
  --button-height: 40px;
  --button-font-size: var(--font-size-M);
}

.caris-selection__headline {
  font-size: var(--headline-font-size);
  line-height: var(--headline-line-height);
  color: var(--color-secondary);
  font-weight: bold;
}

.caris-selection__selection-name {
  font-size: var(--regular-font-size);
  line-height: var(--regular-line-height);
  color: var(--color-white);
  margin-top: var(--space-1);

  .caris-selection__description_link {
    color: var(--color-white);
    padding: var(--space-0);

    ::v-deep .inner {
      padding: var(--space-0);
    }
  }
}

.selection-name__model {
  font-weight: bold;
  text-transform: uppercase;
}

.caris-selection__select-new-car-button {
  margin-top: var(--space-1);
  min-height: var(--button-height);
  width: 100%;
  font-size: var(--font-size-S);
}

.caris-selection__full-description-dialog {
  max-width: 624px;

  .simple-dialog__body {
    margin-top: var(--space-3);
  }
}

.caris-selection__description {
  font-size: var(--font-size-ML);
}

.caris-selection__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: var(--space-3);

  .caris-selection__description_table_row {
    border-bottom: 1px solid var(--color-alto-light);
    padding: var(--space-1);

    &:last-child {
      border-bottom: none;
    }
  }

  .caris-selection__description_table_cell {
    font-size: var(--regular-font-size);
    line-height: var(--regular-line-height);
    padding: var(--space-1);
  }
}

@media (max-width: #{$_widthXXLFrom}) {
  .caris-selection {
    --regular-font-size: var(--font-size-SM);
    --regular-line-height: var(--font-size-M);
    --headline-font-size: var(--font-size-M);
    --headline-line-height: var(--font-size-ML);
    --button-height: 36px;
    --button-font-size: var(--font-size-SM);
  }
}

@media #{$_mediaMDown} {
  .caris-selection {
    --regular-font-size: var(--font-size-S);
    --regular-line-height: var(--font-size-SM);
    --headline-font-size: var(--font-size-SM);
    --headline-line-height: var(--font-size-SM);
    --button-font-size: var(--font-size-M);
    --button-height: 40px;
  }

  .caris-selection__selection-name {
    width: calc(100% - 56px);
  }

  .caris-selection__select-new-car-button {
    width: 100%;
  }
}

:root[data-theme='tirendo'] {
  .caris-selection {
    &__headline,
    &__selection-name,
    &__description_link {
      color: var(--color-tirendo-blackout);
    }
  }
}
</style>
