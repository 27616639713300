import AjaxService from '../../../services/AjaxService';
import qs from 'qs';
import { getProductCategoryLevelTwo } from '../data-layer/actions';

const CART_SHARE_LINK_ENDPOINT_KEY = 'checkout.ajax.cart.share';
const CART_UPDATE_QUANTITY_ENDPOINT_KEY = 'checkout.ajax.cart.amount.modify';
const CART_ADD_ENDPOINT_KEY = 'checkout.ajax.cart.add';
const CART_ADD_DISCOUNT_ENDPOINT_KEY = 'checkout.ajax.cart.voucher.add';
const WISHLIST_ADD_ENDPOINT_KEY = 'checkout.ajax.cart.wishlist.add';

export const getShareLink = async ({ rootState, state }) => {
  const { fetchedUrls } = rootState.core;
  const params = new URLSearchParams();
  params.append('cartId', state.cartId);

  const result = await AjaxService.post(
    fetchedUrls[CART_SHARE_LINK_ENDPOINT_KEY],
    params
  );

  return { shareLink: result ? result.data.url : null };
};

const updateCartStore = ({ cart }, commit) => {
  if (cart) {
    commit('SET_CART', cart);
  }
};

export const addToCart = async (
  { commit, dispatch, state, rootState },
  { product, quantity, disableNotification, list, discountCode }
) => {
  const { fetchedUrls } = rootState.core;
  const cart = state;

  const payload = {
    productId: product.productId,
    quantity,
    carTypeNumber: product.carTypeNumber,
    sku: product.sku,
    cartId: cart.cartId,
    list: list ? list : getProductCategoryLevelTwo(product.sku),
  };

  const result = await AjaxService.post(
    fetchedUrls[CART_ADD_ENDPOINT_KEY],
    qs.stringify(payload)
  );

  if (result.data) {
    updateCartStore(result.data, commit);
    commit('wishlist/SET_HEADER_WISHLIST_DATA', result.data, { root: true });

    if (!disableNotification) {
      setTimeout(() => {
        dispatch(
          'header/showLatestAddedNotification',
          {
            productId: product.productId,
          },
          { root: true }
        );
      }, 500);
    }

    if (discountCode) {
      await dispatch('addDiscountCode', {
        discountCode,
      });
    }
  }
};

export const updateQuantity = async (
  { rootState, state, commit },
  { product, amount, listOfOrigin }
) => {
  const { fetchedUrls } = rootState.core;

  const payload = {
    cartId: state.cartId,
    productId: product.productId,
    sku: product.sku,
    carTypeNumber: product.carTypeNumber,
    amount,
  };

  if (listOfOrigin) {
    payload.list = listOfOrigin;
  }
  const result = await AjaxService.post(
    fetchedUrls[CART_UPDATE_QUANTITY_ENDPOINT_KEY].replace(
      '{cartId}',
      state.cartId
    ),
    qs.stringify(payload)
  );

  if (result.data) {
    updateCartStore(result.data, commit);
    commit('miniCart/SET_MINICART_TOTAL_PRODUCT_COUNT', result.data.cart.totalProductCount, {
      root:true
    });
  }
};

export const updateWishlist = async (
  { commit, state, rootState },
  { product }
) => {
  const { fetchedUrls } = rootState.core;
  const payload = {
    productId: product.productId,
    amount: !product.inWishlist ? product.quantityAmount : 0,
    carTypeNumber: product.carTypeNumber,
    sku: product.sku,
    cartId: state.cartId,
  };

  const result = await AjaxService.post(
    fetchedUrls[WISHLIST_ADD_ENDPOINT_KEY],
    qs.stringify(payload)
  );

  if (result.data) {
    updateCartStore(result.data, commit);
    commit('wishlist/SET_PRODUCT_COUNT', result.data.cart.wishListItemCount, {
      root: true,
    });
  }
};

export const addDiscountCode = async (
  { rootState, commit, state },
  { discountCode }
) => {
  const { fetchedUrls } = rootState.core;

  const payload = {
    cartId: state.cartId,
    discountCode,
    source: 'cart',
  };

  const result = await AjaxService.post(
    fetchedUrls[CART_ADD_DISCOUNT_ENDPOINT_KEY],
    qs.stringify(payload),
    { hideError: true } // [andrei] We display the error message under the input field
  );

  if (result && !result.messages.some(obj => obj.type === 'error')) {
    commit('checkout/UPDATE_CHECKOUT_CART', result.data.cart, { root:true });
    updateCartStore(result.data, commit);
    commit('header/SET_DISCOUNT', {
      show: true,
      fields: {
        headline: result.data?.cart?.discountPopup.header,
        ...(result.data?.cart?.discountPopup || result.data),
      },
    },
    {
      root: true
    });
  }
  return result;
};

export const updateInWishlistProp = async (
  { commit, state }, { product, vehicle }) => {
  const updatedState = { ...state };

  for (let i = 0; i < updatedState.data?.wishlist?.sections?.length; i++) {
    if (updatedState.sections[i].vehicle === vehicle) {
      let modifiedProduct = updatedState.sections[i].products.find(p => p.productId === product.productId);
      modifiedProduct.inWishlist = false;
      break;
    }
  }
  updateCartStore(updatedState, commit);
};

export const removeDiscount = async (
  { commit, state },
  { discount, source }) => {
  const endpoint = '/shop/cart/ajax/delete-voucher';

  const payload = {
    source,
    discountCode: discount.code,
    cartId: state.cartId
  };

  const result = await AjaxService.post(
    endpoint,
    qs.stringify(payload)
  );
  if (result && !result.messages.some(obj => obj.type === 'error')) {
    commit('checkout/UPDATE_CHECKOUT_CART', result.data.cart, { root:true });
    updateCartStore(result.data, commit);
  }
  return result;
};
