<i18n src="./translations"></i18n>

<template>
  <div v-if="isDisplayed" class="caris-information">
    <span class="caris-information__headline">
      {{ $t('caris-information.headline_1') }}
    </span>
    <span class="caris-information__headline">
      {{ $t('caris-information.headline_2') }}
    </span>
    <template v-if="showCustomerServiceEmail">
      <MaskedLink
        class="caris-information__email"
        :redirect-to="`mailto:${customerServiceEmail}`"
        :text="customerServiceEmail"
      />
    </template>
    <template v-else>
      <p class="caris-information__phone-number">
        {{ phoneNumber }}
      </p>
      <p class="caris-information__opening-hours">
        {{ openingHours }}
      </p>
    </template>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import { mapState } from 'vuex';

export default {
  name: 'CarisInformation',
  components: { MaskedLink },
  mixins: [globalMixin],
  props: {
    openingHours: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('core', {
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
      customerServiceEmail: ({ salesChannel, requestData: { companyId } }) =>
        companyId
          ? salesChannel.config.b2BCustomerServiceEmail
          : salesChannel.config.customerServiceEmail,
    }),
    showCustomerServiceEmail() {
      return this.salesChannelId === 'www-carpardoo-dk';
    },
    isDisplayed() {
      if (this.salesChannelId === 'www-carpardoo-fr') {
        return new Date() >= new Date(2023, 3, 10);
      }

      return this.salesChannelId !== 'www-carpardoo-nl';
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

.caris-information {
  height: 100%;
  width: max-content;
  display: flex;
  flex-direction: column;

  --headline-font-size: var(--font-size-M);
  --headline--line-height: var(--font-size-L);
  --phone-font-size: var(--font-size-ML);
  --phone-line-height: var(--font-size-L);
  --regular-font-size: var(--font-size-M);
  --regular-line-height: var(--font-size-ML);
}

.caris-information__headline {
  font-size: var(--font-size-MML);
  line-height: var(--headline-line-height);
  font-weight: bold;
  color: var(--color-white);
  text-transform: uppercase;

  @media #{$_mediaL} {
    word-break: break-word;
  }
}

.caris-information__headline + .caris-information__headline {
  margin-top: 8px;
}

.caris-information__phone-number {
  margin-top: var(--space-1);
  font-size: var(--phone-font-size);
  line-height: var(--phone-line-height);
  font-weight: bold;
  color: var(--color-secondary);
}

.caris-information__opening-hours {
  margin-top: var(--space-0--half);
  font-size: var(--regular-font-size);
  line-height: var(--regular-line-height);
  font-weight: bold;
  color: var(--color-white);
}

.caris-information__email {
  margin-top: var(--space-1);
  font-size: var(--phone-font-size);
  line-height: var(--phone-line-height);
  font-weight: bold;
  color: var(--color-secondary);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: #{$_widthXXLFrom}) {
  .caris-information {
    --headline-font-size: var(--font-size-SM);
    --headline--line-height: var(--font-size-ML);
    --phone-font-size: var(--font-size-M);
    --phone-line-height: var(--font-size-ML);
    --regular-font-size: var(--font-size-SM);
    --regular-line-height: var(--font-size-M);
  }
}

:root[data-theme='tirendo'] {
  .caris-information__headline,
  .caris-information__opening-hours {
    color: var(--color-tirendo-blackout);
  }

  .caris-information__phone-number {
    color: var(--color-primary);
  }
}
</style>
