import set from 'lodash/set';

const getDeliveryMethods = ({ countryCode }) => {
  const methods = ['dhl', 'dpd'];

  if (countryCode === 'DK') {
    methods.push('postnord');
  } else if (countryCode === 'DE') {
    methods.push('go');
    methods.push('rieck');
  }

  return methods;
};

const getFreeDeliveryMin = ({ salesChannelId }) => {
  let freeDeliveryMin = 120;

  if (salesChannelId === 'www-carpardoo-dk') {
    freeDeliveryMin = 949;
  } else if (salesChannelId === 'sis_tirendo-delticom-de') {
    freeDeliveryMin = 50;
  }

  return freeDeliveryMin;
};

const getCurrency = ({ countryCode }) => {
  let currency = '€';

  if (countryCode === 'DK') {
    currency = 'krr';
  }

  return currency;
};

const getPaymentMethods = ({ availablePaymentMethods }) =>
  availablePaymentMethods;

const getAllBannersAndAssignPositions = (content) => {
  if (!content || content.component !== 'components') {
    return [];
  }

  const banners = [];

  const primaryPlaceholder = (content.data.content || []).find(
    (subPlaceholder) =>
      subPlaceholder.component === 'cms-primary-secondary-banners'
  );

  if (primaryPlaceholder) {
    set(
      primaryPlaceholder,
      'data.primaryImage.position',
      'homepage_banner-primary'
    );
    banners.push(primaryPlaceholder.data.primaryImage);

    set(
      primaryPlaceholder,
      'data.secondaryImage.position',
      'homepage_banner-secondary'
    );
    banners.push(primaryPlaceholder.data.secondaryImage);
  }

  (content.data.content || [])
    .filter((subPlaceholder) => subPlaceholder.component === 'cms-banners')
    .forEach((subPlaceholder, placeholderIndex) => {
      if (!subPlaceholder.data) {
        return;
      }

      (subPlaceholder.data.banners || []).forEach((banner, bannerIndex) => {
        banner.position = `cms-banner-row-${placeholderIndex + 1}-item-${bannerIndex + 1
          }`;
        banners.push(banner);
      });
    });

  return banners;
};

export const setCoreState = (state) => {
  const { salesChannel, placeholders } = state;

  const {
    countryCode,
    salesChannelId,
    config: { availablePaymentMethods = [] },
  } = salesChannel;

  return {
    ...state,
    viewport: { name: null, range: null },
    deliveryMethods: getDeliveryMethods({ countryCode }),
    freeDeliveryMin: getFreeDeliveryMin({ salesChannelId }),
    currency: getCurrency({ countryCode }),
    paymentMethods: getPaymentMethods({ availablePaymentMethods }),
    allBanners: getAllBannersAndAssignPositions(placeholders.content),
  };
};
