<template>
  <InputBase
    :id="vars.id"
    ref="inputBase"
    :modifier="modifier"
    :modifiers="[
      ...modifiers,
      vars.submitted && vars.valid === false && 'error',
    ]"
    :type="type"
    :label="vars.label"
    :value="vars.value"
    :full-name="vars.full_name || vars.fullName"
    :send="form.send"
    :disabled="vars.disabled"
    :readonly="vars.readonly"
    :required="vars.required"
    :size="vars.size"
    :attr="vars.attr"
    :hide-optional="vars.hideOptional"
    :icon1="!!icon1"
    :errors="vars.errors"
    :auto-suggest-enabled="
      autoSuggest ? autoSuggest.key === vars.full_name : false
    "
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @iconClick="$emit('icon-click', $event)"
  >
    <template v-slot:icon1>
      <button
        v-if="icon1"
        :class="icon1.classes"
        :title="icon1.title"
        @click="$emit('icon-click', $event)"
      >
        <Icon :name="icon1.icon" width="20" height="20" />
      </button>
    </template>
    <template v-slot:autoSuggestDialog>
      <AutoSuggestDropdown
        :auto-suggest="autoSuggest"
        data-testid="auto-suggest-dropdown"
        @click="
          (suggestion) =>
            $emit('suggestion-selected', suggestion, autoSuggest.key)
        "
      />
    </template>
  </InputBase>
</template>

<script>
import Icon from 'Components/00-generated/Icon.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import InputBase from './InputBase.vue';
import AutoSuggestDropdown from 'Src/components/01-atoms/autosuggest-dropdown/AutoSuggestDropdown.vue';
import { formMixin } from 'Libs/mixins/formMixin';

export default {
  name: 'InputWrapper',
  components: { InputBase, Icon, AutoSuggestDropdown },
  mixins: [globalMixin, formMixin],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    icon1: {
      type: Object,
      default: null,
    },
    autoSuggest: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
