import { miniCartState } from './state';
import * as mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

export default (placeholder) => ({
  state: miniCartState(placeholder),
  getters,
  mutations,
  actions,
  namespaced: true,
});
