<i18n src="./translations"></i18n>

<template>
  <section class="caris-vehicle-selector">
    <CarisLicenseNewHeader
      v-if="isNewHeaderAndLicensePlateSearch"
      ref="license-plate"
      class="caris-vehicle-selector__new-header"
      :parent-form="parentForm"
      :action="false"
    />
    <CarisNatCodeHeader
      v-if="isNewHeaderAndNatCodeSearch"
      ref="natCodePlateSearch"
      :parent-form="parentForm"
      :action="false"
      @select="handleNatCodeSubmit"
    />
    <CarisHsnTsn
      v-if="!isNewHeaderAndLicensePlateSearch && !isNewHeaderAndNatCodeSearch"
      ref="hsn-tsn"
      class="caris-vehicle-selector__hsn-tsn"
      :action="false"
      :title="$t('caris-vehicle-selector.hsn-tsn.title')"
      :parent-form="parentForm"
      :auto-focus="!(parentSelections && parentSelections.manufacturer)"
      :qa-class-name-prefix="'qa-carisDialog'"
      :gtm-class-name="'gtm--carisDialog-'"
      @select="handleHsnTsnSubmit"
      @where-to-find-dialog-show="handleWhereToFindDialogShow"
      @where-to-find-dialog-hide="handleWhereToFindDialogHide"
    />
    <CarisSelectionForm
      class="caris-vehicle-selector__selection-form"
      :selections="parentSelections"
      :manufacturers="manufacturers"
      :models="models"
      :types="types"
      :title="$t('caris-vehicle-selector.selection_form.title')"
      :qa-class-name-prefix="'qa-carisDialog'"
      :gtm-class-name="'gtm--carisDialog-'"
      @change="handleVehicleSelectionChange"
    />
    <CarisTypeMineSearch
      v-if="countryCode === 'FR'"
      ref="mine-search"
      class="caris-vehicle-selector__mine-search"
    />
    <LoadingSpinner
      v-if="isFetchingSelectableVehicles"
      class="caris-vehicle-selector__loading"
      modifier="greyTrace"
      :dimensions="{ width: '40', height: '40' }"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';

import carisSelectionFormMixin from 'Libs/mixins/carisSelectionFormMixin';

import CarisHsnTsn from 'Components/02-molecules/caris-hsn-tsn/CarisHsnTsn';
import CarisSelectionForm from 'Components/02-molecules/caris-selection-form/CarisSelectionForm';
import CarisTypeMineSearch from 'Components/03-organisms/caris-type-mine-search/CarisTypeMineSearch.vue';
import CarisLicenseNewHeader from 'Components/02-molecules/caris-license-new-header/CarisLicenseNewHeader.vue';
import LoadingSpinner from 'Components/01-atoms/loading-spinner/LoadingSpinner';
import CarisNatCodeHeader from 'Components/03-organisms/caris-nat-code-header/CarisNatCodeHeader';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CarisVehicleSelector',
  components: {
    CarisHsnTsn,
    CarisSelectionForm,
    CarisTypeMineSearch,
    CarisLicenseNewHeader,
    LoadingSpinner,
    CarisNatCodeHeader,
  },
  mixins: [globalMixin, carisSelectionFormMixin],
  props: {
    parentForm: {
      type: Object,
      default: () => ({
        hsnValue: null,
        tsnValue: null,
      }),
    },
    parentSelections: {
      type: Object,
      default: () => ({
        manufacturer: null,
        model: null,
        type: null,
      }),
    },
  },
  data() {
    return {
      isFetchingSelectableVehicles: false,
    };
  },
  computed: {
    ...mapGetters('core', {
      config: 'getSalesChannelConfig',
    }),
    ...mapState('carSelection', {
      fetchClusters: ({ data }) => !!data.carDisplayName,
    }),
    ...mapState('core', {
      countryCode({ salesChannel }) {
        return salesChannel.countryCode;
      },
    }),
    isNewHeaderAndLicensePlateSearch() {
      return this.config.carisLicenseSearch;
    },
    isNewHeaderAndNatCodeSearch() {
      return this.config.carisNatCodeSearch;
    },
  },
  methods: {
    ...mapMutations('carSelection', {
      resetSelectableVehicles: 'CARIS_NEW_VEHICLE__RESET_SELECTABLE_VEHICLES',
    }),
    ...mapActions('carSelection', [
      'doHsnTsnSearch',
      'doFindCarWithKtypnr',
      'doNatRequest',
    ]),
    ...mapActions('messages', ['addMessage']),
    handleHsnTsnSubmit() {
      this.isFetchingSelectableVehicles = true;
      this.resetSelectableVehicles();

      const { hsnValue: hsn, tsnValue: tsn } = this.parentForm;

      this.doHsnTsnSearch({
        hsn,
        tsn,
        callbacks: {
          onsingle: async (ktypnr) => {
            await this.doFindCarWithKtypnr({
              ktypnr,
              eventName: 'keyno',
            });
            this.isFetchingSelectableVehicles = false;
            this.$emit('vehicle-select');
          },
          onmultiple: async (cars) => {
            const promises = Object.keys(cars).map((ktypnr) =>
              this.doFindCarWithKtypnr({
                ktypnr,
                eventName: 'keyno',
              })
            );
            await Promise.all(promises);
            this.isFetchingSelectableVehicles = false;
            this.$emit('vehicle-select');
          },
          onerror: (message) => {
            this.addMessage({
              type: 'error',
              text: message,
            });
            this.isFetchingSelectableVehicles = false;
          },
        },
      });
    },
    handleNatCodeSubmit() {
      this.isFetchingSelectableVehicles = true;
      this.resetSelectableVehicles();

      const { natCodeValue } = this.parentForm;

      this.doNatRequest({
        nat: natCodeValue,
        refs: this.$refs.natCodePlateSearch.$refs,
        callbacks: {
          onsingle: async (ktypnr) => {
            await this.doFindCarWithKtypnr({
              ktypnr,
              eventName: 'keyno',
            });
            this.isFetchingSelectableVehicles = false;
            this.$emit('vehicle-select');
          },
          onmultiple: async (cars) => {
            const promises = Object.keys(cars).map((ktypnr) =>
              this.doFindCarWithKtypnr({
                ktypnr,
                eventName: 'keyno',
              })
            );
            await Promise.all(promises);
            this.isFetchingSelectableVehicles = false;
            this.$emit('vehicle-select');
          },
          onerror: (message) => {
            this.addMessage({
              type: 'error',
              text: message,
            });
            this.isFetchingSelectableVehicles = false;
          },
        },
      });
    },
    async handleTypeChange() {
      this.isFetchingSelectableVehicles = true;
      this.resetSelectableVehicles();

      const {
        type: { value: typeKtyPnr },
      } = this.parentSelections;

      await this.doFindCarWithKtypnr({
        ktypnr: typeKtyPnr,
      });
      this.isFetchingSelectableVehicles = false;
      this.$emit('vehicle-select');
    },
    handleWhereToFindDialogHide() {
      this.isBackground = false;
    },
    handleWhereToFindDialogShow() {
      this.isBackground = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.caris-vehicle-selector {
  --input-width: 160px;
  --select-width: 220px;
  --select-margin-right: #{var(--space-2)};

  ::v-deep {
    .caris-license-new-header_button-wrapper {
      display: none;
    }

    .input--error .error {
      width: 400px;
      left: -19px;
      bottom: -21px;
    }

    div.label,
    .caris-license-new-header_label {
      color: var(--color-nero);
      font-size: var(--font-size-MML);
      font-weight: 700;
      letter-spacing: -0.16px;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0;
      margin-bottom: var(--space-1);
    }

    .carisNatCodeHeader {
      .field.field--full {
        width: var(--select-width);

        @media #{$_mediaMDown} {
          width: 100%;
        }
      }

      .where-to-find-button {
        display: none;
      }
    }
  }
}

.caris-vehicle-selector__hsn-tsn {
  ::v-deep {
    .caris-hsn-tsn__headline {
      color: var(--color-nero);
      font-size: var(--font-size-MML);
      font-weight: bold;
    }

    .caris-hsn-tsn__form {
      flex-wrap: nowrap;
      max-width: unset;
      align-items: flex-end;

      .form__field {
        width: var(--input-width);

        label {
          max-width: 145px;
        }

        input {
          width: var(--input-width);
          border: 1px solid var(--color-alto);
          border-radius: var(--border-radius-default);
        }
      }

      .form__field:first-child {
        margin-right: 15px;
      }

      .form__field:nth-child(2) {
        margin-right: 10px;
      }

      .form__where-to-find-button {
        color: var(--color-nero);
        height: min-content;
      }
    }
  }
}

.caris-vehicle-selector__selection-form.caris-selection-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: var(--space-0--half);
  padding: var(--space-3) 0;

  ::v-deep {
    .caris-selection-form__headline {
      color: var(--color-nero);
      font-size: var(--font-size-MML);
      flex-basis: 100%;
    }

    .caris-selection-form__select {
      width: var(--select-width);
      border: 1px solid var(--color-alto);
      border-radius: var(--border-radius-default);

      &:not(:last-of-type) {
        margin-right: var(--select-margin-right);
      }
    }
  }
}

.caris-vehicle-selector__loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.12);
  z-index: 500;
}

@media #{$_mediaMDown} {
  .caris-vehicle-selector {
    --input-width: 100%;
    --select-width: 100%;
    --select-margin-right: 0;
  }

  .caris-vehicle-selector__hsn-tsn {
    ::v-deep {
      .caris-hsn-tsn__form {
        flex-wrap: wrap;
      }
    }
  }
}

.caris-vehicle-selector__new-header ::v-deep {
  .caris-license-new-header_eu-icon {
    width: 11px;
    height: 9px;
  }

  @media #{$_mediaMDown} {
    width: 100%;
  }

  .label {
    color: var(--color-nero) !important;
  }

  .form__select-car-button ::v-deep {
    display: flex !important;
    align-items: center !important;
  }

  .caris-license-new-header_license-plate input[type='text'],
  .caris-license-new-header_license-plate-EU {
    height: 32px;

    @media #{$_mediaMDown} {
      height: 40px;
    }
  }

  .caris-license-new-header_license-plate {
    width: 200px;

    @media #{$_mediaMDown} {
      width: 100%;

      .inputWrapper {
        padding: 0 var(--space-3) 0 0;
      }
    }
  }
}

.caris-vehicle-selector__mine-search ::v-deep {
  margin: 0 var(--space-0--half);

  .divider,
  .helpBttn,
  .buttonWrapper {
    display: none;
  }

  .input.input--label {
    padding-top: 0;
  }

  .inputEl {
    height: 32px;

    @media #{$_mediaMDown} {
      height: 40px;
    }
  }

  .fields {
    padding: 0;

    @media #{$_mediaMDown} {
      display: block;
    }
  }

  .field.field--full {
    width: var(--select-width);
    padding: 0;

    @media #{$_mediaMDown} {
      width: 100%;
    }
  }

  & + .label {
    width: 100%;
    margin: 0 var(--space-0--half) var(--space-1);
    font-size: var(--font-size-MML);
    font-weight: 700;
    color: var(--color-nero);
    letter-spacing: -0.16px;
    text-align: left;
  }

  .mine-type ::v-deep input[type='text'] {
    height: 40px;
  }

  .find-button {
    width: 25%;
  }

  .caris_type_mine_search__where-to-find-button {
    display: flex;
    align-items: flex-end;
    padding: 0 var(--space-1--half);
  }

  .where-to-find-button {
    font-size: var(--font-size-SM);
    text-decoration: underline;
    color: var(--color-nero);
    margin-right: auto;
    cursor: pointer;
    padding-left: 0;
  }

  @media #{$_mediaMDown} {
    .field {
      &--full {
        width: 100%;
      }
    }

    .where-to-find-button {
      color: var(--color-white);
    }

    .buttonWrapper {
      margin: var(--space-2--half) 0;
      min-height: 40px;
    }

    .find-button {
      width: 50%;
    }
  }
}

@media (max-width: 300px) {
  .caris-vehicle-selector {
    ::v-deep {
      .caris-hsn-tsn__form.form {
        .form__field {
          flex-basis: 100%;
        }
      }
    }
  }
}
</style>
