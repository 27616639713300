var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"filterRow",class:[_vm.classes, 'qa-catalog-category--filter'],attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{key:'brand_select',staticClass:"section grid"},[_c('div',{staticClass:"brand-row"},[(_vm.brandsAvailable)?_c('BrandFilterContainer',{key:'brand',staticClass:"section__filter-row",attrs:{"name":_vm.allFilters['brand'].name,"title":_vm.allFilters['brand'].title,"options":_vm.allFilters['brand'].options,"value":_vm.activeFilters['brand']},on:{"input":(value) => _vm.handleSetFilter('brand', value)}}):_vm._e()],1)]),_vm._v(" "),_c('div',{class:[
        'section grid dropdown-filters',
        {
          'display-top':
            !_vm.hasPrimaryFilters &&
            !_vm.hasActiveFiltersBesidesBrandAndSorting &&
            _vm.showCount === 0,
        },
      ]},[_vm._l((_vm.computedVisibleSections),function(section){return [_vm._l((_vm.getSectionFilters(section)),function(filter){return [(filter !== 'sorting' && filter !== 'brand')?_c('Dropdown',{key:filter,staticClass:"section__filter-row item filter-dropdown",attrs:{"form":{
              vars: {
                label: _vm.allFilters[filter].title,
                innerLabel: _vm.allFilters[filter].title,
                full_name: _vm.allFilters[filter].name,
                type: 'choice',
                required: true,
                choices: _vm.filterChoices(filter),
                value: _vm.filterSelected(filter),
              },
            }},on:{"input":(value) => _vm.handleSetFilter(filter, [value])}}):_vm._e()]})]}),_vm._v(" "),(
          _vm.shouldShowMoreLessButtons(true) ||
          _vm.shouldShowMoreLessButtons(false) ||
          _vm.shouldShowHideAllButton()
        )?_c('div',{staticClass:"showMore-button",class:[{ 'no-margin': _vm.noMargin && _vm.showCount > 0 }]},[_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowMoreLessButtons(true)),expression:"shouldShowMoreLessButtons(true)"}],staticClass:"handle-filters show-more",attrs:{"icon-width":13,"icon-height":13,"has-focus-border":false,"title":_vm.isMobile
              ? _vm.$t('catalog-category-filters.show-4-more')
              : _vm.$t('catalog-category-filters.show-more'),"on-click":_vm.showMore}}),_vm._v(" "),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowMoreLessButtons(false)),expression:"shouldShowMoreLessButtons(false)"}],staticClass:"handle-filters show-less",attrs:{"icon-width":13,"icon-height":13,"has-focus-border":false,"title":_vm.$t('catalog-category-filters.show-less'),"on-click":_vm.showLess}}),_vm._v(" "),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowHideAllButton()),expression:"shouldShowHideAllButton()"}],staticClass:"handle-filters hide-all",attrs:{"icon-width":13,"icon-height":13,"has-focus-border":false,"title":_vm.$t('catalog-category-filters.show-top-filters'),"on-click":_vm.hideAll}})],1):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"catalog-category-filters__bottom-container"},[_c('div',{staticClass:"catalog-category-filters__availability-wrapper"},[(_vm.shouldShowAvailabilityFilter)?_c('CheckboxBase',{staticClass:"catalog-category-filters__availability",attrs:{"label":_vm.$t('catalog-category-filters.availability-instant'),"modifiers":['theme-spacing'],"value":_vm.availabilityFilterClicked
              ? !_vm.instantAvailableActiveFilter
              : _vm.instantAvailableActiveFilter,"hide-optional":true},on:{"input":(value) => _vm.handleSetFilter('availability', value)}}):_vm._e(),_vm._v(" "),(_vm.isPromiseBannerVisible && !_vm.isBusinessCustomer)?_c('span',{staticClass:"catalog-category-filters__shipment-promise"},[_vm._v("\n          "+_vm._s(_vm.$t('catalog-category-filters.shipment-promise'))+"\n        ")]):_vm._e()],1),_vm._v(" "),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowRemoveAllFilterButton()),expression:"shouldShowRemoveAllFilterButton()"}],class:[
          'remove-filters item',
          { 'no-margin': _vm.noMargin && _vm.showCount > 0 },
        ],attrs:{"icon":"delete","icon-width":13,"icon-height":13,"has-focus-border":false,"title":_vm.$t('catalog-category-filters.remove-all-filters'),"on-click":_vm.removeFilters}})],1),_vm._v(" "),_c('div',{staticClass:"filters-bottom-section"},[(!_vm.isEmpty(_vm.brandActiveFilters))?_c('div',{staticClass:"activeSection"},[_c('span',{staticClass:"section__header titleText"},[_vm._v("\n          "+_vm._s(_vm.$t('catalog-category-filters.only-parts-of'))+":\n        ")]),_vm._v(" "),_c('ActiveBrandFilter',{attrs:{"active-filters":_vm.brandActiveFilters,"filters":_vm.allFilters},on:{"click":_vm.handleRemoveFilter}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"section__filter-row sorting item"},[_c('SortingCategoriesDropdown',{attrs:{"sorting":_vm.allFilters.sorting,"active-sorting":_vm.activeSorting},on:{"input":(value) => _vm.handleSetFilter('sorting', value)}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }