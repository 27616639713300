<i18n src="./translations"></i18n>

<template>
  <div
    :class="[classes, { 'product-tile--readonly': readonly }]"
    :data-preload="[$options.name]"
  >
    <div class="contentContainer">
      <div class="content">
        <div v-if="readonly" class="product-tile__image-container">
          <AdvancedImage
            v-if="product.image"
            class="product-tile__product-image"
            :src="product.image"
            :alt="product.image.alt || ''"
            :config="{ q: 80, w: 86 }"
          />
        </div>
        <div class="text">
          <div :class="['brand', 'qa-ProductTile__brand']">
            <MaskedLink
              :redirect-to="product.productUrl"
              class="qa-ProductTile__tracking"
              :text="product.brand"
            />
          </div>

          <div class="title qa-ProductTile__title">
            <MaskedLink
              :redirect-to="product.productUrl"
              class="qa-ProductTile__tracking"
              :text="product.name"
            />
          </div>

          <div class="article">
            {{ $t('product-tile.sku') }} {{ product.displaySku }}
          </div>
          <div v-if="!readonly" class="availability">
            <Availability
              :availability-key="availabilityKey"
              :is-new-availability="isNewAvailability"
              :label="
                product && isNewAvailability
                  ? ''
                  : product.availability && product.availability.label
              "
              class="qa-ProductTile__availabilty"
            />
          </div>
        </div>
      </div>
      <div v-if="!readonly" class="numbers">
        <div class="quantity qa-ProductTile__quantity">
          <QuantitySelector
            v-if="!fixedQuantity"
            :label="$t('product-tile.quantity')"
            :value="product.quantityAmount"
            :disabled="readonly"
            :product="product"
            :choices="product.quantityOptions"
            :threshold="product.quantityThreshold"
            is-minicart
            @onQuantityChange="handleQuantityChange($event)"
          >
            <div class="total">
              <template v-if="product.isDiscountCodeApplied">
                <template v-for="(price, key) in selectedDiscountPrices">
                  <Price
                    v-if="
                      price.price >= 0 || (price.price >= 0 && price.discounted)
                    "
                    :key="key"
                    :label="removeNetLabel(selectedDiscountPrices[key].label)"
                    :value="normalizePrice(price.price)"
                    :is-kvi="product.isKvi"
                    class="price"
                    :data-testid="
                      key === 1 && !isBusinessPlusCustomer
                        ? 'discounted-price'
                        : undefined
                    "
                    :class="{
                      'price--bold price--large': key === 0,
                      'price--strikethrough':
                        key === 1 && !isBusinessPlusCustomer,
                    }"
                  />
                </template>
              </template>
              <template v-else>
                <Price
                  v-for="(price, key) in selectedPrices"
                  :key="key"
                  :label="removeNetLabel(selectedPrices[key].label)"
                  :value="normalizePrice(price.price)"
                  :is-kvi="product.isKvi"
                  class="price"
                  :class="{ 'price--bold price--large': key === 0 }"
                />
              </template>
            </div>
          </QuantitySelector>
          <div v-else class="fixedQuantity">
            {{ $t('product-tile.quantity') }}:
            {{ product.quantityAmount }}
          </div>
        </div>
      </div>
    </div>

    <TileActions
      :actions-list="actions"
      :delete-product="deleteProduct"
      :product-id="product.productId"
      @action="({ type }) => $emit('action', { type, product })"
    />
  </div>
</template>

<script>
import noop from 'lodash/noop';
import Availability from 'Components/01-atoms/availability/Availability';
import QuantitySelector from 'Components/01-atoms/quantity-selector/QuantitySelector';
import Price from 'Components/01-atoms/price/Price';
import TileActions from 'Components/02-molecules/tile-actions/TileActions';
import globalMixin from 'Libs/mixins/globalMixin';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage.vue';
import { mapGetters, mapState } from 'vuex';
import { isNumber, isString } from 'lodash';

export default {
  name: 'ProductTile',
  components: {
    Availability,
    QuantitySelector,
    Price,
    TileActions,
    MaskedLink,
    AdvancedImage,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    actions: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    deleteProduct: {
      type: Function,
      default: noop,
    },
    fixedQuantity: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('core', ['isBusinessPlusCustomer']),
    ...mapState('core', {
      currency: (state) => state.currency,
      isDirectDelivery: ({ requestData }) =>
        requestData.isDirectDelivery ?? false,
    }),
    isNewAvailability() {
      if (this.product && this.product.availability) {
        const { availability } = this.product;
        return (
          typeof availability === 'string' || availability instanceof String
        );
      }
      return false;
    },
    availabilityKey() {
      if (this.isNewAvailability) {
        return this.product.availability;
      }
      return this.product.availability ? this.product.availability.key : '';
    },
    selectedDiscountPrices() {
      /**
       * Returns the list of discount prices for the product based on the customer type.
       * For business customers, it excludes the first price (Bruto) but the deposits value in totalPrices remains.
       */
      const discountPrices = this.product.discountCode.totalPrices;
      const totalPrices = this.product.totalPrices;

      const hasDiscountPrices = discountPrices.length > 1;
      const hasTotalPrices = totalPrices.length > 1;
      if (this.isBusinessPlusCustomer) {
        let showDiscountPrices = hasDiscountPrices
          ? discountPrices.slice(1, 2).filter(({ price }) => !!price)
          : [];
        let showTotalPrices = hasTotalPrices
          ? totalPrices.slice(2).filter(({ price }) => !!price)
          : [];

        if (this.isDirectDelivery) {
          showDiscountPrices =
            showDiscountPrices.length > 0
              ? showDiscountPrices.filter(
                  ({ label }) =>
                    label !== 'Gefahrgutzuschlag' &&
                    label !== 'Sperrgutzuschlag'
                )
              : showDiscountPrices;
          showTotalPrices =
            showTotalPrices.length > 0
              ? showTotalPrices.filter(
                  ({ label }) =>
                    label !== 'Gefahrgutzuschlag' &&
                    label !== 'Sperrgutzuschlag'
                )
              : showTotalPrices;
        }

        return [...showDiscountPrices, ...showTotalPrices];
      }
      // For non-business customers, show the  strike-through price and exclude "Netto"
      const showDiscountPrices = hasDiscountPrices
        ? discountPrices.filter((price) => price.label !== 'Netto').slice(0, 3)
        : [];
      const showTotalPrices = hasTotalPrices
        ? totalPrices.slice(2).filter(({ price }) => !!price)
        : [];
      return [...showDiscountPrices, ...showTotalPrices];
    },
    selectedPrices() {
      /**
       * Returns the list of regular prices for the product based on the customer type.
       * For B+ customers, it excludes the first price (Bruto) and displays only the Net price (without Net label)
       */
      if (this.isBusinessPlusCustomer) {
        let refinedPrices =
          this.product.totalPrices.length > 1
            ? this.product.totalPrices.slice(1).filter(({ price }) => !!price)
            : [];

        if (this.isDirectDelivery && refinedPrices.length > 0) {
          refinedPrices = refinedPrices.filter(
            ({ label }) =>
              label !== 'Gefahrgutzuschlag' && label !== 'Sperrgutzuschlag'
          );
        }

        return refinedPrices;
      }
      return this.product.totalPrices.filter(
        ({ price, label }) =>
          !!price && !['Netto', 'net', 'rapporter'].includes(label)
      );
    },
    selectedDiscountLabels() {
      /**
       * Computes the list of labels for discount prices, with special handling for business customers.
       * For B+ customers, it excludes the first price (Bruto) and displays only the Net price (without Net label)
       */
      const prices = this.selectedDiscountPrices;
      return prices.map((price, index) =>
        this.isBusinessPlusCustomer && index === 0 ? '' : price.label
      );
    },
  },
  methods: {
    handleQuantityChange({ quantity }) {
      this.$emit('onQuantityChange', quantity);
    },
    normalizePrice(price) {
      let number = null;
      if (isNumber(price)) {
        number = price;
      }

      if (isString(price)) {
        if (price.indexOf(',') !== -1) {
          number = Number.parseFloat(
            price
              .split(' ')[0]
              .replace('.', '')
              .replace(',', '.')
              .replace('€', '')
          );
        } else {
          number = Number.parseFloat(price.split(' ')[0].replace('€', ''));
        }
      }

      return number !== null
        ? number.toFixed(2).replace('.', ',') + ' ' + this.currency
        : '';
    },
    removeNetLabel(label) {
      // Net label should never appear, even for B+ customers.
      if (!label) return '';

      return ['Netto', 'net', 'rapporter'].includes(label) ? '' : label;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.productTile {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  overflow: hidden;
  border-bottom: 1px solid var(--color-black--decorative);
}

.product-tile--readonly {
  border: none;
}

.product-tile__image-container {
  padding-right: var(--space-1--half);
}

.product-tile__product-image {
  width: 86px;
  height: 86px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 999;
  padding: var(--space-2);
  min-width: 0;
}

.unit {
  opacity: 0;
  position: absolute;
  top: -#{var(--space-2)};
  right: 16px;
  transform: translate(0, -100%);

  transition: opacity var(--time-S) ease-in-out;
}

.text {
  flex-grow: 1;
  order: 0;
  overflow: hidden;
  flex-basis: 100%;
  color: var(--color-mine-shaft);
}

.brand {
  margin-bottom: 4px;

  font-weight: bold;
  color: var(--color-mine-shaft);
  font-size: var(--font-size-S);
  line-height: 18px;
}

.title {
  margin-bottom: 4px;

  font-weight: bold;
  color: var(--color-mine-shaft);
  font-size: var(--font-size-M);
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.article {
  margin-bottom: 4px;
  font-size: var(--font-size-S);
  line-height: 20px;
  color: var(--color-mine-shaft);
}

.check {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  font-size: var(--font-size-S);
  line-height: 14px;

  color: var(--color-primary);

  cursor: pointer;
}

.checkIcon {
  position: relative;
  left: -3px;
  width: 16px;

  fill: var(--color-primary);
}

.fixedQuantity {
  color: var(--color-mine-shaft);
  font-size: var(--font-size-S);
  align-self: flex-end;
  margin-bottom: var(--space-0--half);
}

.numbers {
  flex-grow: 1;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--space-2) var(--space-2);
}

.price {
  padding-bottom: var(--space-0--half);
  font-size: var(--font-size-S);

  &--large {
    padding-bottom: var(--space-1);
    font-size: var(--font-size-M);
  }

  &--bold {
    font-weight: bold;
  }

  &--strikethrough {
    text-decoration: line-through;
  }
}

@media #{$_mediaLUp},
  print {
  .productTile {
    .numbers {
      flex-direction: row;
      align-items: flex-start;
    }

    .quantity {
      padding: 0;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media #{$_mediaMUp} {
  .productTile {
    flex-direction: row;

    .content {
      flex-direction: row;
    }

    .text {
      order: 1;
    }

    .numbers {
      flex-direction: column;
      align-items: stretch;
      padding: var(--space-2);
    }

    .unit {
      display: block;
      opacity: 1;
      position: static;
      transform: none;
    }

    .quantity {
      padding: 0;
      flex-grow: 0;
    }

    .total {
      width: 120px;
    }

    .check {
      font-size: var(--font-size-SM);
      line-height: 18px;
    }

    .checkIcon {
      width: 18px;
    }
  }

  .product-tile--readonly {
    .content {
      align-items: center;
    }
  }
}

@media screen {
  .productTile {
    .contentContainer {
      flex-grow: 1;
    }

    .brand {
      font-weight: normal;
    }
  }
}

@media print {
  .productTile {
    page-break-inside: avoid;
  }

  .details {
    display: none;
  }
}
</style>
