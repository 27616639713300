import has from 'lodash/has';
import { axios } from 'Services/AjaxService';
import UrlHelper from '../../../libs/helpers/url';
import TrackingHandler from '../../../libs/helpers/tracking';
import StringHelper from '../../../libs/helpers/string';
import LocalStorage from '../../../libs/helpers/local-storage';
import { calculateCarSeries } from '../data-layer/actions';

const ls = new LocalStorage();

export const toggleExpanded = ({ commit }) => {
  commit('CARIS_WIDGET__TOGGLE');
};

export const showCaris = ({ commit }) => {
  commit('CARIS_WIDGET__OPEN');
};

export const setAutoExpand = ({ commit }, value) => {
  commit('CARIS_WIDGET__SET_AUTOEXPAND', value);
};

export const toggleFilters = async ({ commit }, { step }) => {
  commit('CARIS_FILTER_TRIGGER__SELECT');

  if (step) {
    commit('CARIS_FILTER_SELECT__STEP', { filterStep: step });
  }
};

const romanize = (num) => {
  num = parseInt(num, 10);

  const LOOKUP = [
    { roman: 'M', decimal: 1000 },
    { roman: 'CM', decimal: 900 },
    { roman: 'D', decimal: 500 },
    { roman: 'CD', decimal: 400 },
    { roman: 'C', decimal: 100 },
    { roman: 'XC', decimal: 90 },
    { roman: 'L', decimal: 50 },
    { roman: 'XL', decimal: 40 },
    { roman: 'X', decimal: 10 },
    { roman: 'IX', decimal: 9 },
    { roman: 'V', decimal: 5 },
    { roman: 'IV', decimal: 4 },
    { roman: 'I', decimal: 1 },
  ];

  let roman = '';

  if (!isNaN(num)) {
    for (const i in LOOKUP) {
      if (Object.prototype.hasOwnProperty.call(LOOKUP, i)) {
        while (num >= LOOKUP[i].decimal) {
          roman += LOOKUP[i].roman;
          num -= LOOKUP[i].decimal;
        }
      }
    }
  }

  return roman;
};

export const filterClusters = async ({ commit }, { clusters, step, query }) => {
  const value = query.toLowerCase().trim();
  const synonym = value.replace(/\d/gi, romanize).toLowerCase().trim();

  const filteredClusters = clusters.map((item) => {
    item.options.map((option) => {
      const labelArray = [
        ...(option.label ? [option.label] : []),
        ...(option.labels ? option.labels : []),
      ];

      const length = step === 1 ? labelArray.length : labelArray.length - 1;
      const label = [...labelArray].splice(0, length).join('');
      option.hidden =
        !label.toLowerCase().trim().includes(value) &&
        !label.toLowerCase().trim().includes(synonym);
    });

    let isEmptyGroup = 0;
    item.options.map((option) => {
      isEmptyGroup += ~~option.hidden;
    });

    item.hidden = isEmptyGroup === item.options.length;

    return item;
  });

  let noResults = 0;
  filteredClusters.forEach((item) => {
    noResults += ~~item.hidden;
  });
  noResults = noResults === filteredClusters.length;

  const FILTER_TYPE = {
    1: 'CARIS_CARIS_BAR__LOAD_MANUFACTURERS',
    2: 'CARIS_CARIS_BAR__LOAD_MODELS',
    3: 'CARIS_CARIS_BAR__LOAD_TYPES',
  };

  commit(FILTER_TYPE[step], { clusters: filteredClusters, noResults });
};

export const loadCarisData = async (
  {
    commit,
    state,
    rootState: {
      core: { fetchedUrls },
    },
  },
  { step, option }
) => {
  let id, label, value;

  if (option) {
    id = option && option.value;
    const labelArray = [
      ...(option.label ? [option.label] : []),
      ...(option.labels ? option.labels : []),
    ];
    label = StringHelper.removeAllTags(labelArray.join(' '));

    value = StringHelper.removeAllTags(labelArray[0]);
    value = StringHelper.removeDoubleSpaces(value);
  }

  const shouldFetchManufacturer = state.manufacturers.clusters.length === 0;

  if (shouldFetchManufacturer && step === 0) {
    commit('CARIS_FILTER_SELECT__FETCH', { fetching: true });

    const manufacturerEndpoint = fetchedUrls['car.ajax.manufacturers'];
    try {
      const manufacturerStoredOptions = localStorage.getItem('manufacturer-options');

      const response = manufacturerStoredOptions ? JSON.parse(manufacturerStoredOptions) : await axios.get(manufacturerEndpoint);
      const data = response?.data || {};

      localStorage.setItem('manufacturer-options', JSON.stringify(response));
      commit('CARIS_CARIS_BAR__LOAD_MANUFACTURERS', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching manufacturers:', error);
    } finally {
      commit('CARIS_FILTER_SELECT__FETCH', { fetching: false });
    }
  }

  if (step === 1) {
    TrackingHandler.track({
      data: {
        event: 'identifyCar_manufacturer',
        page: {
          carManufacturer: value,
        },
      },
    });
    commit('CARIS_FILTER_SELECT__STEP', { filterStep: 2 });
    commit('CARIS_FILTER_SELECT__FETCH', { fetching: true });

    const modelEndPoint = fetchedUrls['car.ajax.models'];
    try {
      const response = await axios.get(`${modelEndPoint}/${id}`);
      const data = response?.data || {}; // Use default empty object if data is undefined

      commit('CARIS_CARIS_BAR__LOAD_MODELS', data);
      commit('CARIS_CARIS_BAR__SET_CARIS_MANUFACTURER', { label, id });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching models:', error);
    } finally {
      commit('CARIS_FILTER_SELECT__FETCH', { fetching: false });
    }
  }

  if (step === 2) {
    TrackingHandler.track({
      data: {
        event: 'identifyCar_series',
        page: {
          carSeries: value,
        },
      },
    });
    commit('CARIS_FILTER_SELECT__STEP', { filterStep: 3 });
    commit('CARIS_FILTER_SELECT__FETCH', { fetching: true });

    const typeEndPoint = fetchedUrls['car.ajax.types'];
    try {
      const response = await axios.get(`${typeEndPoint}/${id}`);
      const data = response?.data || {}; // Use default empty object if data is undefined

      commit('CARIS_CARIS_BAR__LOAD_TYPES', data);
      commit('CARIS_CARIS_BAR__SET_CARIS_MODEL', { label, id });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching types:', error);
    } finally {
      commit('CARIS_FILTER_SELECT__FETCH', { fetching: false });
    }
  }

  if (step === 3) {
    let trackedElements = [];
    trackedElements.push(
      TrackingHandler.trackAsync({
        data: {
          event: 'identifyCar_model',
          page: {
            carModel: value,
          },
        },
      })
    );
    trackedElements.push(
      TrackingHandler.trackAsync({
        data: {
          event: 'identifyCar_completedSelection',
          page: {
            carType: id,
          },
        },
      })
    );
    Promise.all(trackedElements).then(() => {
      commit('CARIS_CARIS_BAR__SET_CARIS_TYPE', { label, id });
      const allCategories = fetchedUrls['content.page.node_id.0'];

      window.location.href = UrlHelper.getCarSelectionURL({
        url: allCategories,
        parameters: {
          ktypnr: id,
        },
      });
    });
  }
};

const applyFindCarData = ({ data, callbacks }) => {
  if (has(data, 'status') && data.status === 'OK') {
    if (has(data, 'ktypnr')) {
      callbacks.onsingle(data.ktypnr);
    } else if (has(data, 'cars')) {
      callbacks.onmultiple(data.cars);
    }
  } else {
    callbacks.onerror(data.message);
  }
};

export const performFindCarRequest = async (
  {
    rootState: {
      core: { fetchedUrls, salesChannel },
    },
  },
  { numberPlate, callbacks }
) => {
  const queryParameter =
    salesChannel.countryCode === 'DK' ? 'reg_nr' : 'number_plate';

  const { data } = await axios.post(
    fetchedUrls['car.ajax.find_car'],
    `${queryParameter}=${numberPlate}`
  );

  applyFindCarData({
    data,
    callbacks,
  });
};

export const doHsnTsnSearch = async (
  {
    rootState: {
      core: { fetchedUrls },
    },
  },
  { hsn, tsn, callbacks }
) => {
  if (hsn && hsn.length === 4 && tsn && tsn.length === 3) {
    const { data } = await axios.post(
      fetchedUrls['car.ajax.find_car'],
      `hsn=${hsn}&tsn=${tsn}`
    );

    applyFindCarData({
      data,
      callbacks,
    });
  }
};

export const doTypeMineSearch = async (
  {
    rootState: {
      core: { fetchedUrls },
    },
  },
  { typeMine, callbacks }
) => {
  const { data } = await axios.post(
    fetchedUrls['car.ajax.find_car'],
    `type_mine=${typeMine}`
  );

  applyFindCarData({
    data,
    callbacks,
  });
};

export const doHsnRequest = async (
  {
    rootState: {
      core: { fetchedUrls },
    },
  },
  { hsnValue, refs, instance }
) => {
  const data = await axios.get(`${fetchedUrls['car.ajax.hsn']}/${hsnValue}`);
  if (data.exists) {
    refs.hsn.setValidStyle();
    instance.tsnDisabled = false;
    requestAnimationFrame(() => {
      refs.tsn.focus();
    });
  } else {
    refs.hsn.handleInvalidInput();
    instance.tsnDisabled = true;
  }
};

export const doNatRequest = async (
  {
    rootState: {
      core: { fetchedUrls },
    },
  },
  { nat, refs, setKtypnr, callbacks }
) => {
  const response = {};

  if (nat.length >= 2 && nat.length <= 6) {
    const { data } = await axios.post(
      fetchedUrls['car.ajax.find_car'],
      `nat_code=${nat}`
    );

    if (has(data, 'status') && data.status === 'OK' && has(data, 'ktypnr')) {
      if (setKtypnr) {
        setKtypnr(data.ktypnr);
      }
    } else {
      response.error = data.message;

      refs.nat.handleInvalidInput();
      refs.nat.focus();
    }
    if (!setKtypnr) {
      applyFindCarData({
        data,
        callbacks,
      });
    }
  }

  return {
    response,
  };
};

export const unselectCar = async ({ commit, rootState, dispatch }) => {
  const unselectUrl = rootState.core.fetchedUrls['car.ajax.deselect_car'];
  const { data } = await axios.get(unselectUrl);
  if (data.status === 'OK') {
    // TODO Find a better way to do this
    dispatch(
      'dataLayer/trackCarUnselection',
      {
        callback: () => {
          commit('CARIS_CARIS_BAR__UNSELECT');
          ls.removeItem('CARIS_COCKPIT__SAVE_CAR');

          // TODO Move this to UrlHelper.removeParameter()
          const params = UrlHelper.getUrlParameters();
          delete params.ktypnr;
          const baseUrl = window.location.origin + window.location.pathname;
          const url = UrlHelper.assembleUrl({
            url: baseUrl,
            parameters: params,
            shouldMerge: false,
          });
          window.location.assign(url);
        },
      },
      { root: true }
    );
  }
};

export const doRetrieveGarageVehicles = async ({
  rootState: {
    core: { fetchedUrls },
  },
  commit,
}) => {
  commit('CARIS_FILTER_SELECT__FETCH', { fetching: true });
  const { data } = await axios.get(fetchedUrls['car.ajax.garage']);

  if (data && data.vehicles) {
    commit('CARIS_MY_GARAGE__SET_GARAGE_VEHICLES', { vehicles: data.vehicles });
  }
  commit('CARIS_FILTER_SELECT__FETCH', { fetching: false });
};

export const doFindCarWithKtypnr = async (
  {
    rootState: {
      core: { fetchedUrls },
    },
    commit,
  },
  { ktypnr, eventName = null }
) => {
  try {
    const { data } = await axios.get(
      fetchedUrls['car.ajax.car.id'].replace('{id}', ktypnr)
    );

    if (data) {
      const { car } = data;
      const eventsList = {
        keyno: 'identifyCar_completedKeyNo',
      };
      commit('CARIS_NEW_VEHICLE__ADD_SELECTABLE_VEHICLES', { vehicle: car });

      if (eventName && eventsList[eventName]) {
        TrackingHandler.track({
          data: {
            event: eventsList[eventName],
            page: {
              carType: ktypnr,
              carManufacturer: car && car.manufacturer,
              carSeries: car && calculateCarSeries(car),
              carModel: car && car.type,
            },
          },
        });
      } else {
        TrackingHandler.track({
          data: {
            event: 'identifyCar_completedSelection',
            page: {
              carType: ktypnr,
            },
          },
        });
      }

    }
  } catch (error) {
    commit('CARIS_NEW_VEHICLE__RESET_SELECTABLE_VEHICLES');
  }
};

export const doSaveCarToGarage = async (
  {
    rootState: {
      core: { fetchedUrls },
    },
  },
  { ktypnr, displayName }
) => {
  await axios.post(
    fetchedUrls['customer.shop.ajax.vehicle.minimal.create'].replace(
      '{carTypeNumber}',
      ktypnr
    ),
    { display_name: displayName.trim() }
  );
};

export const updateSaveToGarage = ({ commit }, { ktypnr, garageName }) => {
  const localStorage = new LocalStorage();

  ktypnr
    ? localStorage.setItem(
      'CARIS_COMBINED__SAVE_TO_GARAGE_VEHICLE',
      JSON.stringify({ ktypnr, garageName })
    )
    : localStorage.removeItem('CARIS_COMBINED__SAVE_TO_GARAGE_VEHICLE');

  commit('CARIS_MY_GARAGE__UPDATE_SAVE_TO_GARAGE', { ktypnr, garageName });
};
