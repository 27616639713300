const formatPrice = (price) => {
  if (typeof price === 'string') {
    price = Number.parseFloat(price);
  }

  return price || price === 0
    ? price.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '';
};

const normalizePrice = (price, currency) => {
  let number = null;
  if (typeof price === 'number') {
    number = price;
  }

  if (typeof price === 'string') {
    if (price.indexOf(',') !== -1) {
      number = Number.parseFloat(
        price.split(' ')[0].replace('.', '').replace(',', '.').replace('€', '')
      );
    } else {
      number = Number.parseFloat(price.split(' ')[0].replace('€', ''));
    }
  }

  return number !== null
    ? number.toFixed(2).replace('.', ',') + ' ' + currency
    : '';
};

export { formatPrice, normalizePrice };
