<i18n src="./translations"></i18n>

<template>
  <PdpTabBase
    class="pdp-spare-part-numbers-tab"
    :headline="$t('pdp-spare-part-numbers-tab.headline')"
    :class="classes"
    :data-preload="$options.name"
    :is-expanded="activeTab"
  >
    <PdpTable
      class="pdp-spare-part-numbers-tab__table"
      :lines="lines"
      :columns="columns"
      :load-limit="loadLimit"
      :headline="$t('pdp-spare-part-numbers-tab.table_header')"
      :show-all-label="$t('pdp-spare-part-numbers-tab.load_more')"
    >
      <template #oeNumber="{ line }">
        <MaskedLink
          :redirect-to="oeSearchUrl(line.oeNumber)"
          :text="line.oeNumber"
          class="link"
        />
      </template>
    </PdpTable>
  </PdpTabBase>
</template>

<script>
import PdpTabBase from 'Components/01-atoms/pdp-tab-base/PdpTabBase';
import globalMixin from 'Libs/mixins/globalMixin';
import PdpTable from 'Components/01-atoms/pdp-table/PdpTable';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import { axios } from 'Services/AjaxService';

const LOAD_LIMIT = 5;

export default {
  name: 'PdpSparePartNumbersTab',
  components: { PdpTabBase, PdpTable, MaskedLink },
  mixins: [globalMixin],
  props: {
    productId: {
      type: String,
      required: true,
    },
    hasOeNumbers: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    lines: [],
    columns: [],
    loadLimit: LOAD_LIMIT,
  }),
  mounted() {
    if (this.hasOeNumbers) {
      this.loadLines(LOAD_LIMIT);
    }
  },
  methods: {
    async loadLines(limit) {
      const oeNumbersEndpoint = this.frontFacingPath(
        'search.ajax.product.oe_numbers'
      ).replace('{productId}', this.productId);
      const requestConfiguration = { params: { limit } };
      const {
        data: { oeNumbers: oeNumberList },
      } = await axios.get(
        oeNumbersEndpoint,
        limit ? requestConfiguration : undefined
      );

      if (oeNumberList) {
        const { entities, count, columns } = oeNumberList;

        this.lines = entities || [];
        this.columns = this.lines.length ? columns : [];
        this.loadLimit = count >= this.lines.length ? limit : 0;
      }
    },
    oeSearchUrl(oeNumber) {
      return (
        this.frontFacingPath('search.search_bar.search') +
        '?search=' +
        oeNumber.replace(/\s/g, '+') +
        '#oens'
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.link {
  color: var(--color-nero);
  text-decoration: underline;
}
</style>
