<i18n src="./translations"></i18n>

<template>
  <div
    class="pdp-availability"
    :class="[classes, availabilityClass, { 'is-b2b': isBusinessCustomer }]"
    :data-preload="[$options.name]"
  >
    <Icon
      class="pdp-availability__shipping-icon"
      height="24"
      width="24"
      :name="
        ['instant', 'available_today'].includes(clearedState)
          ? 'shipping'
          : 'delivery_standard'
      "
    />

    <p class="pdp-availability__message">
      <span class="pdp-availability__label">
        {{ $t(`pdp-availability.${clearedState}.label`) }}
      </span>

      <span
        v-if="['available_today'].includes(clearedState) && !isBusinessCustomer"
        class="pdp-availability__text"
      >
        {{ $t(`pdp-availability.${clearedState}.text`) }}
      </span>
    </p>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'PdpAvailability',
  components: { Icon },
  mixins: [globalMixin],
  props: {
    state: {
      type: String,
      default: '',
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('core', {
      promiseBanner: ({ promiseBanner }) => promiseBanner || {},
    }),
    ...mapGetters('core', {
      isBusinessCustomer: 'isBusinessCustomer',
    }),
    clearedState() {
      const availabilityState = this.state && this.state.toLowerCase();

      if (this.promiseBanner.visible && availabilityState === 'instant') {
        return 'available_today';
      }

      switch (availabilityState) {
        case 'instant':
          return 'instant';
        case 'available':
        case 'available_today':
          return 'available_today';
        case '2to3workdays':
        case '2_to_3_workdays':
          return '2_to_3_workdays';
        case '3to4workdays':
        case '3to5workdays':
          return 'delivery_within_3_5_working_days';
        case '5to10workdays':
        case '5_to_10_workdays':
          return '5_to_10_workdays';
        case '2to4weeks':
          return 'delivery_within_2_4_weeks';
        case 'delayed':
        case 'available_soon':
        case 'available_on_request':
          return 'delayed';
        case 'out_of_stock':
          return 'out_of_stock';
        case 'notavailable':
        case 'not_available':
        case 'unavailable':
          return 'not_available';
        default:
          return 'not_available';
      }
    },
    availabilityClass() {
      switch (this.clearedState) {
        case 'instant':
          return this.promiseBanner.visible
            ? [
                'pdp-availability--instant',
                'qa-Availability--available',
                'qa-Availability--available-today',
              ]
            : ['pdp-availability--instant', 'qa-Availability--available'];
        case 'available_today':
          return this.promiseBanner.visible
            ? [
                'pdp-availability--available',
                'qa-Availability--available',
                'qa-Availability--available-today',
              ]
            : ['pdp-availability--available', 'qa-Availability--available'];
        case '2_to_3_workdays':
          return [
            'pdp-availability--2-3-workdays',
            'qa-Availability--available',
          ];
        case '5_to_10_workdays':
          return [
            'pdp-availability--5-10-workdays',
            'qa-Availability--available',
          ];
        case 'delivery_within_3_4_working_days':
        case 'delivery_within_3_5_working_days':
          return [
            'pdp-availability--3-5-workdays',
            'qa-Availability--available_soon',
          ];
        case 'delivery_within_2_4_weeks':
          return [
            'pdp-availability--2-4-weeks',
            'qa-Availability--available_soon',
          ];
        case 'delayed':
          return [
            'pdp-availability--delayed',
            'qa-Availability--available_soon',
          ];
        case 'out_of_stock':
          return [
            'pdp-availability--out-of-stock',
            'qa-Availability--available_soon',
          ];
        case 'not_available':
          return [
            'pdp-availability--not-available',
            'qa-Availability--not_available',
          ];
        default:
          return [
            'pdp-availability--not-available',
            'qa-Availability--not_available',
          ];
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';

.pdp-availability {
  display: flex;
  align-items: center;
  margin-top: var(--space-1);

  --indicative-color: var(--color-nero);

  &--instant,
  &--available,
  &--2-3-workdays,
  &--3-4-workdays,
  &--3-5-workdays,
  &--5-10-workdays {
    --indicative-color: var(--color-japanese-laurel);
  }

  &.is-b2b {
    &.pdp-availability--2-3-workdays,
    &.pdp-availability--3-5-workdays,
    &.pdp-availability--5-10-workdays {
      --indicative-color: var(--color-24-blue);
    }
  }

  &--2-4-weeks,
  &--delayed {
    --indicative-color: var(--color-amber);
  }

  &--not-available,
  &--out-of-stock {
    --indicative-color: var(--color-monza);
  }
}

.pdp-availability__shipping-icon {
  margin-right: 10px;
  fill: var(--indicative-color);

  @include icon-dimensions(24px);
}

.pdp-availability__message {
  @include typography-size(var(--font-size-M), 19px);
}

.pdp-availability__label {
  color: var(--color-nero);
  font-size: var(--font-size-SM);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.pdp-availability__text {
  font-size: var(--font-size-SM);
  color: var(--color-nero);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  display: block;
  margin-top: 3px;
}

.pdp-availability--instant .pdp-availability__label,
.pdp-availability--available .pdp-availability__label {
  background: var(--color-japanese-laurel);
  color: var(--color-white);
  padding: 3px var(--space-1);
  border-radius: 4px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.pdp-availability--available {
  align-items: normal;
}
</style>
