<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div class="product-details-21__header-wrapper">
      <div class="product-details-21__header">
        <span class="pageTitle"
          >{{ product.brandName }} {{ product.name }}</span
        >
        <div class="productId">
          <ProductAvailability
            v-show="isEmployee"
            :product-availability="product.productAvailability"
          />
          <div class="articleNumber">
            {{
              $t('product-details-21.article_number', {
                '%artNr%': product.displaySku,
              })
            }}
          </div>
        </div>
      </div>
      <div class="product-details-21__brand-logo">
        <AdvancedImage
          v-if="product.brandLogoUrl"
          :is-lazy-component="false"
          :src="product.brandLogoUrl || ''"
          :alt="product.brandName"
          :config="brandConfig"
        />
      </div>
    </div>

    <div class="information">
      <ProductImageSlider
        class="qa-productImageSlider"
        :title="product.name"
        :images="product.images"
        :brand="{
          alt: product.brandName,
          src: product.brandLogoUrl || '',
        }"
        variant="secondary"
      />

      <PdpAttributes
        :pdp-car-bar="pdpCarBar"
        :product="product"
        :related-items-link="relatedItemsLink"
      />
    </div>

    <div v-if="productTabs.length" class="tabsController">
      <span
        v-for="tab in productTabs"
        :key="tab"
        class="tab"
        :class="[{ activeTab: activeTab === tab }, `qa-${tab}`]"
        @click="activeTab = tab"
      >
        {{ $t(`product-details-21.${tab}`) }}
      </span>
    </div>

    <div class="tabs">
      <PdpTabs
        v-if="productTabs.length"
        class="qa-tabInformation"
        :active-tab="activeTab"
        :product-tabs="productTabs"
        :product="product"
        :restrictions="restrictions"
        :features="features"
      />

      <PdpBadges
        :class="{
          'pdpBadges--horizontal': !productTabs.length && viewport !== 'mobile',
        }"
        :is-horizontal="!productTabs.length"
        variant="secondary"
      />
    </div>

    <CategoryRecommendations
      v-if="!!recommendations.length"
      :items="recommendations"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

import { InfoTextsEnum } from './models/info-texts.enum';

import PdpAttributes from 'Components/03-organisms/pdp-attributes/PdpAttributes';
import PdpTabs from 'Components/03-organisms/pdp-tabs/PdpTabs';
import ProductImageSlider from 'Components/02-molecules/product-image-slider/ProductImageSlider';
import CategoryRecommendations from 'Components/02-molecules/category-recommendations/CategoryRecommendations';
import PdpBadges from 'Components/02-molecules/pdp-badges/PdpBadges';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';

export default {
  name: 'ProductDetails21',
  components: {
    PdpAttributes,
    ProductImageSlider,
    PdpTabs,
    CategoryRecommendations,
    PdpBadges,
    AdvancedImage,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    categoryRecommendations: {
      type: Array,
      default: () => [],
    },
    pdpCarBar: {
      type: Object,
      default: () => ({}),
    },
    breadcrumbs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeTab: 'description',
      brandConfig: {
        q: 60,
        w: 100,
      },
    };
  },
  computed: {
    ...mapState('core', {
      staticUrl: ({ salesChannel }) => salesChannel.staticUrl,
      isEmployee: ({ isEmployee }) => isEmployee,
      viewport: ({ viewport }) => viewport.range,
    }),
    restrictions() {
      return this.product.attributes.filter((attribute) => attribute.isTop);
    },
    features() {
      return this.product.attributes.filter((attribute) => !attribute.isTop);
    },
    recommendations() {
      let categories = [];
      if (this.categoryRecommendations.length) {
        categories = this.categoryRecommendations.map((item) => {
          if (item.imageUrl) {
            !item.imageUrl.includes(this.staticUrl)
              ? (item.imageUrl = `${this.staticUrl}/media-library/${item.imageUrl}`)
              : null;
          }
          return item;
        });
      }
      return categories;
    },
    relatedItemsLink() {
      const { breadcrumbs } = this.breadcrumbs;
      if (breadcrumbs) {
        const relatedItemsIndex = breadcrumbs.length - 2;
        if (relatedItemsIndex >= 0) {
          return breadcrumbs[relatedItemsIndex].url;
        }
      }

      return '/';
    },
    hasDescription() {
      const {
        description,
        attributes,
        documents,
        setProductsItems,
        partsListItems,
      } = this.product;

      return (
        !!description.length ||
        !!attributes.length ||
        !!documents.items.length ||
        !!setProductsItems.length ||
        !!partsListItems.length
      );
    },
    hasOeNumbers() {
      return this.product.hasOeNumbers;
    },
    hasCarTypes() {
      return this.product.hasCarTypes;
    },
    hasHints() {
      return !!this.product.articleType;
    },
    hasGpsr() {
      return (
        !!this.product.gpsrResources &&
        !!Object.keys(this.product.gpsrResources).length
      );
    },
    hasGeneralInfo() {
      const infoTexts = this.product.infoTexts;
      return (
        !!infoTexts &&
        infoTexts.some(({ type }) => type === InfoTextsEnum.GENERAL_INFO)
      );
    },
    hasTechInfo() {
      const infoTexts = this.product.infoTexts;
      return (
        !!infoTexts &&
        infoTexts.some(({ type }) => type === InfoTextsEnum.TECH_INFO)
      );
    },
    productTabs() {
      let tabs = [];
      if (this.hasDescription) tabs.push('description');
      if (this.hasGeneralInfo) tabs.push('generalInfo');
      if (this.hasTechInfo) tabs.push('techInfo');
      if (this.hasOeNumbers) tabs.push('spareParts');
      if (this.hasCarTypes) tabs.push('vehicleTypes');
      if (this.hasHints) tabs.push('hints');
      if (this.hasGpsr) tabs.push('gpsr');

      return tabs;
    },
  },
  mounted() {
    this.activeTab = this.productTabs.length ? this.productTabs[0] : null;
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

.product-details-21__header-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.product-details-21__header {
  display: flex;
  flex-direction: column;
}

.product-details-21__brand-logo {
  width: 100px;
  height: 60px;
}

.productDetails21 {
  display: flex;
  flex-wrap: wrap;
  padding: var(--space-2);
  width: 100%;

  @media #{$_mediaMDown} {
    flex-direction: column;
    padding: 0;
    width: 100vw;
  }

  @media #{$_mediaSDown} {
    padding: 0;
    width: 100vw;
  }
}

.pageTitle {
  font-size: var(--font-size-XL);
  font-weight: bold;
  line-height: 40px;
  color: var(--color-nero);
  padding-bottom: var(--space-1);

  @media #{$_mediaMDown} {
    padding: var(--space-2);
    font-size: var(--font-size-L);
    line-height: 1.17;
  }
}

.productId,
.information {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media #{$_mediaMDown} {
    flex-direction: column;
  }
}

.productId {
  .articleNumber,
  .products-availability-wrapper {
    color: var(--color-rhino-without-horn);
    font-size: var(--font-size-SM);
  }

  @media #{$_mediaMDown} {
    .articleNumber {
      padding: var(--space-2);
    }

    .products-availability-wrapper {
      display: none;
    }
  }
}

.information {
  padding-top: var(--space-3);
  padding-bottom: var(--space-5);

  .productImageSlider {
    width: 100%;
    max-width: 480px;
  }

  .pdpAttributes {
    max-width: 400px;
    height: fit-content;
  }

  @media #{$_mediaLDown} {
    .productImageSlider,
    .pdpAttributes {
      width: 100%;
      max-width: none;
    }
  }

  @media #{$_mediaMDown} {
    padding-top: var(--space-0);
    padding-bottom: var(--space-0);
    flex-direction: column;
  }
}

.tabsController {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 51px;
  margin-bottom: var(--space-3);
  border-radius: var(--border-radius-default);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--color-wild-sandish);

  .tab {
    padding: var(--space-2) var(--space-3);
    color: var(--color-nero);
    cursor: pointer;
    height: 100%;
    vertical-align: center;
    text-align: center;
  }

  .activeTab {
    color: var(--color-secondary);
    border-bottom: 2px solid var(--color-secondary);
  }

  @media #{$_mediaMDown} {
    display: none;
  }
}

.tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: var(--space-2);

  .pdpTabs {
    max-width: 580px;
    width: 100%;
    padding-right: var(--space-2);
  }

  .pdpBadges {
    max-width: 300px;
    max-height: 380px;

    &--horizontal {
      max-width: none;
      display: flex;

      @media #{$_mediaLUp} {
        max-height: 280px;
        padding: var(--space-2);
        align-items: center;
      }

      @media (min-width: #{$_widthXXLFrom}) {
        max-height: 137px;
        padding: 0;
        flex-direction: row;
      }
    }
  }

  @media #{$_mediaMDown} {
    flex-direction: column;

    .pdpTabs,
    .pdpBadges {
      max-width: none;
      width: 100%;
      padding-right: 0;
    }

    .pdpBadges {
      margin-top: var(--space-3);
      width: 90%;
      align-self: center;
    }

    .product-details-21__header-wrapper {
      padding: 0 var(--space-2);
    }
  }

  @media #{$_mediaSDown} {
    .pdpTabs {
      padding-right: 0;
    }

    .product-details-21__header-wrapper {
      padding: 0 var(--space-2);
    }
  }
}

.categoryRecommendations {
  margin-top: var(--space-1);
}
</style>
