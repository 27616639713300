import { has } from 'lodash';

const findProduct = (state, productId) => {
  return state.sections
    .reduce((products, section) => [...products, ...section.products], [])
    .find((product) => product.productId === productId);
};

export const SET_HEADER_WISHLIST_DATA = (state, data) => {
  if (data && data.wishlist && state.data) {
    state.data.wishlist = data.wishlist;
    state.data.isWished = true;
  }
};

export const SET_WISHLIST = (state, wishlist) => {
  if (has(wishlist, 'idWishlist') || has(wishlist, 'productCount')) {
    for (const field in wishlist) {
      if (Object.hasOwn(wishlist, field)) {
        state[field] = wishlist[field];
      }
    }
  }
};

export const SET_PRODUCT_COUNT = (state, productCount) => {
  state.productCount = productCount;
};

export const SET_SECTIONS = (state, sections) => {
  state.sections = { ...sections };
};

export const SET_PRODUCT_IN_WISHLIST = (state, { productId, inWishlist }) => {
  const product = findProduct(state, productId);

  if (product) {
    product.inWishlist = inWishlist;
    state.sections = [...state.sections];
  }
};
