<i18n src="./translations"></i18n>

<template>
  <div :class="classes">
    <div class="inner">
      <span
        v-if="pagination.hasPreviousPage"
        class="pageSelector arrow"
        @click="handleAdjacentPageClick(pagination.currentPage - 1)"
      >
        <Icon class="chevron" name="chevron_left" />
      </span>

      <MaskedLink
        v-for="(page, index) in paginationList"
        :key="`page-${index}`"
        class="link pageSelector"
        :class="{
          'pageSelector--separator': page === '...',
          'pageSelector--active': page.current === true,
          'pageSelector--extra':
            page === pagination.currentPage - 2 ||
            page === pagination.currentPage + 2,
        }"
        :text="(page.page || page).toString()"
        :redirect-to="createUrl(page instanceof Object ? page.page : page)"
      />

      <span
        v-if="pagination.hasNextPage"
        class="pageSelector arrow"
        @click="handleAdjacentPageClick(pagination.currentPage + 1)"
      >
        <Icon class="chevron" name="chevron_right" />
      </span>
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="pagination.maxPage > pagesMaxForWindowSize"
      class="info"
      v-html="
        $t('pagination.results', {
          '%count%': Math.trunc(pagination.maxPage),
        })
      "
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon';
import snakeCase from 'lodash/snakeCase';

import { mapState } from 'vuex';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'Pagination',
  components: {
    Icon,
    MaskedLink,
  },
  mixins: [globalMixin],
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('core', {
      requestData: ({ requestData }) => {
        return requestData;
      },
    }),
    paginationList() {
      let pagesMax = this.pagination.maxPage;
      const LIMIT = 2;

      if (this.pagination.maxPage > this.pagesMaxForWindowSize) {
        pagesMax = this.pagesMaxForWindowSize;
      }

      const pagesDisplay = [1];
      const fromIndex = this.pagination.currentPage - LIMIT;
      const toIndex = this.pagination.currentPage + LIMIT;

      for (let index = fromIndex; index <= toIndex; index++) {
        if (index > 1 && index < pagesMax) {
          pagesDisplay.push(index);
        }
      }

      pagesDisplay.push(pagesMax);
      let pageLast = 0;

      pagesDisplay.forEach((item, index) => {
        if (item !== pageLast + 1) {
          if (item === pagesMax) {
            pagesDisplay.push(pagesMax);
          }
          pagesDisplay.splice(index, 1, '...');
        }

        if (this.pagination.currentPage === item) {
          pagesDisplay[index] = { current: true, page: item };
        }

        pageLast = item;
      });

      if (
        pagesDisplay.filter((item) => item === '...').length > 1 ||
        pagesDisplay.indexOf('...') === 1
      ) {
        pagesDisplay.splice(2, 0, pagesDisplay[2] - 1);
      }

      return pagesDisplay;
    },
    pagesMaxForWindowSize() {
      return parseInt(10000 / this.pagination.currentItemsPerPage, 10);
    },
  },
  methods: {
    convertKey(key) {
      // The Filters should have double __ in case of outlet and inlet
      const [baseKey, index] = key.split('[');
      let modifiedKey = snakeCase(baseKey);

      ['outlet', 'inlet'].forEach((item) => {
        if (modifiedKey.includes(item)) {
          modifiedKey = modifiedKey.replace(item, `${item}_`);
        }
      });

      if (index) {
        modifiedKey = `${modifiedKey}[${index}`;
      }

      return modifiedKey;
    },
    createUrl(page) {
      const queryParams = Object.entries(this.requestData.queryParams);
      const relativeURL = this.requestData.requestUrl
        .split('//')[1]
        .split('/')
        .slice(1)
        .join('/');

      let queryString = '';
      if (queryParams.length) {
        queryParams.forEach(([key, value]) => {
          if (key === 'page') {
            return;
          }

          // Calculate the offset based on the page and limit
          if (key === 'offset' && this.requestData.queryParams.limit) {
            value[0] =
              (page - 1) * Number(this.requestData.queryParams.limit[0]);
          }

          queryString =
            queryString +
            `&${encodeURI(this.convertKey(key))}=${encodeURIComponent(
              value[0]
            )}`;
        });

        queryString = `?page=${page}` + queryString;
      } else {
        queryString = `?page=${page}`;
      }

      return `/${relativeURL}${queryString}`;
    },
    handleAdjacentPageClick(pageNumber) {
      location.assign(this.createUrl(pageNumber));
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.pagination {
  text-align: center;
  padding-top: 20px;
  width: 100%;
}

.inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-top: 20px;
}

.pageSelector {
  $elementHeight: 30px;

  height: $elementHeight;
  width: 32px;
  font-size: var(--font-size-S);
  font-weight: 700;
  line-height: $elementHeight;
  border: 1px solid var(--color-alto);
  color: var(--color-rolling-stone);
  cursor: pointer;
  border-radius: 3px;

  &--separator {
    border: none;
    margin: 0 4px 0 8px;
    padding: 0;
    pointer-events: none;
  }

  &--active {
    color: var(--color-white);

    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
  }

  &:not(:first-child) {
    margin-left: var(--space-0--half);
  }

  &:hover {
    color: var(--color-white);

    @include themeColor(
      background-color,
      var(--color-endeavour),
      var(--color-gold-drop),
      var(--color-chathamsblue)
    );
    @include themeColor(
      border-color,
      var(--color-endeavour),
      var(--color-gold-drop),
      var(--color-chathamsblue)
    );
  }
}

.arrow {
  width: 45px;

  .chevron {
    height: 100%;
    width: 25px;
  }
}

.iconContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  color: var(--color-primary);

  max-width: 400px;
  margin: 16px auto 0;
  text-align: center;
  font-size: var(--font-size-S);
  line-height: 1.4em;

  ::v-deep em {
    font-weight: 700;
  }
}

@media (max-width: 590px) {
  .pageSelector {
    padding: 0;
    margin-left: 3px;
    width: 40px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .pageSelector {
    width: 40px;
    margin-left: 3px;

    &--extra {
      display: none;
    }

    &--separator {
      margin: 0 -3px 0 -2px;
    }
  }
}
</style>
