<template>
  <section class="pdp-tabs" :class="classes" :data-preload="[$options.name]">
    <component
      :is="tab"
      v-for="(tab, index) of availableTabs"
      :key="`tab-${tab}-${index}`"
      :class="{ 'is-not-active': activeTabComponent !== tab }"
      :restrictions="restrictions"
      :features="features"
      :description="product.description"
      :tab-name="tab"
      :info-texts="product.infoTexts"
      :product-bundles="productBundles"
      :documents="product.documents.items"
      :is-hazardous-good="product.isHazardousGood"
      :hazardous-good-information="product.hazardousGoodInformation"
      :hazardous-good-info="hazardousGoodInfo"
      :article-type="product.articleType"
      :product-id="product.productId"
      :gpsr="product.gpsrResources"
      :has-car-types="product.hasCarTypes"
      :has-oe-numbers="product.hasOeNumbers"
      :active-tab="tab === activeTabComponent"
      :images="product.images"
    />
  </section>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState } from 'vuex';
import PdpSparePartNumbersTab from 'Components/02-molecules/pdp-spare-part-numbers-tab/PdpSparePartNumbersTab';
import PdpDescriptionTab from 'Components/02-molecules/pdp-description-tab/PdpDescriptionTab';
import PdpVehicleTypesTab from 'Components/02-molecules/pdp-vehicle-types-tab/PdpVehicleTypesTab';
import PdpHintsTab from 'Components/02-molecules/pdp-hints-tab/PdpHintsTab';

const TABS = {
  description: 'PdpDescriptionTab',
  generalInfo: 'PdpGeneralInfoTab',
  techInfo: 'PdpTechInfoTab',
  spareParts: 'PdpSparePartNumbersTab',
  vehicleTypes: 'PdpVehicleTypesTab',
  hints: 'PdpHintsTab',
  gpsr: 'PdpTabGpsr',
};

export default {
  name: 'PdpTabs',
  components: {
    PdpSparePartNumbersTab,
    PdpDescriptionTab,
    PdpVehicleTypesTab,
    PdpHintsTab,
    // Async Components
    PdpGeneralInfoTab: () =>
      import('Components/02-molecules/pdp-info-texts-tab/PdpInfoTextsTab'),
    PdpTechInfoTab: () =>
      import('Components/02-molecules/pdp-info-texts-tab/PdpInfoTextsTab'),
    PdpTabGpsr: () => import('Components/02-molecules/pdp-tab-gpsr/PdpTabGpsr'),
  },
  mixins: [globalMixin],
  props: {
    activeTab: {
      type: String,
      default: 'description',
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    restrictions: {
      type: Array,
      default: () => [],
    },
    features: {
      type: Array,
      default: () => [],
    },
    productTabs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tabs: TABS,
  }),
  computed: {
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
      hazardousGoodInfo: ({ salesChannel }) =>
        salesChannel.config.hazardousGoodInfo || false,
    }),
    productBundles() {
      const setProductsItems = this.product.setProductsItems || [];
      const partsListItems = this.product.partsListItems || [];

      return setProductsItems.concat(partsListItems);
    },
    activeTabComponent() {
      return TABS[this.activeTab] || TABS.description;
    },
    availableTabs() {
      return Object.keys(this.tabs)
        .filter((tab) => this.productTabs.includes(tab))
        .reduce((filteredTabs, tab) => {
          filteredTabs[tab] = this.tabs[tab];

          return filteredTabs;
        }, {});
    },
  },
  methods: {
    toggleContainer() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss">
@import 'variables/breakpoints';

.is-not-active {
  @media #{$_mediaLUp} {
    display: none; // We only hide the non-active tabs in Large screens
  }
}
</style>
