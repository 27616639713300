<i18n src="./translations"></i18n>
<template>
  <div
    class="mobileCategories--navigation"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <div v-if="parentItem" class="headerContainer">
      <button class="backButton" @click="hidePanel">
        <ChevronLeftSvg width="35" height="35" />
        <span v-if="headerPretitle" class="headerPretitle">
          {{ parentItem.title }}
        </span>
      </button>
      <button class="closeButton" @click="closeMobileMenu">
        <CloseSvg width="24" height="24" />
      </button>
    </div>
    <template v-else>
      <a
        v-if="isTirendo"
        class="tirendo-back-button"
        href="https://www.tirendo.de/"
        target="_blank"
      >
        <ChevronLeftSvg width="35" height="35" />
        {{ $t('mobile-categories.tirendo-return-button') }}
      </a>

      <StaticLinks :links="links" />
      <div class="headerButtonContainer">
        <button class="closeButton headerButton" @click="closeMobileMenu">
          <CloseSvg width="24" height="24" />
        </button>
      </div>
    </template>

    <nav>
      <div class="body">
        <div v-if="fetching" class="bodyRemain">
          <LoadingSpinner
            class="spinnerPosition"
            :dimensions="{ width: '60', height: '60' }"
          />
        </div>

        <span class="mobileCategoriesList">
          <MaskedLink
            class="panelMobCategTitle"
            :redirect-to="frontFacingPath('content.page.node_id.0')"
            :text="$t('mobile-categories.title')"
          />
          <ChevronRightSvg class="mobileChevron" />
        </span>

        <ul class="categoriesList">
          <li v-if="!!parentItem && activeItem">
            <MaskedLink
              :redirect-to="activeItem.url"
              :text="activeItem.title"
              class="parentLink"
            />
          </li>
          <li
            v-for="(item, index) in menuItems"
            :key="item.id"
            :class="[
              'menuItem',
              { 'menuItem--hasChildren': !!item.childrenCount },
            ]"
          >
            <span
              v-if="item.url"
              class="menuItemButton"
              @click="handleClick(item, index)"
            >
              <span class="itemTitle">
                {{ item.title }}
              </span>
              <ChevronRightSvg
                v-if="!!item.childrenCount"
                class="selectionChevron"
              />
            </span>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CloseSvg from 'Components/00-generated/CloseSvg';
import ChevronLeftSvg from 'Components/00-generated/ChevronLeftSvg';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import LoadingSpinner from 'Components/01-atoms/loading-spinner/LoadingSpinner';
import StaticLinks from 'Components/01-atoms/static-links/StaticLinks';
import scrollMixin from 'Libs/mixins/scrollMixin';
import globalMixin from 'Libs/mixins/globalMixin';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'MobileCategories',
  components: {
    LoadingSpinner,
    CloseSvg,
    ChevronLeftSvg,
    ChevronRightSvg,
    StaticLinks,
    MaskedLink,
  },
  mixins: [globalMixin, scrollMixin],
  props: {
    headerPretitle: {
      type: String,
      default: '',
    },
    headerTitle: {
      type: String,
      required: true,
    },
    parentItem: {
      type: Object,
      default: null,
    },
    parentIndex: {
      type: Number,
      default: null,
    },
    menuItems: {
      type: Array,
      default() {
        return [];
      },
    },
    activeItem: {
      type: Object,
      default: null,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
    isTopCategories: {
      type: Boolean,
      default: false,
    },
    onClickHandler: {
      type: Function,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    isNewTopNavigationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('navigation', {
      lastSelectedMenuItemIndex: (state) => state.lastSelectedMenuItemIndex,
    }),
    ...mapState('carSelection', {
      isLoggedIn: ({ data }) => data && data.isLoggedIn,
    }),
    ...mapState('core', {
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
    }),
    links() {
      let linkArray = [
        {
          title: this.isLoggedIn
            ? this.$t('mobile-categories.my_account')
            : this.$t('mobile-categories.login'),
          url: this.isLoggedIn
            ? this.frontFacingPath('customer.shop.customer_edit')
            : this.frontFacingPath('customer.shop.login'),
          icon: 'account',
        },
        {
          title: this.$t('mobile-categories.wishlist'),
          url: this.isLoggedIn
            ? this.frontFacingPath('customer.shop.wishlist')
            : this.frontFacingPath('checkout.wishlist'),
          icon: 'wishlist_filled_heart',
        },
        // Hide this till the Filialen & Werkstätten content is available
        // {
        //   title: this.$t('mobile-categories.branches'),
        //   url: this.frontFacingPath('content.page.branches'),
        //   icon: 'location',
        // }
      ];

      return linkArray;
    },
    isTirendo() {
      return this.salesChannelId === 'sis_tirendo-delticom-de';
    },
  },
  methods: {
    ...mapActions('navigation', [
      'closeMobileNavigation',
      'selectMenuItem',
      'highlightSelectedMenuItemMobile',
      'saveLastSelectedItemIndex',
    ]),
    hidePanel() {
      this.selectMenuItem(this.parentItem);
      this.highlightSelectedMenuItemMobile(this.lastSelectedMenuItemIndex);
    },
    closeMobileMenu() {
      this.enableBodyScrolling();
      this.closeMobileNavigation();
    },
    handleClick(item, index) {
      const { menuItems, childrenCount, url } = item;

      if (
        !!menuItems.length ||
        this.name === 'mainCategories' ||
        (this.isNewTopNavigationEnabled && !!childrenCount)
      ) {
        this.onClickHandler(item);
        this.saveLastSelectedItemIndex(index);
      }

      if (menuItems.length === 0) {
        location.assign(url);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.mobileCategories {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  overflow: scroll;

  & > header {
    flex-shrink: 0;
  }

  & > nav {
    flex-grow: 1;
    background-color: var(--color-wild-sand);
    border: 3px solid var(--color-alt);
    border-top: 1px solid var(--color-alt);
  }

  .staticLinks {
    overflow: visible;
    flex-grow: 0;
    margin-top: var(--space-9);
  }

  &--navigation {
    & > nav {
      border: none;
      border-top: none;
      background-color: var(--color-white);
    }

    .closeButton {
      position: absolute;
      right: 3%;
      top: 24px;
      color: #545456;
      display: block;
      margin: 0;

      & svg {
        stroke: currentColor;
        fill: currentColor;
      }
    }

    .headerButton {
      right: 5%;
    }

    .body {
      border-top: none;
      height: 100%;
      max-width: 100%;
      overflow-x: hidden;
    }

    .headerContainer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      background-color: var(--color-white);

      .backButton {
        flex-shrink: 0;
        height: 100%;
        width: 80%;
        background-color: var(--color-white) !important;
        margin-right: var(--space-2);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .headerPretitle {
          color: var(--color-black);
        }

        svg {
          fill: var(--color-secondary);
        }
      }
    }

    .categoriesList {
      margin: var(--space-1) var(--space-2) var(--space-2);

      .parentLink {
        font-weight: bold;
        font-size: 18px;
        color: var(--color-nero);
        padding-left: 2px;
        margin-bottom: 12px;
        display: block;
      }

      .menuItemButton {
        padding-left: 2px;
      }

      .menuItem {
        .menuItemButton {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          margin-bottom: var(--space-0--half);
          text-align: left;
          height: 32px;
          line-height: 32px;
          cursor: pointer;
        }

        .itemTitle {
          width: fit-content;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: var(--font-size-M);
          color: var(--color-nero);
        }

        .chevronRightSvg {
          height: 30px;
          width: 30px;

          color: var(--color-secondary);
        }
      }
    }

    .tirendo-back-button {
      display: flex;
      align-items: center;
      margin: var(--space-3) var(--space-2) var(--space-0);
      font-size: var(--font-size-M);
    }
  }
}

.headerContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  background-color: var(--color-concrete);
}

.headerTexts {
  flex-grow: 1;
}

.headerPretitle {
  font-size: var(--font-size-SM);
  color: #707c80;
  display: block;
}

.headerTitle {
  font-weight: bold;
}

.mobileCategoriesList {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  margin-top: var(--space-3);
  z-index: 0;
}

.panelMobCategTitle {
  width: 100%;
  font-weight: bold;
  font-size: var(--font-size-M);
  color: var(--color-nero);
  padding: 15px 18px;
  position: absolute;
}

.mobileChevron {
  width: 30px;
  height: 30px;
  color: var(--color-secondary);
  margin: var(--space-1) var(--space-2);
}

.infoMobileNav {
  margin: 0 var(--space-2);
  padding-left: var(--space-1);
  padding-bottom: var(--space-3);
  border-bottom: 1px solid var(--color-alt);
  position: sticky;
  top: -20px;
  z-index: 1;
}

.headerButtonContainer {
  position: fixed;
  width: 80px;
  height: 80px;
  right: 10px;
  padding: var(--space-2) var(--space-1);
  background-color: var(--color-white);
  z-index: 1;
}

.backButton {
  flex-shrink: 0;
  height: 100%;
  width: 60px;
  color: var(--color-white);
  margin-right: var(--space-2);
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-primary);
}

.closeButton {
  margin-left: auto;
  margin-right: var(--space-1);
  color: var(--color-rolling-stone);
  display: flex;

  svg {
    stroke: currentColor;
  }
}

.body {
  background-color: var(--color-white);
  border-top: solid 1px var(--color-alt);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
  align-items: stretch;
}

.bodyRemain {
  flex-grow: 1;
  position: relative;
  min-height: 120px;
}

.spinnerPosition {
  @include absolutelyCentered;
}

.bodyTitle {
  line-height: 50px;
  font-weight: bold;
  text-indent: var(--space-2);
}
</style>
