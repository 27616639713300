import { has } from 'lodash';

export const setCheckoutState = ({
  data: checkoutData,
  component: checkoutComponent,
} = {}) => {
  const defaultData = {
    addresses: {
      billing: [],
      shipping: [],
    },
    cart: {},
    availableCountries: [],
    availablePhoneCodeCountries: [],
    addressSalutationHideCompany: false,
    defaultCountryCode: '',
    defaultBillingActive: false,
    businessEmail: '',
    company: '',
    taxNumber: '',
    customerSegment: '',
    customer: {
      id: null,
      customerType: {},
      incrementId: '',
      isGuest: false,
      defaultBillingAddress: {},
      defaultShippingAddress: {},
      shopUser: {
        email: '',
      },
    },
    bulkyGoods: {},
    dangerousGoods: {},
    directDeliveryEnabled: false,
    dropshippingItems: false,
    lastSuccessfulStep: '',
    newsletterSubscription: false,
    payment: {},
    shipping: {
      availableShippingTypes: [
        {
          disabled: false,
          dropshipmentAndExpress: false,
          estimatedDeliveryDate: '',
          shipmentAvailableForBulky: true,
          shipmentAvailableForDangerous: true,
          shippingCost: '',
          shippingType: '',
          zipCodeAllowed: false,
        },
        {
          disabled: false,
          dropshipmentAndExpress: false,
          estimatedDeliveryDate: '',
          shipmentAvailableForBulky: true,
          shipmentAvailableForDangerous: true,
          shippingCost: '',
          shippingType: '',
          zipCodeAllowed: false,
        },
      ],
      shippingType: '',
    },
    step: '',
    selectedBillingAddress: {},
    selectedShippingAddress: {},
    isValidPaymentSelection: false,
    selectedPayment: {}
  };

  if (!checkoutData) {
    checkoutData = {};
  }

  /**
   * For some paths the selectedAddress is nested inside the address object
   * and for ease of use we extract it.
   */
  if (has(checkoutData, ['checkout', 'address', 'selectedBillingAddress'])) {
    checkoutData.checkout.selectedBillingAddress = checkoutData.checkout.address.selectedBillingAddress;
  }

  if (has(checkoutData, ['checkout', 'address', 'selectedShippingAddress'])) {
    checkoutData.checkout.selectedShippingAddress = checkoutData.checkout.address.selectedShippingAddress;
  }

  if (has(checkoutData, ['checkout', 'shipping', 'availableShippingTypes'])) {
    checkoutData.checkout.shipping.availableShippingTypes = checkoutData.checkout.shipping.availableShippingTypes.map(
      (type) => ({
        ...type,
        zipCodeAllowed:
          type.zipCodeAllowed !== undefined ? type.zipCodeAllowed : false,
      })
    );
  }
  const result = {
    data: {
      ...defaultData,
      ...(has(checkoutData, 'checkout') ? checkoutData.checkout : {}),
    },
    component: checkoutComponent,
  };

  return result;
};
