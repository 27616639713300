import { render, staticRenderFns } from "./CarisMyGarage.vue?vue&type=template&id=542c287c&scoped=true"
import script from "./CarisMyGarage.vue?vue&type=script&lang=js"
export * from "./CarisMyGarage.vue?vue&type=script&lang=js"
import style0 from "./CarisMyGarage.vue?vue&type=style&index=0&id=542c287c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542c287c",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fcaris-my-garage%2FCarisMyGarage.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports