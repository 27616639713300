<i18n src="./translations"></i18n>

<template>
  <div
    :class="[classes, dynamicCssClasses, ...propBasedClasses]"
    :data-preload="[$options.name]"
  >
    <slot />
    <div class="inner">
      <label v-if="label" class="label" :for="computedId">
        {{ label }}
        <template v-if="!required"> ({{ $t('dropdown.optional') }}) </template>
      </label>

      <select
        :id="computedId"
        class="select"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :value="value"
        @focus="addModifier('focused')"
        @blur="removeModifier('focused')"
        @invalid="handleInvalidInput"
        @input="$emit('input', $event.target.value)"
      >
        <option disabled :selected="!selectedOption">
          <template v-if="innerLabel">
            {{ innerLabel }}
          </template>
          <template v-else> -- {{ $t('dropdown.select') }} -- </template>
        </option>

        <template v-for="option in options">
          <option
            :key="option.value"
            :value="option.value"
            :selected="option.value === value"
          >
            {{ option.label }}
          </option>
        </template>
      </select>

      <div class="button">
        <slot
          v-if="attr && attr.iconDropdown"
          name="selectedOptionIcon"
          :icon="selectedOption && selectedOption.icon"
        />

        <div v-if="selectedOption" class="buttonText">
          {{ selectedOption.selected_label || selectedOption.label }}
        </div>
        <div v-else class="buttonText default-option">
          -- {{ $t('dropdown.select') }} --
        </div>

        <div class="buttonIcon">
          <ArrowDropDownSvg class="icon" width="10" height="10" />
        </div>
      </div>
    </div>

    <div class="error">
      <template v-for="error in errors">
        {{ error.message }}
      </template>
    </div>

    <div class="message">
      {{ message || (attr && attr.message) }}
    </div>
  </div>
</template>

<script>
import ArrowDropDownSvg from 'Components/00-generated/ArrowDropDownSvg.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import find from 'lodash/find';
import { formBaseMixin } from 'Libs/mixins/formBaseMixin';

export default {
  name: 'Dropdown',
  components: { ArrowDropDownSvg },
  mixins: [globalMixin, formBaseMixin],
  props: {
    innerLabel: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    useSvg: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    hasTitleIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedOption() {
      return find(this.options || [], (o) => o.value === this.value) || null;
    },
    propBasedClasses() {
      return [
        this.buildClass(
          this.selectedOption && this.selectedOption.icon,
          'indentText'
        ),
        this.buildClass(this.hasTitleIcon, 'has-titleIcon'),
      ];
    },
  },
  beforeMount() {
    this.$watch('value', function() {
      this.addModifier('dirty');
      this.removeModifier('error');
    });
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';

.dropdown {
  position: relative;

  &--error {
    .error {
      color: var(--color-red);
    }

    .label {
      color: var(--color-red);
    }

    .select,
    .button {
      border: 1px solid var(--color-red);
    }

    .buttonIcon {
      border-color: var(--color-red);
    }

    .icon {
      color: var(--color-red);
    }
  }

  &--readonly {
    pointer-events: none;
  }

  &--disabled {
    pointer-events: none;

    .label {
      color: var(--color-boulder);
    }

    .select {
      pointer-events: none;
      color: var(--color-silver);
    }

    .icon {
      color: var(--color-black--disabled);
    }

    .buttonText {
      opacity: 0.5;
    }
  }

  &--focused:not(&--error):not(&--disabled) {
    .button {
      border: 1px solid var(--color-chathamsblue);
      outline: none;
    }

    .buttonIcon {
      border-left-color: var(--color-chathamsblue);
    }

    .icon {
      color: var(--color-black--primary);
    }

    .label {
      color: var(--color-chathamsblue);
    }
  }

  &--has-titleIcon.dropdown--indentText {
    .buttonText {
      padding-left: 48px;
    }
  }
}

.inner {
  position: relative;
}

.label {
  display: block;

  color: var(--color-black--primary);
  font-size: var(--font-size-S);
  line-height: 16px;
  position: absolute;
  top: 1px;
  transform: translate(0, -50%);
  z-index: 1;
  background: var(--color-white);
  padding: 0 6px;
  margin: 0 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 66px);
  white-space: nowrap;
  transition:
    color var(--time-S) ease-in-out,
    font-size var(--time-S) ease-in-out,
    transform var(--time-S) ease-in-out;
}

.button {
  display: flex;
  border: 1px solid var(--color-alto);
  height: 48px;
  border-radius: var(--border-radius-default);
  background: var(--color-white);

  transition: all var(--time-S);
  cursor: pointer;

  .dropdown--label & {
    margin-top: 9px;
  }
}

.buttonText {
  padding: 12px var(--space-2);
  flex-grow: 1;

  font-size: var(--font-size-M);
  color: var(--color-mine-shaft);
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 48px;

  &.default-option {
    color: var(--color-alto);
  }
}

.buttonIcon {
  flex-shrink: 0;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid var(--color-alto);
}

.select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  font-size: var(--font-size-M);
  line-height: 18px;
  appearance: none;
}

::v-deep .optionIcon {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 20px;
  margin: 0 var(--space-1) 0 var(--space-2);
  cursor: pointer;
  pointer-events: none;
  transition: opacity var(--time-S) ease-in-out;
}

.message,
.error {
  color: var(--color-mine-shaft);
  font-size: var(--font-size-S);
  line-height: 16px;
  padding-left: 17px;
}

:root[data-theme='tirendo'] {
  .dropdown {
    &--focused:not(&--error):not(&--disabled) {
      .button {
        border: 1px solid var(--color-tirendo-blackout) !important;
        outline: none;
      }

      .buttonIcon {
        border-left-color: var(--color-tirendo-blackout) !important;
      }

      .icon {
        color: var(--color-black--primary);
      }

      .label {
        color: var(--color-tirendo-blackout) !important;
      }
    }
  }
}
</style>
