import { has } from 'lodash';

export const setCartState = ({
  data: headerCartData,
  component: headerCartComponent,
} = {}) => {
  const defaultData = { totalProductCount: 0 };
  let cartData = {};

  if (!headerCartData) {
    headerCartData = {};
  }

  if (has(headerCartData, 'cart')) {
    cartData = headerCartData.cart;
  }

  return {
    data: {
      ...headerCartData,
      cart:
        headerCartData && headerCartData.cart
          ? headerCartData.cart
          : defaultData,
    },
    component: headerCartComponent,
    ...cartData,
  };
};
