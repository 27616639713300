import { render, staticRenderFns } from "./MobileCategories.vue?vue&type=template&id=5289a757&scoped=true"
import script from "./MobileCategories.vue?vue&type=script&lang=js"
export * from "./MobileCategories.vue?vue&type=script&lang=js"
import style0 from "./MobileCategories.vue?vue&type=style&index=0&id=5289a757&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5289a757",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fmobile-categories%2FMobileCategories.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports