import { setWishlistState } from './state';
import * as mutations from './mutations';
import * as actions from './actions';
import getters from './getters';

export default (content) => ({
  state: setWishlistState(content),
  mutations,
  actions,
  getters,
  namespaced: true,
});
