<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <HeaderButton
      class="desktopLabel"
      :modifiers="['headerButton']"
      :icon="{ name: 'wishlist_filled_heart', height: '21', width: '21' }"
      :title="$t('header-wishlist-button.wishlist')"
      :label="$t('header-wishlist-button.wishlist')"
      :count="getWishlistProductCount"
      :href="
        isLoggedIn
          ? frontFacingPath('customer.shop.wishlist')
          : frontFacingPath('checkout.wishlist')
      "
      :toggle="false"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import HeaderButton from 'Components/01-atoms/header-button/HeaderButton.vue';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'HeaderWishlistButton',
  components: {
    HeaderButton,
  },
  mixins: [globalMixin],
  computed: {
    ...mapState('wishlist', {
      isLoggedIn: (state) => state.data.wishlist.isLoggedIn,
    }),
    ...mapGetters('wishlist', ['getWishlistProductCount']),
  },
  methods: mapActions('wishlist', ['updateWishlist']),
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.headerWishlistButton {
  display: block;
  position: relative;
}

.desktopLabel {
  span {
    display: inline-block;
    font-weight: 400;
    font-size: var(--font-size-SM);
    margin-left: 8px;

    @media (max-width: 899px) {
      display: none;
    }
  }
}
</style>
