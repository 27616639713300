import { has } from 'lodash';

export const SET_CART = (state, cart) => {
  if (has(cart, 'cartId') || has(cart, 'totalProductCount')) {
    Object.assign(state, cart);
  }
};

export const SET_TOTAL_PRODUCT_COUNT = (state, totalProductCount) => {
  state.totalProductCount = totalProductCount;
};
