<i18n src="./translations"></i18n>

<template>
  <div
    v-click-outside="{
      handler: closeAccountWidget,
      isActive: accountWidgetActive,
    }"
    :class="classes"
    :data-preload="[$options.name]"
    data-prefetch="AccountWidget"
    :data-testid="
      isLoggedIn ? 'HeaderAccountButton_MyAccount' : 'HeaderAccountButton_Login'
    "
  >
    <div class="button">
      <HeaderButton
        :modifiers="['headerButton', 'full']"
        :icon="icon"
        :label="label"
        :title="$t('header-account-button.my_account')"
        :href="frontFacingPath('customer.shop.customer_edit')"
        :on-click="clickHandler"
        :is-active="accountWidgetActive"
      />
    </div>
    <client-only>
      <transition
        enter-class="transitionInitial"
        enter-to-class="transitionGoal"
        enter-active-class="transitionEnterActive"
        leave-to-class="transitionInitial"
        leave-class="transitionGoal"
        leave-active-class="transitionLeaveActive"
      >
        <AccountWidget
          v-if="!mobile && accountWidgetActive"
          v-bind="accountWidgetConfig"
          class="widget"
        />
      </transition>
    </client-only>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only';
import HeaderButton from 'Components/01-atoms/header-button/HeaderButton.vue';
import { mapState, mapActions } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';
import debounce from 'lodash/debounce';

export default {
  name: 'HeaderAccountButton',
  components: {
    HeaderButton,
    ClientOnly,
    AccountWidget: () =>
      import(
        'Components/02-molecules/account-widget/AccountWidget' /* webpackChunkName: "AccountWidget" */
      ),
  },
  mixins: [globalMixin],
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    username: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icon: {
      name: 'account',
      width: '21',
      height: '21',
    },
    viewportWidth: null,
    label: '',
  }),
  computed: {
    ...mapState('header', {
      accountWidgetActive: (state) => state.accountWidget.open,
    }),
    accountWidgetConfig() {
      const { isLoggedIn, username, email } = this;
      return { isLoggedIn, username, email };
    },
  },
  watch: {
    viewportWidth(newValue) {
      if (!this.isLoggedIn) {
        this.label = this.$t('header-account-button.login');
      } else {
        this.label = this.$t(
          newValue < 900
            ? 'header-account-button.account'
            : 'header-account-button.my_account'
        );
      }
    },
  },
  mounted() {
    this.trackLogin();
    this.updateViewport();
    window.addEventListener('resize', debounce(this.updateViewport, 150));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewport);
  },
  methods: {
    ...mapActions('header', ['toggleAccountWidget', 'closeAccountWidget']),
    ...mapActions('dataLayer', ['trackLogin']),
    handleClick() {
      this.toggleAccountWidget();
    },
    clickHandler(event) {
      if (window.matchMedia('(min-width: 900px)').matches) {
        event.preventDefault();
        this.toggleAccountWidget();
      }
    },
    updateViewport() {
      this.viewportWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.headerAccountButton {
  display: block;
  position: relative;

  .widget {
    right: 0;
    left: unset;
    top: 100%;

    &::before {
      content: none;
    }
  }
}

.button {
  position: relative;
  white-space: nowrap;
}

@media (max-width: 899px) {
  .headerAccountButton {
    &::before {
      content: none;
    }
  }

  .widget {
    display: none;
  }
}

::v-deep {
  .transitionInitial {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-15px);
  }

  .transitionGoal {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  .transitionEnterActive,
  .transitionLeaveActive {
    transition: transform var(--time-S) ease-in, opacity var(--time-S) linear;
  }
}
</style>
