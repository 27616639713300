<template>
  <DropdownBase
    :id="vars.id"
    :modifiers="[
      ...modifiers,
      vars.submitted && vars.valid === false && 'error',
    ]"
    :modifier="modifier"
    :disabled="vars.disabled"
    :required="vars.required"
    :readonly="vars.readonly"
    :full-name="vars.full_name || vars.fullName"
    :label="vars.label"
    :inner-label="vars.innerLabel"
    :errors="vars.errors"
    :options="vars.choices"
    :attr="vars.attr"
    :value="vars.value"
    :use-svg="vars.useSvg"
    :message="message"
    :has-title-icon="hasTitleIcon"
    @input="$emit('input', $event)"
  >
    <template v-slot:default>
      <Input type="hidden" :form="form" />
    </template>

    <template v-slot:selectedOptionIcon="props">
      <Icon v-if="props.icon" :name="props.icon" class="optionIcon" />
    </template>
  </DropdownBase>
</template>

<script>
import Icon from 'Components/00-generated/Icon';
import DropdownBase from './DropdownBase.vue';
import Input from 'Components/01-atoms/form/input/Input.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import { formMixin } from 'Libs/mixins/formMixin';

export default {
  name: 'DropDown',
  components: { DropdownBase, Input, Icon },
  mixins: [globalMixin, formMixin],
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasTitleIcon() {
      const isPhoneCountryDropdown =
        this.vars.phone_country_codes &&
        this.vars.phone_country_codes.is_icon_dropdown;
      const isIconDropdown = this.vars.attr && this.vars.attr.iconDropdown;

      return isIconDropdown || isPhoneCountryDropdown;
    },
  },
};
</script>
